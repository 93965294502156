import React from 'react'
import { Layout, Menu } from 'antd'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import HeaderDiv from '../components/HeaderDiv'

const { Header, Sider, Content } = Layout

const PublicLayout = ({
  classname,
  children,
}) => (
  <>

    <Layout className={`${classname}`}>
      <Header className="headerTop">
        <HeaderDiv />
      </Header>
      {children}
      <ToastContainer />
    </Layout>
  </>
)

export default PublicLayout
