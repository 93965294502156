import React, { useEffect, useState, useForm } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Upload, Tooltip,
} from 'antd'
import {
  DiffOutlined,
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  IdcardOutlined,
  ReadOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  HourglassOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import moment from 'moment'
import { toast } from 'react-toastify'
import { Label } from 'recharts'
import tfa from '../../../provider/2fa'
import appleStore from '../../../static/images/app-store.png'
import googlePlay from '../../../static/images/google-play.png'
import qrCode from '../../../static/images/qr-code.png'
import verification from '../../../static/images/verification.png'
import './index.css'
import user from '../../../provider/user'

const { Step } = Steps
const { Option } = Select
const steps = [
  {
    title: 'Tải ứng dụng',
    description: 'Đang thực hiện',
    content: 'Tải ứng dụng',
    subcontent: 'Nhập địa chỉ email được dùng để đăng ký tài khoản REX Block',
  },
  {
    title: 'Quét mã QR',
    description: 'Đang chờ',
    content: 'Quét mã QR',
    subcontent: 'Chọn một loại giấy tờ cần xác minh',
  },
  {
    title: 'Khoá dự phòng',
    description: 'Đang chờ',
    content: 'Khoá dự phòng',
    subcontent: 'Cung cấp ảnh chụp khuôn mặt cùng với giấy tờ xác minh',
  },
  {
    title: 'Kích hoạt',
    description: 'Đang chờ',
    content: 'Kích hoạt Google Authenticator',
    subcontent: 'Thông tin liên hệ',
  },
  {
    title: 'Hoàn tất',
    description: 'Đang chờ',
    content: '',
    subcontent: '',
  },
]

const StepLayout = () => {
  const history = useHistory()

  const [currentStep, setCurrentStep] = useState(0)
  const [statusStep, setStatusStep] = useState({
    step1: '',
    step2: '',
    step3: '',
    step4: '',
    step5: '',
  })

  const [descriptonStep, setDescriptonStep] = useState({
    step1: 'Đang thực hiện',
    step2: 'Đang chờ',
    step3: 'Đang chờ',
    step4: 'Đang chờ',
    step5: 'Đang chờ',
  })

  const [data2fa, setData2fa] = useState({})
  const [form] = Form.useForm()
  const [current2fa, setCurrent2fa] = useState({ token: '', password: '' })

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const onChangeStep = (current) => {
    setCurrentStep(current)
  }

  function getStatus(value) {
    switch (value) {
      case 'PC':
        return 'Chờ phê duyệt'
      case 'P':
        return 'Chờ phê duyệt'
      case 'W':
        return 'Đang chờ'
      case 'C':
        return 'Đã phê duyệt'
      case 'RJ':
        return 'Cần thực hiện lại'
      default:
        return 'Đang thực hiện'
    }
  }
  const get2fa = async () => {
    try {
      const resp = await tfa.gen2fa()
      console.log(resp)
      setData2fa(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    form.setFieldsValue(current2fa)
  }, [form, current2fa])

  useEffect(() => {
    get2fa()
    return () => {
    }
  }, [])

  const onFinishStep1 = async () => {
    setCurrentStep(1)
  }

  // logic step2
  const onFinishStep2 = async () => {
    setCurrentStep(2)
  }

  // logic step3
  const onFinishStep3 = async () => {
    setCurrentStep(3)
  }

  const onFinishStep4 = async (value) => {
    const data = { token: value.token, password: value.password }
    try {
      const resp = await tfa.active2fa(data)
      if (resp.code === 200) {
        toast.success('Đã kích hoạt thành công 2FA')
        setCurrentStep(4)
      } else { return }
    } catch (e) {
      console.log(e)
    }
  }

  const refreshToken = async () => {
    await user.refreshToken()
    history.push('/dashboard')
  }

  const stepContent = () => (
    <div className="mt-4">
      <div className="">
        <h4>{steps[currentStep].content}</h4>
      </div>
      {
            (() => {
              switch (currentStep) {
                case 0:
                  return (
                    <>
                      <Typography.Title level={4} className="mb-4">
                        Tải xuống và cài đặt ứng dụng
                        Google Authenticator
                      </Typography.Title>

                      <Row gutter={20} className="mb-3">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>

                          <Card className="card my-3">
                            <p>
                              <img src={appleStore} />
                            </p>

                            <span>
                              Download from
                              {' '}
                              <br />
                              <Typography.Title level={5}>App Store</Typography.Title>
                            </span>
                          </Card>

                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>

                          <Card className="card my-3">
                            <p>
                              <img src={googlePlay} />
                            </p>

                            <span>
                              Download from
                              {' '}
                              <br />
                              <Typography.Title level={5}>Google Play</Typography.Title>
                            </span>

                          </Card>

                        </Col>

                      </Row>
                      <p>
                        <Button
                          type="primary"
                          className="m-t-15"
                          size="large"
                          onClick={onFinishStep1}
                        >
                          Tiếp tục
                        </Button>

                      </p>
                    </>

                  )

                case 1:
                  return (
                    <>
                      <Typography.Title level={4} className="mb-4">
                        Thực hiện bằng ứng dụng Google Authenticator
                      </Typography.Title>

                      <Row gutter={20} className="mb-3">
                        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                          <p className="text-center">
                            {/* <QRCode value={data2fa.path} /> */}
                            <img src={data2fa.path} />
                          </p>
                        </Col>
                      </Row>

                      <Card className="walletContent" bordered={false}>
                        <p>Vui lòng nhập mã dưới đây vào ứng dụng nếu không thực hiện được QR code </p>
                        <Typography.Title level={4}>{data2fa.stringQR}</Typography.Title>
                      </Card>
                      <p>
                        <Button
                          className="m-t-15 mr-2"
                          size="large"
                          onClick={prev}
                        >
                          Quay lại
                        </Button>
                        <Button
                          type="primary"
                          className="m-t-15"
                          size="large"
                          onClick={onFinishStep2}
                        >
                          Tiếp tục
                        </Button>

                      </p>
                    </>

                  )

                case 2:
                  return (
                    <>
                      <Typography.Title level={4} className="mb-4">
                        Vui lòng lưu khóa dự phòng để khôi phục
                        Google Authenticator
                      </Typography.Title>

                      <Card className="walletContent" bordered={false}>
                        <div className="key">
                          <span>
                            <img src={verification} className="img-verifycation" />
                          </span>
                          <Typography.Title level={4} style={{ overflow: 'auto' }} className="align-self-center">{data2fa.stringQR}</Typography.Title>

                        </div>
                        <p>Khóa này sẽ giúp bạn khôi phục Google Authenticator trong trường hợp mất điện thoại. Nếu không. việc đặt lại Google Authenticator sẽ mất nhiều thời gian </p>

                      </Card>
                      <p>
                        <Button
                          className="m-t-15 mr-2"
                          size="large"
                          onClick={prev}
                        >
                          Quay lại
                        </Button>
                        <Button
                          type="primary"
                          className="m-t-15"
                          size="large"
                          onClick={onFinishStep3}
                        >
                          Tiếp tục
                        </Button>

                      </p>
                    </>

                  )

                case 3:
                  return (
                    <>
                      <Typography.Title level={4} className="mb-4">
                        Vui lòng xác minh tài khoản của bạn
                      </Typography.Title>
                      <Form
                        form={form}
                        name="verify-account"
                        layout="vertical"
                        onFinish={onFinishStep4}
                        initialValues={current2fa}
                        className="form-infor"
                      >
                        {/* <Form.Item
                          name="verification-code"
                          label="Mã xác minh Email"
                          rules={[{ required: true }]}
                          help="Nhập mã xác minh 6 chữ số đã được gửi đến địa chỉ email nguyen****@gmail.com "
                        >
                          <div className="verify-input">
                            <Input className="w-75 mr-2" placeholder="Nhập mã xác minh" />
                            <Link className="align-self-center">Gửi lại mã</Link>
                          </div>

                        </Form.Item> */}

                        <Form.Item
                          name="token"
                          label="Mã xác thực"
                          rules={[{ required: true, message: 'Mã không được để trống.' }]}
                          help="Hãy nhập mã gồm 6 chữ số từ Google Authenticator"
                        >
                          {/* <div className="verify-input"> */}
                          <Input className="w-75 mr-2" placeholder="Nhập mã xác thực" />
                          {/* <Link className="align-self-center">Gửi lại mã</Link> */}
                          {/* </div> */}

                        </Form.Item>
                        <Form.Item
                          name="password"
                          label="Mật khẩu"
                          rules={[{ required: true, message: 'Mật khẩu không được để trống.' }]}
                        >
                          <Input.Password
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            className="w-75 mr-2"
                            placeholder="Nhập mật khẩu"
                          />

                        </Form.Item>
                        <Form.Item>
                          <Button
                            className="m-t-15 mr-2"
                            size="large"
                            onClick={prev}
                          >
                            Quay lại
                          </Button>
                          <Button
                            type="primary"
                            className="m-t-15"
                            size="large"
                            htmlType="submit"
                          >
                            Tiếp tục
                          </Button>
                        </Form.Item>
                      </Form>

                    </>

                  )

                case 4:
                  return (
                    <>
                      <div className="text-center">
                        <img src={verification} />
                        <Typography.Title level={2} className="text-center">Đã kích hoạt Google Authenticator</Typography.Title>
                        <Typography.Title level={5} className="text-center">
                          Bạn đã bật Google Authenticator
                          <br />
                          để bảo vệ tài khoản của mình
                        </Typography.Title>
                      </div>

                      <div className="text-center m-t-15">
                        <div>
                          <Button
                            type="primary"
                            className="m-t-15"
                            size="large"
                            onClick={refreshToken}
                          >
                            Về trang chủ
                          </Button>
                        </div>
                      </div>
                    </>
                  )

                default:
                  return null
              }
            })()
          }
    </div>
  )

  return (
    <Row>
      <Col span={24} className="loginForm">
        <div className="d-flex  justify-content-center flex-column m-t-30">
          <Steps current={currentStep} onChange={onChangeStep}>
            <Step status={statusStep?.step1} title="Tải ứng dụng" description={descriptonStep.step1} />
            <Step status={statusStep?.step2} title="Quét mã QR" description={descriptonStep.step2} />
            <Step status={statusStep?.step3} title="Khoá dự phòng" description={descriptonStep.step3} />
            <Step status={statusStep?.step4} title="Kích hoạt" description={descriptonStep.step4} />
            <Step status={statusStep?.step5} title="Hoàn tất" description={descriptonStep.step5} />
          </Steps>
          <div className="w-100 m-t-40">
            {stepContent()}
          </div>
        </div>
      </Col>
    </Row>
  )
}
export default StepLayout
