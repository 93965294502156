import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Typography } from 'antd'
import {
  PhoneOutlined,
  MailOutlined,
  MessageOutlined,

} from '@ant-design/icons'

const { Title } = Typography

const ModalContactInfo = ({ onOK, onCancel, visible }) => (
  <Modal
    className="modal-contact-info"
    footer={null}
    visible={visible}
    onOk={onOK}
    onCancel={onCancel}

  >
    <Title level={3} className="font-weight-500 mb-3">
      Thông tin liên hệ
    </Title>
    <p>

      Quý khách vui lòng liên hệ với chúng tôi qua các kênh sau, để được giải đáp hỗ trợ

    </p>
    <p>
      <span>
        <PhoneOutlined className="m-r-10" />
        Hotline: 0912345678
      </span>
    </p>
    <p>
      <span>
        <MailOutlined className="m-r-10" />
        Email: hotro@bre.com.vn
      </span>
    </p>
    <p className="mb-5">
      <span>
        <MessageOutlined className="m-r-10" />
        Telegram: t.me/hotro_BRE
      </span>
    </p>
    <p>
      <Button
        type="primary"
        htmlType="submit"
        className="btn-block"
        size="large"
        onClick={onCancel}
      >
        Đóng
      </Button>
    </p>
  </Modal>
)
ModalContactInfo.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onOK: PropTypes.func,
}

export default ModalContactInfo
