import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import { Route, Link, useHistory } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout, Modal, Typography,
  Carousel,
} from 'antd'
import OtpInput from 'react-otp-input'
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons'

import { FormattedMessage } from 'react-intl'
import { toast } from 'react-toastify'
import tfaApi from '../../../provider/2fa'
// import Header from '../../layout/header'
import emailSuccess from '../../../static/images/email-success.png'
import HeaderDiv from '../../../components/HeaderDiv'
import * as storage from '../../../utils/storage'

const { Title } = Typography
const { Header, Sider, Content } = Layout

const SignIn = ({
  user,
  getInfo,
  login, verifyOTP, resendOTP,

}) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [currentDataOtp, setCurrentDataOtp] = useState({})
  const [modalOtp, setModalOtp] = useState(false)
  const [modal2fa, setModal2fa] = useState(false)
  const [modalOtpSuccess, setModalOtpSuccess] = useState(false)
  const [otp, setOTP] = useState('')
  const [tfa, set2fa] = useState('')
  const [data2fa, setData2fa] = useState(null)
  const [userId, setUserId] = useState('')

  const handleChange2fa = (tfa) => {
    set2fa(tfa)
  }

  const handleVerify2fa = () => {
    login({ ...data2fa, ...{ tfacode: tfa } })
  }

  const clear2fa = () => {
    setModal2fa(false)
    set2fa('')
  }

  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }

  const handleVerifyOtp = () => {
    const dataEmail = currentDataOtp?.username
    verifyOTP({ email: dataEmail, otp, userId }, () => {
      setModalOtp(false)
      setModalOtpSuccess(true)
    })
  }

  const handleResendOtp = () => {
    resendOTP({ email: currentDataOtp?.username }, (resp) => {
      setUserId(resp?.data?.user_id)
    })
  }

  const handleVerifyEmail = (email) => {
    resendOTP({ email }, (resp) => {
      setUserId(resp?.data?.user_id)
    })
  }

  const clearOTP = () => {
    setModalOtp(false)
    setOTP('')
  }

  useEffect(() => {
    const { KEYS } = storage
    storage.remove(KEYS.TOKEN)
  }, [])

  useEffect(() => {
    if (user?.data?.username) {
      history.push('/dashboard')
    }
  }, [user?.data])

  const onSubmit = (data) => {
    setCurrentDataOtp(data)
    setData2fa(data)
    login(data, (userData) => {
      if (userData?.data?.code === 1004) {
        handleVerifyEmail(data.username)
        setModalOtp(true)
      }
      if (userData?.data?.code === 202) {
        setModal2fa(true)
      }
    })
  }

  // useEffect(() => {
  //   if (user?.data?.data?.code === 1004) {
  //     setModalOtp(true)
  //   }
  //   if (user?.data?.data?.code === 202) {
  //     setModal2fa(true)
  //   }
  //   return () => {}
  // }, [user])

  // useEffect(() => {
  //   if (user?.data?.data?.code === 1004) {
  //     handleResendOtp()
  //     setModalOtp(true)
  //   }
  //   if (user?.data?.data?.code === 202) {
  //     setModal2fa(true)
  //   }
  // }, [user])

  const onSubmitFailed = (error) => {
  }

  return (
    <>
      {/* <Header className="headerTop">
        <HeaderDiv />
      </Header> */}
      <Layout title="login" className="login">
        {/* <Header className="headerTop">
        <HeaderDiv />
      </Header> */}
        {/* Modal Otp Success */}
        <Modal
          footer={null}
          className="modalOtp"
          visible={modalOtpSuccess}
          onCancel={() => setModalOtpSuccess(false)}
          onOk={() => setModalOtpSuccess(false)}
        >
          <Title level={3} className="text-title">
            Xác minh thông tin thành công!
          </Title>
          <p style={{ marginBottom: 32 }}>
            Bạn đã tạo thành công tài khoản tại Quesera, vui lòng đăng nhập để
            tiếp tục
          </p>
          <p style={{ marginBottom: 32, textAlign: 'center' }}>
            <img src={emailSuccess} />
          </p>
          <p style={{ marginBottom: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              onClick={() => onSubmit(currentDataOtp)}
            >
              Đăng nhập tài khoản
            </Button>
          </p>
          <p>
            <Button
            // type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              onClick={() => setModalOtpSuccess(false)}
            >
              Trở về trang chủ
            </Button>
          </p>
        </Modal>

        {/* Modal Otp */}
        <Modal
          className="modalOtp"
          footer={null}
          visible={modalOtp}
          onCancel={() => clearOTP()}
          onOk={() => clearOTP()}
        >
          <Title level={3} className="text-title">
            Xác minh địa chỉ email
          </Title>
          <p style={{ marginBottom: 32 }}>
            Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
          </p>
          <OtpInput
            value={otp}
            onChange={handleChangeOtp}
            numInputs={6}
            separator=""
            inputStyle={{
              height: 60,
              width: 50,
              marginRight: 4,
              marginLeft: 4,
              background: '#F5F5F5',
              border: '2px solid #F5F5F5',
              borderRadius: 4,
              marginBottom: 32,
            }}
            containerStyle={{ justifyContent: 'center' }}
            focusStyle={{ border: '2px solid #cf9900' }}
          />
          <p style={{ marginBottom: 32, color: '#121212' }}>
            Nhập mã xác minh gồm 6 chữ số được gửi đến địa chỉ email:
            {' '}
            <span style={{ fontWeight: 500 }}>{currentDataOtp?.username}</span>
          </p>
          <p style={{ marginBottom: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              disabled={otp.length < 6}
              onClick={handleVerifyOtp}
            >
              Xác nhận
            </Button>
          </p>
          <p style={{ marginBottom: 5, textAlign: 'center' }}>
            Bạn chưa nhận được email?
          </p>
          <div className="text-center">
            <a href="javascript:void(0)" onClick={handleResendOtp}>
              Gửi lại mã xác nhận
            </a>
          </div>
        </Modal>

        {/* Modal 2fa */}
        <Modal
          className="modalOtp"
          footer={null}
          visible={modal2fa}
          onCancel={() => clear2fa()}
          onOk={() => clear2fa()}
        >
          <Title level={4} className="text-title">
            Xác minh Google Authenticator
          </Title>
          <p style={{ marginBottom: 32 }}>
            Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
          </p>
          <OtpInput
            value={tfa}
            onChange={handleChange2fa}
            numInputs={6}
            separator=""
            inputStyle={{
              height: 60,
              width: 50,
              marginRight: 4,
              marginLeft: 4,
              background: '#F5F5F5',
              border: '2px solid #F5F5F5',
              borderRadius: 4,
              marginBottom: 32,
            }}
            containerStyle={{ justifyContent: 'center' }}
            focusStyle={{ border: '2px solid #cf9900' }}
          />
          <p style={{ marginBottom: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              disabled={tfa.length < 6}
              onClick={handleVerify2fa}
            >
              Xác nhận
            </Button>
          </p>
        </Modal>

        <Row>
          <Col md={{ span: 12, order: 1 }} span={24} order={2} className="loginForm">
            <div className="login-container">
              <div className="text-left">
                {/* <img src={logo} /> */}
                <h1 className="m-b-15 m-t-15">
                  <FormattedMessage id="signin_title" />
                </h1>
              </div>
              <Form
                layout="vertical"
                className="login-form"
                initialValues={{
                  username: '',
                  password: '',
                }}
                onFinish={onSubmit}
                onFinishFailed={onSubmitFailed}
              >
                <Form.Item className="item-form" name="username" label="Tên đăng nhập" rules={[{ required: true, message: 'Tên đăng nhập không được để trống.' }]}>
                  <Input
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Nhập tên đăng nhập"
                  />
                </Form.Item>
                <Form.Item className="item-form" name="password" label="Mật khẩu" rules={[{ required: true, message: 'Mật khẩu không được để trống.' }]}>
                  <Input.Password
                    prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    type="password"
                    placeholder="Nhập mật khẩu"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item>
                  <Checkbox>Ghi nhớ</Checkbox>
                  <Link to="/reset-password" className="float-right">
                    Quên mật khẩu
                  </Link>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-block m-t-15"
                    size="large"
                  >
                    Đăng nhập
                  </Button>
                  <p>
                    <span className="mr-1">
                      {/* Need an account? */}
                      Bạn muốn đăng ký tài khoản? Vui lòng
                    </span>
                    <Link to="/sign-up">
                      {/* Signup */}
                      Đăng ký
                    </Link>
                  </p>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col md={{ span: 12, order: 2 }} span={24} order={1}>
            {/* <div className="banner-container" /> */}
            <Carousel autoplay>
              <div className="banner-container" />
              <div className="banner-container" />
              <div className="banner-container" />
              <div className="banner-container" />
            </Carousel>
            ,
          </Col>
        </Row>
      </Layout>
    </>
  )
}

SignIn.propTypes = {
  user: PropTypes.shape(),
  getInfo: PropTypes.func,
  login: PropTypes.func,
  verifyOTP: PropTypes.func,
  resendOTP: PropTypes.func,
}

export default SignIn
