import { connect } from 'react-redux'
import Signup from '../pages/authentication/signUp'
import { getUserData } from '../redux/selector/user'
import {
  checkReferrer, register, resendVerifyEmail, verifyUser,
} from '../redux/action/user'

const mapDispatchToProps = (dispatch) => ({
  register: (data, callback) => dispatch(register(data, callback)),
  verifyOTP: (data, callback) => dispatch(verifyUser(data, callback)),
  resendOTP: (data, callback) => dispatch(resendVerifyEmail(data, callback)),
})

export default connect(false, mapDispatchToProps)(Signup)
