/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

export const signout = async () => {
  axios.defaults.headers.common.Authorization = null
  const { KEYS } = storage
  await storage.remove(KEYS.TOKEN)
  window.location.href = '/sign-in'
}

export const login = async (username, password, tfacode) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const payload = {
      username,
      password,
      tfa_code: tfacode,
    }
    const resp = await axios.post(ENDPOINT.LOGIN, payload, {
      headers: {
        lang: 'vn',
      },
    })
    const { data } = resp.data
    if (resp.data.code === 200) {
      axios.defaults.headers.common.Authorization = `Bearer ${data.token?.access_token}`
      const { KEYS } = storage
      storage.set(KEYS.TOKEN, data.token?.access_token)
      storage.set(KEYS.REFRESH, data?.refresh_token)
      storage.set(KEYS.ID, data?.user_id)
      try {
        const info = await axios.get(ENDPOINT.GET_USER)
        return info.data?.data || { username: '' }
      } catch (e) {
        toast.error(e.response.data.msg)
        throw e
      }
    } else {
      return resp
    }
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
      return e.response
    }
    toast.error('sorry, we are having some issues at the moment. Please try again later.')

    throw e
  }
}

export const register = async (name, email, username, password, referrer) => {
  try {
    const resp = await axios.post(ENDPOINT.REGISTER, {
      name,
      email,
      username,
      password,
      confirm_password: password,
      referrer,
    }, {
      headers: {
        lang: 'vn',
      },
    })
    const { data: { data } } = resp || { data: {} }
    // {"msg":"OK","data":{"user_id":"623ee2ca1dd31810ca832513"},"code":200}
    return { name, email, userId: data.user_id }
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export const get = async () => {
  const { KEYS } = storage
  const token = storage.get(KEYS.TOKEN)
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
    try {
      const resp = await axios.get(ENDPOINT.GET_USER)
      return resp.data?.data || { username: '' }
    } catch (e) {
      throw e
    }
  } else {
    const e = { message: 'Authorization failed' }
    toast.error(e.message)
    throw e
  }
}

export const verifyUserByOtp = async (email, userId, opt) => {
  try {
    const resp = await axios.post(ENDPOINT.VERIFY_USER, {
      email,
      user_id: userId,
      number_verify: opt,
      re_captcha: null,
    }, {
      headers: {
        lang: 'vn',
      },
    })

    const { data: { data } } = resp || { data: {} }
    return {}
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export const resendOtpByEmail = async (email) => {
  try {
    const resp = await axios.post(ENDPOINT.RESEND_VERIFY_EMAIL, {
      email,
      re_captcha: null,
    }, {
      headers: {
        lang: 'vn',
      },
    })
    const { data: { data } } = resp || { data: {} }
    return { data }
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getProfile = async () => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_PROFILE, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const updateInfoPersonal = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.UPDATE_INFO_PERSONAL, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const changePassword = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.CHANGE_PASSWORD, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const refreshToken = async () => {
  const { KEYS } = storage
  try {
    const payload = {
      user_id: storage.get(KEYS.ID),
      refresh_token: storage.get(KEYS.REFRESH),
      old_access_token: storage.get(KEYS.TOKEN),

    }
    const resp = await axios.post(ENDPOINT.NEW_TOKEN, payload, {
      headers: {
        lang: 'vn',
      },
    })
    const { data } = resp.data
    storage.set(KEYS.REFRESH, data?.refresh_token)
    storage.set(KEYS.ID, data?.user_id)
    storage.set(KEYS.TOKEN, data.token?.access_token)
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const forgotPassword = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.FORGOT_PASSWORD, data, {
      headers: {
        lang: 'vn',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const confirmForgotPassword = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_FORGOT_PASSWORD, data, {
      headers: {
        lang: 'vn',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  refreshToken,
  getProfile,
  updateInfoPersonal,
  changePassword,
  forgotPassword,
  confirmForgotPassword,
}
