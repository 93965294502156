import * as React from 'react'
import styled, { css } from 'styled-components'
import { Button, Progress } from 'antd'
import {
  useHistory,
} from 'react-router-dom'
import ProductImg1 from '../../static/images/product/product1.jpeg'

export const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin-bottom: 30px;
  padding: 16px;
  background: #fff;
  color: #838383;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  @media (min-width: 1400px) {
    padding: 24px;
  }
  b {
    color: #121212;
    font-weight: 600 !important;
  }
  .primary-text {
    color: #fdb515 !important;
  }
`

export const ProductImgWrap = styled.div`
  position: relative;
  margin-bottom: 15px;
`

export const ProductImg = styled.img`
  max-width: 100%;
  border-radius: 4px;
`

export const ProductTag = styled.span`
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 3px 12px;
  background: #d94046;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`

export const BigText = css`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`

export const ProductTitle = styled.h2`
  ${BigText}
`

export const ProductPrice = styled.p`
  margin-top: 15px;
  margin-bottom: 15px;
  color: #121212;
  ${BigText}
`

export const ProductAddress = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  svg {
    margin-right: 8px;
  }
`

export const ProductProperty = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    margin-right: 8px;
  }
`

function ProductItem({ value }) {
  console.log(value)
  const history = useHistory()
  return (
    <ProductWrapper>
      <ProductImgWrap>
        <ProductImg src={ProductImg1} alt="Product Image" />
        <ProductTag>Sắp mở bán</ProductTag>
      </ProductImgWrap>
      <ProductTitle>Căn hộ loại B: 69.6 m2 (2 phòng ngủ) - Dự án VIVA PLAZA Quận 7</ProductTitle>
      <ProductProperty>
        <div className="d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z" fill="#5C5C5C" />
            <path d="M9.72981 8.97666L7.50168 7.36572V3.49854C7.50168 3.42979 7.44543 3.37354 7.37668 3.37354H6.62512C6.55637 3.37354 6.50012 3.42979 6.50012 3.49854V7.80166C6.50012 7.84229 6.51887 7.87979 6.55168 7.90322L9.13606 9.7876C9.19231 9.82822 9.27043 9.81572 9.31106 9.76104L9.75793 9.15166C9.79856 9.09385 9.78606 9.01572 9.72981 8.97666Z" fill="#5C5C5C" />
          </svg>
          <span>Thời gian đầu tư</span>
        </div>
        <span>12 tháng</span>
      </ProductProperty>
      <ProductProperty>
        <div className="d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
            <path d="M15.7313 5.21528H11.1598C11.0813 5.21528 11.017 5.27956 11.017 5.35813V6.21528C11.017 6.29385 11.0813 6.35813 11.1598 6.35813H15.7313C15.8098 6.35813 15.8741 6.29385 15.8741 6.21528V5.35813C15.8741 5.27956 15.8098 5.21528 15.7313 5.21528ZM13.3384 7.64385H11.1598C11.0813 7.64385 11.017 7.70813 11.017 7.7867V8.64385C11.017 8.72242 11.0813 8.78671 11.1598 8.78671H13.3384C13.417 8.78671 13.4813 8.72242 13.4813 8.64385V7.7867C13.4813 7.70813 13.417 7.64385 13.3384 7.64385ZM7.37948 3.61706H6.60627C6.49555 3.61706 6.40627 3.70635 6.40627 3.81706V8.24563C6.40627 8.30992 6.43662 8.36885 6.48841 8.40635L9.14734 10.3456C9.23662 10.4099 9.36163 10.3921 9.42591 10.3028L9.88484 9.67599V9.6742C9.94913 9.58492 9.92948 9.45992 9.8402 9.39563L7.5777 7.75992V3.81706C7.57948 3.70635 7.48841 3.61706 7.37948 3.61706Z" fill="#5C5C5C" />
            <path d="M13.2283 9.8876H12.1962C12.0962 9.8876 12.0015 9.93939 11.9479 10.0251C11.7208 10.3851 11.4543 10.7187 11.1533 11.0197C10.6351 11.5397 10.0207 11.9539 9.34438 12.2394C8.64259 12.5358 7.89795 12.6858 7.13009 12.6858C6.36045 12.6858 5.6158 12.5358 4.9158 12.2394C4.23902 11.9537 3.63009 11.543 3.10688 11.0197C2.58366 10.4965 2.17295 9.8876 1.88723 9.21081C1.5908 8.51081 1.4408 7.76617 1.4408 6.99653C1.4408 6.22689 1.5908 5.48403 1.88723 4.78224C2.17295 4.10546 2.58366 3.49653 3.10688 2.97331C3.63009 2.4501 4.23902 2.03938 4.9158 1.75367C5.6158 1.45724 6.36223 1.30724 7.13009 1.30724C7.89973 1.30724 8.64438 1.45724 9.34438 1.75367C10.0212 2.03938 10.6301 2.4501 11.1533 2.97331C11.4543 3.27433 11.7208 3.60793 11.9479 3.96796C12.0015 4.05367 12.0962 4.10546 12.1962 4.10546H13.2283C13.3515 4.10546 13.4301 3.97688 13.3747 3.86796C12.2104 1.55188 9.84973 0.0465268 7.21402 0.0161697C3.35509 -0.0320446 0.149733 3.12688 0.14259 6.98224C0.135447 10.8447 3.2658 13.9787 7.12831 13.9787C9.79795 13.9787 12.1979 12.468 13.3747 10.1251C13.3873 10.1001 13.3932 10.0723 13.392 10.0444C13.3908 10.0165 13.3825 9.9893 13.3678 9.9655C13.3531 9.9417 13.3326 9.92204 13.3082 9.90841C13.2838 9.89477 13.2563 9.88761 13.2283 9.8876Z" fill="#5C5C5C" />
          </svg>
          <span>
            Thời gian gọi vốn
          </span>
        </div>
        <span>
          9/30 ngày
        </span>
      </ProductProperty>
      <ProductProperty>
        <div className="d-flex align-items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M7 0C3.13437 0 0 3.13437 0 7C0 10.8656 3.13437 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13437 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z" fill="#5C5C5C" />
            <path d="M7.74539 6.6375L7.34851 6.54531V4.44688C7.94226 4.52812 8.30945 4.9 8.37195 5.35625C8.37976 5.41875 8.43289 5.46406 8.49539 5.46406H9.19695C9.27039 5.46406 9.3282 5.4 9.32195 5.32656C9.22664 4.35313 8.42508 3.72813 7.35476 3.62031V3.10938C7.35476 3.04063 7.29851 2.98438 7.22976 2.98438H6.7907C6.72195 2.98438 6.6657 3.04063 6.6657 3.10938V3.625C5.55944 3.73281 4.69382 4.34375 4.69382 5.48438C4.69382 6.54063 5.47194 7.05 6.28913 7.24531L6.67507 7.34375V9.57344C5.98444 9.48125 5.59694 9.1125 5.51726 8.61563C5.50788 8.55625 5.45476 8.5125 5.39382 8.5125H4.67194C4.5985 8.5125 4.54069 8.575 4.54694 8.64844C4.61725 9.50781 5.26882 10.2984 6.65945 10.4V10.8906C6.65945 10.9594 6.7157 11.0156 6.78445 11.0156H7.2282C7.29695 11.0156 7.3532 10.9594 7.3532 10.8891L7.35007 10.3938C8.57351 10.2859 9.44852 9.63125 9.44852 8.45625C9.44695 7.37187 8.75789 6.8875 7.74539 6.6375ZM6.67351 6.38438C6.58601 6.35938 6.51257 6.33594 6.43913 6.30625C5.91101 6.11562 5.66569 5.80781 5.66569 5.41094C5.66569 4.84375 6.09538 4.52031 6.67351 4.44688V6.38438ZM7.34851 9.57812V7.48906C7.39695 7.50313 7.4407 7.51406 7.48601 7.52344C8.22507 7.74844 8.47351 8.06094 8.47351 8.54062C8.47351 9.15156 8.01414 9.51875 7.34851 9.57812Z" fill="#5C5C5C" />
          </svg>
          <span>Lãi suất dự kiến</span>
        </div>
        <span>12%</span>
      </ProductProperty>
      <ProductPrice>
        Quỹ cần gọi
        {' '}
        <span className="primary-text">3 tỷ VND</span>
      </ProductPrice>

      <div className="d-flex justify-content-between" style={{ marginBottom: '-8px' }}>
        <span>
          <b>99</b>
          {' '}
          nhà đầu tư
        </span>
        <span>
          <b>99/1,000</b>
          {' '}
          phần
        </span>
      </div>
      <Progress percent={10} strokeWidth={2} strokeColor="#fdb515" trailColor="#e6e6e6" showInfo={false} />
      <ProductAddress>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M15.7599 7.87393L8.85989 0.9775L8.39739 0.515C8.29201 0.410316 8.1495 0.351563 8.00097 0.351562C7.85243 0.351562 7.70992 0.410316 7.60454 0.515L0.242038 7.87393C0.134058 7.98149 0.0487196 8.10959 -0.00894006 8.25067C-0.0665997 8.39175 -0.0954118 8.54296 -0.093676 8.69536C-0.0865331 9.32393 0.436681 9.82571 1.06525 9.82571H1.82418V15.6418H14.1778V9.82571H14.9528C15.2581 9.82571 15.5456 9.70607 15.7617 9.49C15.8681 9.38395 15.9524 9.25784 16.0097 9.11899C16.067 8.98014 16.0962 8.83129 16.0956 8.68107C16.0956 8.3775 15.976 8.09 15.7599 7.87393ZM9.00097 14.3561H7.00097V10.7132H9.00097V14.3561ZM12.892 8.54V14.3561H10.1438V10.2846C10.1438 9.89 9.82418 9.57036 9.42954 9.57036H6.57239C6.17775 9.57036 5.85811 9.89 5.85811 10.2846V14.3561H3.10989V8.54H1.39561L8.00275 1.93821L8.41525 2.35071L14.6081 8.54H12.892Z" fill="#5C5C5C" />
        </svg>
        <span>Phường Phú Mỹ, Quận 7, Tp.HCM</span>
      </ProductAddress>
      <Button
        type="primary"
        htmlType="submit"
        className="btn-block m-t-15"
        size="large"
        onClick={() => history.push('/product/item')}
      >
        Xem chi tiết dự án
      </Button>
    </ProductWrapper>
  )
}

export default ProductItem
