/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getWallet = async () => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_WALLET, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const createBankAccount = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.BANK_ACCOUNT, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const searhIDO = async (param, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.SEARCH_ORDER_BOOK, data, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getHistory = async (param, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.HISTORY_ORDER_BOOK, data, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}
const requestOrder = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.PLACE_ORDER_BOOK, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const idoFulfill = async (data, code = '') => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.P2P_FULFILL, data, {
      params: { code },
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}
export default {
  requestOrder,
  searhIDO,
  getHistory,
  idoFulfill,
}
