/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getListBountyProgram = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.BOUNTY_PROGRAM_GET_LIST, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const createBountyProgram = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.BOUNTY_PROGRAM_CREATE, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const rewardBountyProgram = async (completionId, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.BOUNTY_PROGRAM_REQUEST_REWARD, data, {
      params: completionId,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getListBountyProgram,
  createBountyProgram,
  rewardBountyProgram,
}
