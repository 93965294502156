/* eslint-disable no-underscore-dangle */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import styled from 'styled-components'
import {
  Row,
  Col,
  Card,
  Typography, Tabs,
  Button, Form, Input,
  DatePicker,
  Modal,
  Table,
  Tag,
  Space,
  Avatar,
  Select, message, Breadcrumb,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import {
  ClockCircleOutlined,
  CloseOutlined, RightOutlined, UserOutlined, LockOutlined, DollarOutlined, StockOutlined, UserSwitchOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { TablePaginationConfig } from 'antd/lib/table'
import OtpInput from 'react-otp-input'
import { toast } from 'react-toastify'
import tokenBreIcon from '../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../static/images/TokenVNĐ.png'
import tokenUsdrIcon from '../../static/images/TokenUSDR.png'
import product from '../../provider/product'
import wallet from '../../provider/wallet'
import ido from '../../provider/ido'
import { formatAmount } from '../../utils/common'

// const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`
const { TabPane } = Tabs
const { Option } = Select
const { Title } = Typography

export const ASSET = {
  USDT: 'USDT',
  VND: 'VND',
  USDR: 'USDR',
  REX: 'REX',
}

const ASSET_ICON = {
  [ASSET.USDT]: tokenUsdtIcon,
  [ASSET.VND]: tokenVndIcon,
  [ASSET.USDR]: tokenUsdrIcon,
  [ASSET.REX]: tokenBreIcon,
}

const ASSET_COIN = {
  [ASSET.USDT]: 'Tether',
  [ASSET.VND]: 'Việt Nam đồng',
  [ASSET.USDR]: 'USDR Coin',
  [ASSET.REX]: 'REX Coin',
}

export const WALLET_STATUS = {
  W: 'W',
  P: 'P',
  PC: 'PC',
  C: 'C',
  CL: 'CL',
  F: 'F',
  WUT: 'WUT',
  WSV: 'WSV',
  RF: 'RF',
}

export const IDO_STATUS = {
  FF: 'FF',
  F: 'F',
  PC: 'PC',
  PR: 'PR',
  P: 'P',
  RF: 'RF',
  FN: 'FN',
}

const STATUS_COLORS = {
  [WALLET_STATUS.W]: 'waiting',
  [WALLET_STATUS.P]: 'waiting',
  [WALLET_STATUS.PC]: 'success',
  [WALLET_STATUS.C]: 'success',
  [WALLET_STATUS.CL]: 'fail',
  [WALLET_STATUS.F]: 'fail',
  [WALLET_STATUS.WSV]: 'waiting',
  [WALLET_STATUS.WUT]: 'waiting',
  [WALLET_STATUS.RF]: 'fail',
  [IDO_STATUS.FF]: 'success',
  [IDO_STATUS.F]: 'fail',
  [IDO_STATUS.PC]: 'waiting',
  [IDO_STATUS.PR]: 'waiting',
  [IDO_STATUS.P]: 'waiting',
  [IDO_STATUS.RF]: 'success',
  [IDO_STATUS.FN]: 'success',
}

export const STATUS_NAMES = {
  [WALLET_STATUS.W]: 'Chờ xác nhận',
  [WALLET_STATUS.P]: 'Chưa xử lý',
  [WALLET_STATUS.PC]: 'Tiến hành trả',
  [WALLET_STATUS.C]: 'Giao dịch thành công',
  [WALLET_STATUS.CL]: 'Không xác nhận',
  [WALLET_STATUS.F]: 'Thất bại',
  [WALLET_STATUS.WSV]: 'Đang xác nhận',
  [WALLET_STATUS.WUT]: 'Chờ chuyển tiền',
  [WALLET_STATUS.RF]: 'Hoàn tiền',
}
export const IDO_STATUS_NAMES = {
  [IDO_STATUS.FF]: 'Hoàn tất',
  [IDO_STATUS.F]: 'Thất bại',
  [IDO_STATUS.PC]: 'Đang xử lý',
  [IDO_STATUS.PR]: 'Chuẩn bị',
  [IDO_STATUS.P]: 'Chưa xử lý',
  [IDO_STATUS.RF]: 'Hoàn tiền',
  [IDO_STATUS.FN]: 'Kết thúc',
}
const Transfer = () => {
  const history = useHistory()
  const [modalTransactionDetail, setModalTransactionDetail] = useState(false)
  const [otp, setOTP] = useState('')
  const [modalOtp, setModalOtp] = useState(false)
  const [typeConfirmOtp, setTypeConfirmOtp] = useState('')
  const [total, setTotal] = useState(10)
  const [data, setData] = useState([])
  const [totalHistoryWallet, setTotalHistoryWallet] = useState(10)
  const [modalBuyStock, setModalBuyStock] = useState(false)
  const [modalSaleStock, setModalSaleStock] = useState(false)

  const [dataDetailStock, setDataDetailStock] = useState({})
  const [dataBuyIDO, setDataBuyIDO] = useState([])
  const [dataSaleIDO, setDataSaleIDO] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  })

  const [querySearchBuyIDO, setQuerySearchBuyIDO] = useState({
    current: 1,
    pageSize: 10,
    type: 'B',
    date: '',
    status: '',
  })

  const [querySearchSaleIDO, setQuerySearchSaleIDO] = useState({
    current: 1,
    pageSize: 10,
    type: 'S',
    date: '',
    status: '',
  })
  const [paginationBuy, setPaginationBuy] = useState({
    current: 1,
    pageSize: 10,
  })

  const [paginationSale, setPaginationSale] = useState({
    current: 1,
    pageSize: 10,
  })

  const buyIDO = (data) => {
    console.log('IDO DATA', data)
    setDataDetailStock(data)
    setModalBuyStock(true)
  }

  const saleIDO = (data) => {
    console.log('IDO DATA', data)
    setDataDetailStock(data)
    setModalSaleStock(true)
  }

  const columnsBuy = [
    {
      title: 'Mã cổ phần',
      dataIndex: 'stockInfo',
      key: 'stockInfo',
      render: (stockInfo) => (
        <span className="">{stockInfo?.symbol}</span>
      ),
    },
    {
      title: 'Ngày đăng',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text) => <span className="font-weight-500">{moment(text).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: 'Khối lượng',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">{formatAmount(text)}</span>,
    },
    {
      title: 'Đơn giá',
      key: 'price',
      dataIndex: 'price',
      render: (text, row) => (
        <span className="font-weight-500">
          {formatAmount(text) || 0 }
          {' '}
          {row.bases}
        </span>
      ),
    },
    {
      title: 'Người đăng',
      key: 'owner',
      dataIndex: 'owner',
      render: (text) => <span className="font-weight-500">{text || '-'}</span>,
    },
    {
      title: 'Thao tác',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text, row) => (
        <div className="text-right d-flex">

          <Button
            type="primary"
            className=""
            size="small"
            onClick={() => buyIDO(row)}
          >
            Mua
          </Button>
        </div>
      ),
    },
  ]

  const columnsSale = [
    {
      title: 'Mã cổ phần',
      dataIndex: 'stockInfo',
      key: 'stockInfo',
      render: (stockInfo) => (
        <span className="">{stockInfo?.symbol}</span>
      ),
    },
    {
      title: 'Ngày đăng',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text) => <span className="font-weight-500">{moment(text).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: 'Khối lượng',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">{formatAmount(text)}</span>,
    },
    {
      title: 'Đơn giá',
      key: 'price',
      dataIndex: 'price',
      render: (text, row) => (
        <span className="font-weight-500">
          {formatAmount(text) || 0}
          {' '}
          {row.bases}
        </span>
      ),
    },
    {
      title: 'Người đăng',
      key: 'owner',
      dataIndex: 'owner',
      render: (text) => <span className="font-weight-500">{text || '-'}</span>,
    },
    {
      title: 'Thao tác',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text, row) => (
        <div className="text-right d-flex">

          <Button
            type="primary"
            className=""
            size="small"
            onClick={() => saleIDO(row)}
          >
            Bán
          </Button>
        </div>
      ),
    },
  ]

  const onChange = (date, dateString) => {
    console.log(date, dateString)
  }

  const searchBuyIDO = async (param) => {
    const filter = {
      type: 'S',
    }
    const payload = {
      query: filter,
      sort: {
        updatedAt: -1,
      },

    }
    console.log(payload)
    const resp = await ido.searhIDO({ page: param.current || 1, limit: param.pageSize || 10 }, payload)
    console.log(resp)
    setDataBuyIDO(resp.data?.data)
    setPaginationBuy({ ...paginationBuy, ...{ total: resp.data?.total }, ...{ current: param.current } })
  }

  const searchSaleIDO = async (param) => {
    const filter = {
      type: 'B',
    }
    const payload = {
      query: filter,
      sort: {
        updatedAt: -1,
      },

    }
    console.log(payload)
    const resp = await ido.searhIDO({ page: param.current || 1, limit: param.pageSize || 10 }, payload)
    console.log(resp)
    setDataSaleIDO(resp.data?.data)
    setPaginationSale({ ...paginationSale, ...{ total: resp.data?.total }, ...{ current: param.current } })
  }

  const fetchOrderBuy = async (params) => {
    await searchBuyIDO({ ...querySearchBuyIDO, current: params?.current || 1, pageSize: params?.pageSize || 10 })
    setPaginationBuy({ ...params })
  }

  const fetchOrderSale = async (params) => {
    await searchSaleIDO({ ...querySearchSaleIDO, current: params?.current || 1, pageSize: params?.pageSize || 10 })
    setPaginationSale({ ...params })
  }

  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }

  const onSearchBuy = (value) => {
    // getData(1, 10, statusSelected, value)
    console.log(value)
  }

  const onSearch = (value) => {
    // getData(1, 10, statusSelected, value)
    console.log(value)
  }
  const clearOTP = () => {
    setModalOtp(false)
    setTypeConfirmOtp('')
    setOTP('')
  }

  const confirmBuy = async () => {
    const payload = {
      orderBookId: dataDetailStock._id,
      type: 'B',
      quantity: dataDetailStock.quantity - dataDetailStock.executed_qty,
      base: dataDetailStock.bases.toString(),
    }
    setTypeConfirmOtp('B')
    console.log(payload)
    const resp = await ido.idoFulfill(payload)
    console.log(resp)
    setModalBuyStock(false)
    setModalOtp(true)
  }

  const confirmSale = async () => {
    setTypeConfirmOtp('S')
    const payload = {
      orderBookId: dataDetailStock._id,
      type: 'S',
      quantity: dataDetailStock.quantity - dataDetailStock.executed_qty,
      base: dataDetailStock.bases.toString(),
    }
    console.log(payload)
    const resp = await ido.idoFulfill(payload)
    console.log(resp)
    setModalSaleStock(false)
    setModalOtp(true)
  }

  const handleVerifyOtp = async () => {
    try {
      const payload = {
        orderBookId: dataDetailStock._id,
        type: typeConfirmOtp,
        quantity: dataDetailStock.quantity - dataDetailStock.executed_qty,
        base: dataDetailStock.bases.toString(),
      }
      const resp = await ido.idoFulfill(payload, otp)
      console.log(resp)
      toast.success('yêu cầu của bạn đã được gửi.')
      setModalOtp(false)
    } catch (error) {
      // setSumbitSwap(false)
    }
  }

  const handleResendOtp = async () => {

  }

  useEffect(() => {
    searchSaleIDO(querySearchSaleIDO)
    searchBuyIDO(querySearchBuyIDO)
    return () => {}
  }, [])

  return (
    <>
      <Modal
        footer={null}
        visible={modalTransactionDetail}
        onOk={() => setModalTransactionDetail(false)}
        onCancel={() => setModalTransactionDetail(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Chi tiết giao dịch
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>ID NDT</DescriptionTextLeft>
          <DescriptionTextRight>123456789</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Mã giao dịch</DescriptionTextLeft>
          <DescriptionTextRight>hoangtaynapvnd1</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
          <TextColorGreen>Nạp / VND</TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Ngày</DescriptionTextLeft>
          <DescriptionTextRight>30/01/2021 23:59</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số lượng</DescriptionTextLeft>
          <DescriptionTextRight>+12.000.000 VND</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
          <TextColorGreen>Hoàn tất</TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Ngân hàng</DescriptionTextLeft>
          <DescriptionTextRight>Vietcombank</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số tài khoản</DescriptionTextLeft>
          <DescriptionTextRight>001100345678</DescriptionTextRight>
        </DescriptionText>
        <DescriptionTextEnd>
          <DescriptionTextLeft>Chủ tài khoản</DescriptionTextLeft>
          <DescriptionTextRight>Hoang Tay</DescriptionTextRight>
        </DescriptionTextEnd>
        <p>
          <Button
          // type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => setModalTransactionDetail(false)}
          >
            Đóng
          </Button>
        </p>
      </Modal>

      <Modal
        footer={null}
        visible={modalBuyStock}
        onOk={() => setModalBuyStock(false)}
        onCancel={() => setModalBuyStock(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Xác nhận mua cổ phần
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>Mã Cổ phần</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.stockInfo?.symbol}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft> Tên sản phẩm</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.project?.name}</DescriptionTextRight>
        </DescriptionText>

        <DescriptionText>
          <DescriptionTextLeft>Số lượng cổ phần</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock.quantity - dataDetailStock.executed_qty)}</TextColorGreen>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Giá cổ phần</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock?.price)}</TextColorGreen>
        </DescriptionText>

        <DescriptionText>
          <DescriptionTextLeft>Tổng số tiền phải thanh toán</DescriptionTextLeft>
          <TextColorGreen>{formatAmount((dataDetailStock.quantity - dataDetailStock.executed_qty) * dataDetailStock?.price)}</TextColorGreen>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Phương thức thanh toán</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.bases}</DescriptionTextRight>
        </DescriptionText>

        <div className="d-flex justify-content-center">
          <Button
            htmlType="submit"
            size="large"
            className="m-l-10 m-r-10"
            onClick={() => setModalBuyStock(false)}
          >
            Hủy bỏ
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="m-l-10 m-r-10"
            onClick={() => confirmBuy()}
          >
            Xác nhận mua
          </Button>
        </div>
      </Modal>

      <Modal
        footer={null}
        visible={modalSaleStock}
        onOk={() => setModalSaleStock(false)}
        onCancel={() => setModalSaleStock(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Xác nhận Bán cổ phần
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>Mã Cổ phần</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.stockInfo?.symbol}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft> Tên sản phẩm</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.project?.name}</DescriptionTextRight>
        </DescriptionText>

        <DescriptionText>
          <DescriptionTextLeft>Số lượng cổ phần</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock.quantity - dataDetailStock.executed_qty)}</TextColorGreen>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Giá cổ phần</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock?.price)}</TextColorGreen>
        </DescriptionText>

        <DescriptionText>
          <DescriptionTextLeft>Tổng số tiền nhận được</DescriptionTextLeft>
          <TextColorGreen>{formatAmount((dataDetailStock.quantity - dataDetailStock.executed_qty) * dataDetailStock?.price)}</TextColorGreen>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Phương thức thanh toán</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.bases}</DescriptionTextRight>
        </DescriptionText>

        <div className="d-flex justify-content-center">
          <Button
            htmlType="submit"
            size="large"
            className="m-l-10 m-r-10"
            onClick={() => setModalSaleStock(false)}
          >
            Hủy bỏ
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="m-l-10 m-r-10"
            onClick={() => confirmSale()}
          >
            Xác nhận bán
          </Button>
        </div>
      </Modal>

      {/* Modal Otp */}
      <Modal
        className="modalOtp"
        footer={null}
        visible={modalOtp}
        onCancel={() => clearOTP()}
        onOk={() => clearOTP()}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Xác minh mã bảo mật
        </Title>
        <p style={{ marginBottom: 32 }}>
          Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
        </p>
        <OtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={6}
          separator=""
          inputStyle={{
            height: 60,
            width: 50,
            marginRight: 4,
            marginLeft: 4,
            background: '#F5F5F5',
            border: '2px solid #F5F5F5',
            borderRadius: 4,
            marginBottom: 32,
          }}
          containerStyle={{ justifyContent: 'center' }}
          focusStyle={{ border: '2px solid #cf9900' }}
        />
        <p style={{ marginBottom: 32, color: '#121212' }}>
          Nhập mã xác minh gồm 6 chữ số được gửi đến địa email của bạn.
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            disabled={otp.length < 6}
            onClick={handleVerifyOtp}
          >
            Xác nhận
          </Button>
        </p>
        <p style={{ marginBottom: 5, textAlign: 'center' }}>
          Bạn chưa nhận được email?
        </p>
        <div className="text-center">
          <a href="javascript:void(0)" onClick={handleResendOtp}>
            Gửi lại mã xác nhận
          </a>
        </div>
      </Modal>

      <Breadcrumb className="mt-3 mb-4">
        <Breadcrumb.Item>Sàn p2p</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/transfer">Giao dịch</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={2}>Giao dịch cổ phần BĐS</Typography.Title>
      <div className="mb-4">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={(
              <span>
                <DollarOutlined />
                Mua cổ phần
              </span>
            )}
            key="1"
          >
            <div className="d-flex flex-row mb-4 mt-3">
              <Input.Search placeholder="Tìm kiếm cổ phần cần mua" className="mr-5" onSearch={onSearchBuy} />
              <Button
                className="mr-3 button-section-filter"
                type="primary"
                onClick={() => history.push('/transfer/buy')}

              >
                <span>Tạo quảng cáo mua</span>
              </Button>
            </div>

            <Table
              scroll={{ x: true }}
              columns={columnsBuy}
              dataSource={dataBuyIDO}
              pagination={paginationBuy}
              onChange={fetchOrderBuy}
            />
          </TabPane>
          <TabPane
            tab={(
              <span>
                <StockOutlined />
                Bán cổ phần
              </span>
            )}
            key="2"
          >
            <div className="d-flex flex-row mb-4 mt-3">
              <Input.Search placeholder="Tìm kiếm cổ phần cần mua" className="mr-5" onSearch={onSearch} />
              <Button
                className="mr-3 button-section-filter"
                type="primary"
                onClick={() => history.push('/transfer/sale')}
              >
                <span>Tạo quảng cáo bán</span>
              </Button>
            </div>

            <Table
              scroll={{ x: true }}
              columns={columnsSale}
              dataSource={dataSaleIDO}
              pagination={paginationSale}
              onChange={fetchOrderSale}
            />
          </TabPane>
        </Tabs>

      </div>

    </>
  )
}

export default Transfer
