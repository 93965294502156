import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs, InputNumber, Upload, message,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
  PhoneOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  CopyOutlined,
} from '@ant-design/icons'
// import './index.css'
import styled from 'styled-components'
import moment from 'moment'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import ModalContactInfo from '../contact-info'
import wallet from '../../../provider/wallet'
import WalletInfoUsdt from '../wallet-info-usdt'

const { Step } = Steps

const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`
const TitleDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;
`

const DepositUSDT = (classe) => {
  useEffect(() => { }, [])
  const { TabPane } = Tabs

  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')
  const [visible, setVisible] = useState(false)
  const [modalTransactionDetail, setModalTransactionDetail] = useState(false)
  const [modalDeposit, setModalDeposit] = useState(false)

  const [modalContactInfo, setModalContactInfo] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const [query, setQuery] = useState({
    currency: 'USDT',
    page: 1,
    limit: 10,
  })

  const [infoRequest, setInfoRequest] = useState({})
  const [data, setData] = useState([])
  const [qrCode, setQrCode] = useState('')

  const openDetailRequest = (data) => {
    setInfoRequest(data)
    setModalTransactionDetail(true)
  }

  const columns = [
    // {
    //   title: 'Mã tham chiếu',
    //   key: 'tx_id',
    //   dataIndex: 'tx_id',
    //   render: (text) => <span className="font-weight-500">{text || '-'}</span>,
    // },
    {
      title: 'Mã tham chiếu',
      key: 'from',
      dataIndex: 'from',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{text || '-'}</span>
        </div>
      ),
    },
    {
      title: 'Số lượng',
      key: 'amount',
      dataIndex: 'amount',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{text || 0}</span>
          <span>USDT</span>
        </div>
      ),
    },
    {
      title: 'Thời gian',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text) => (
        <span className="font-weight-500">
          {moment(text).format('MM/DD/YYYY HH:mm:ss')}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text, row) => (
        <div className="text-right">
          {text === 'C' ? (
            <span className="success">
              Hoàn tất
            </span>
          )
            : text === 'F' ? (
              <span className="fail">
                Lỗi xảy ra
              </span>
            )
              : text === 'W' ? (
                <span className="waiting">
                  Khởi tạo
                </span>
              )
                : text === 'P' ? (
                  <span className="waiting">
                    Chờ xác nhận
                  </span>
                )

                  : text === 'PC' ? (
                    <span className="waiting">
                      Đang xử lý
                    </span>
                  )

                    : text === 'CL' ? (
                      <span className="fail">
                        Hủy
                      </span>
                    )
                      : (
                        <>{text}</>
                      )}
          <span>
            <Button
              onClick={() => openDetailRequest(row)}
              shape="circle"
              icon={<RightOutlined className="colorIcon" />}
              size="small"
            />
          </span>

        </div>
      ),
    },
  ]

  const onChange = () => {

  }

  const getListDeposit = async (params) => {
    const resp = await wallet.getListDeposit(params)
    console.log('list deposit', resp)
    setData(resp.data)
  }

  useEffect(() => {
    getListDeposit(query)
  }, [])

  const onChangeStep = (current) => {
    setCurrentStep(current)
  }

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <div className="d-flex align-items-center">
        <span className="mr-2">USDT</span>
        <Avatar src={tokenUsdtIcon} size={18} />
      </div>
    </Form.Item>
  )

  const requestDeposit = async (data) => {
    const payload = { currency: 'USDT' }

    const resp = await wallet.requestDeposit(payload)
    setInfoRequest(resp.data)
    console.log(resp)
    setModalDeposit(true)
  }

  const uploadButton = (
    <div>
      <CloudUploadOutlined style={{ fontSize: 40 }} />
      <div className="m-t-20">Click hoặc kéo ảnh vào khung hình</div>
    </div>
  )

  const copyToClipboard = async (values) => {
    try {
      await navigator.clipboard.writeText(values)
      message.success('Sao chép thành công.')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>

      <Modal
        footer={null}
        visible={modalTransactionDetail}
        onOk={() => setModalTransactionDetail(false)}
        onCancel={() => setModalTransactionDetail(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Chi tiết giao dịch
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>ID NDT</DescriptionTextLeft>
          <DescriptionTextRight>{infoRequest?.user}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionTextLeft>TX_ID</DescriptionTextLeft>
        <DescriptionTextRight>{infoRequest?.tx_id}</DescriptionTextRight>
        <DescriptionText>
          <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
          <TextColorGreen>Rút / USDT</TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Ngày</DescriptionTextLeft>
          <DescriptionTextRight>{infoRequest?.createdAt}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số lượng</DescriptionTextLeft>
          <DescriptionTextRight>
            {infoRequest?.amount?.toLocaleString()}
            {' '}
            USDT
          </DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
          <TextColorGreen>
            {infoRequest?.status === 'C' ? (
              <span className="success">
                Hoàn tất
              </span>
            )
              : infoRequest?.status === 'F' ? (
                <span className="fail">
                  Lỗi xảy ra
                </span>
              )
                : infoRequest?.status === 'W' ? (
                  <span className="waiting">
                    Khởi tạo
                  </span>
                )
                  : infoRequest?.status === 'P' ? (
                    <span className="waiting">
                      Chờ xác nhận
                    </span>
                  )

                    : infoRequest?.status === 'PC' ? (
                      <span className="waiting">
                        Đang xử lý
                      </span>
                    )

                      : infoRequest?.status === 'CL' ? (
                        <span className="fail">
                          Hủy
                        </span>
                      )
                        : (
                          <>{infoRequest?.status}</>
                        )}

          </TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Địa chỉ Gửi</DescriptionTextLeft>
          <DescriptionTextRight>{infoRequest?.from}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Địa chỉ nhận</DescriptionTextLeft>
          <DescriptionTextRight>{infoRequest?.to}</DescriptionTextRight>
        </DescriptionText>
        <p>
          <Button
          // type="primary"
            htmlType="submit"
            className="btn-block m-t-20"
            size="large"
            onClick={() => setModalTransactionDetail(false)}
          >
            Đóng
          </Button>
        </p>
      </Modal>

      <ModalContactInfo
        visible={modalContactInfo}
        onOK={() => setModalContactInfo(false)}
        onCancel={() => setModalContactInfo(false)}
      />

      <Modal
        footer={null}
        visible={modalDeposit}
        onOk={() => setModalDeposit(false)}
        onCancel={() => setModalDeposit(false)}
      >

        <Title level={3} className="font-weight-500 mb-3">
          Thông tin nạp USDT
        </Title>

        <DescriptionText>
          <DescriptionTextLeft>Định dạng ví</DescriptionTextLeft>
          <DescriptionTextRight>ERC20</DescriptionTextRight>
        </DescriptionText>
        <hr />
        <TitleDescription>Thông tin ví tài khoản</TitleDescription>
        <DescriptionText>
          <DescriptionTextLeft>Địa chỉ ví</DescriptionTextLeft>
          <DescriptionTextRight>
            <span className="mr-2">{infoRequest?.address}</span>
            <span><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(infoRequest?.address)} /></span>

          </DescriptionTextRight>

        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Phí nạp</DescriptionTextLeft>
          <DescriptionTextRight>0 USDT</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số lượng nạp tối thiểu</DescriptionTextLeft>
          <DescriptionTextRight>1 USDT</DescriptionTextRight>
        </DescriptionText>
        <div className="mb-3">
          <DescriptionTextLeft>QR Code</DescriptionTextLeft>
          <p className="text-center"><img src={infoRequest?.stringQR} /></p>
        </div>

        <p>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => setModalDeposit(false)}
          >
            Đóng
          </Button>
        </p>
      </Modal>

      <WalletInfoUsdt />

      {/* Deposit amount */}
      <Card className="walletContent" bordered={false}>

        <Form
          name="deposit-amount"
          initialValues={{
          }}
          scrollToFirstError
          onFinish={requestDeposit}
        >
          <Button type="primary" className="mr-2" htmlType="submit">
            Nạp USDT
          </Button>
          <Button icon={<PhoneOutlined />} onClick={() => setModalContactInfo(true)}>
            Liên hệ hỗ trợ
          </Button>
        </Form>

      </Card>
      <div className="m-t-40">

        {/* table balance */}
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          pagination={{ position: 'bottomCenter' }}
        />
      </div>

    </>
  )
}

export default DepositUSDT
