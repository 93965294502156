import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const RouteWithLayout = ({
  layout: Layout,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <Layout>
        <Component {...matchProps} {...matchProps.match.params} />
      </Layout>
    )}
  />
)

RouteWithLayout.propTypes = {
  component: PropTypes.shape().isRequired,
  layout: PropTypes.shape().isRequired,
  path: PropTypes.string,
}

export default RouteWithLayout
