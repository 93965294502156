import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import makeStore from './redux/store'
import './styles/global.scss'
import Routes from './Routes'
import { LOCALES } from './i18n/locales'
import { messages } from './i18n/messages'

const browserHistory = createBrowserHistory()
const store = makeStore()

const App = () => {
  const [currentLocale, setCurrentLocale] = useState(LOCALES.ENGLISH)

  useEffect(() => {
    setCurrentLocale(LOCALES.VIETNAMESE)
  }, [])

  return (
    <IntlProvider locale={currentLocale} messages={messages[currentLocale]} defaultLocale={LOCALES.ENGLISH}>
      <Provider store={store}>
        <Router history={browserHistory}>
          <Routes />
        </Router>
      </Provider>
    </IntlProvider>
  )
}

export default App
