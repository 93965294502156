import React, { useEffect, useState } from 'react'
import {
  Menu, Input, Avatar, Button,
} from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  useHistory, Route, Link, BrowserRouter as Router, NavLink,
} from 'react-router-dom'
import logo from '../static/images/rex-logo.png'
import avatar from '../static/images/flat-avatar.png'
import { signout } from '../provider/user'
import { getUserData } from '../redux/selector/user'
import * as userAction from '../redux/action/user'

const { SubMenu } = Menu

const { Search } = Input

const HeaderDiv = ({ user, getInfo }) => {
  const [isLogout, setIsLogout] = useState(false)

  console.log(user)
  const history = useHistory()
  useEffect(() => {
    if (!localStorage.getItem('TOKEN')) {
      setIsLogout(true)
    } else {
      getInfo()
    }
    return () => { }
  }, [])

  const onProfileItemClick = ({ key }) => {
    switch (key) {
      case 'logout':
        signout()
        return null
      case 'profile-view':
      default:
        return null
    }
  }

  return (
    <>
      <div className="">
        {/* <Menu className="border-0">
          <Menu.Item key="brand-logo" className="brand-logo">
            <Link to="/dashboard">
              <img src={logo} />
            </Link>
          </Menu.Item>
        </Menu> */}
        <Menu
          mode="horizontal"
          theme="light"
          className=""
        >

          <Menu.Item key="brand-logo" className="brand-logo">
            <Link to="/dashboard">
              <img src={logo} />
            </Link>
          </Menu.Item>

          <Menu.Item className="menu-item" key="about">
            <a href="https://rexblock.io/">Về REX</a>
          </Menu.Item>

          <Menu.Item key="gp" className="menu-item">
            <a href="https://rexblock.io/">Dịch Vụ</a>
          </Menu.Item>

          <Menu.Item key="products" className="menu-item">
            <NavLink activeClassName="active" to="/products">Sàn BDS</NavLink>
          </Menu.Item>

          <Menu.Item key="transfer" className="menu-item">
            <NavLink activeClassName="active" to="/transfer">Sàn P2P</NavLink>
          </Menu.Item>

          <Menu.Item key="tt" className="menu-item">
            <a href="https://rexblock.io/tin-tuc/">Tin Tức</a>
          </Menu.Item>
          {
            user?.data?.name ? (
              <Menu.SubMenu
                key="profile"
                title={(
                  <span>
                    <Avatar size={24} src={avatar} />
                    {' '}
                    {user?.data?.name || ''}
                  </span>
                )}
                onClick={onProfileItemClick}
              >
                <Menu.Item key="logout" style={{ pointerEvents: 'auto' }}>Đăng xuất</Menu.Item>
              </Menu.SubMenu>
            ) : (
              <>
              </>
            )

          }

          {
            isLogout ? (
              <>

                <Menu.Item className="menu-item">
                  <Button type="primary" onClick={() => history.push('/sign-up')}>Đăng Ký</Button>
                </Menu.Item>

                <Menu.Item className="menu-item">
                  <Button onClick={() => history.push('/sign-in')}>Đăng nhập</Button>
                </Menu.Item>
              </>
            ) : (
              <>

              </>
            )

          }

        </Menu>
      </div>
    </>
  )
}

HeaderDiv.propTypes = {
  user: PropTypes.shape(),
  getInfo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
})
export default connect(
  (state) => (
    {
      user: getUserData(state),
    }), mapDispatchToProps,
)(HeaderDiv)
