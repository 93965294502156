import { fromJS } from 'immutable'
import WALLET from '../default/wallet'
import { WALLET_ACTION } from '../type'

export default function wallet(state = null, action) {
  const { type, payload } = action

  switch (type) {
    case WALLET_ACTION.GET_WALLET_INFO:
      return state.set('loading', true).set('error', null)
    case WALLET_ACTION.GET_WALLET_OK:
      return state.set('loading', false).set('data', fromJS(payload))
    case WALLET_ACTION.GET_WALLET_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')
    default:
      return state
  }
}
