import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  DatePicker,
  Form,
  Input,
} from 'antd'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import tfa from '../../../provider/2fa'

const { Title } = Typography

const Deactive2FA = () => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [current2fa, setCurrent2fa] = useState({ token: '', password: '' })
  const [modalConfirmDeactive2fa, setModalConfirmDeactive2fa] = useState(false)

  const gotoActive = () => {
    history.push('/2fa')
  }
  const deactive2FA = async (value) => {
    const data = { token: value.token, password: value.password }
    try {
      const resp = await tfa.deactive2fa(data)

      if (resp.code === 200) {
        toast.success(resp.msg)
        gotoActive()
      } else { toast.error(resp.msg) }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    form.setFieldsValue(current2fa)
  }, [form, current2fa])

  return (

    <>

      <Typography.Title level={2}>Tắt Google Authenticator</Typography.Title>

      <Form
        form={form}
        name="verify-account"
        wrapperCol={{ span: 12 }}
        layout="vertical"
        onFinish={deactive2FA}
        initialValues={current2fa}
      >

        <Form.Item
          name="token"
          label="Mã bảo mật"
          rules={[{ required: true }]}
          help="Hãy nhập mã gồm 6 chữ số từ Google Authenticator"
        >
          <Input className="w-75 mr-2" placeholder="Nhập mã bảo mật" />

        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true }]}
        >
          <Input className="w-75 mr-2" placeholder="Nhập mật khẩu" />

        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            className="m-t-15"
            size="large"
            htmlType="submit"
          >
            Tiếp tục
          </Button>
        </Form.Item>
      </Form>

    </>

  )
}

export default Deactive2FA
