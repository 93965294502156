import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import verificationNone from '../../static/images/verification-none.png'
import verification from '../../static/images/verification.png'
import successIcon from '../../static/images/reset-pwd.png'
import StepLayout from './steps'
import user from '../../provider/user'

const GoogleAuthen = () => {
  const history = useHistory()
  // const [name, setName] = useState('')
  // const [password, setPassword] = useState('')
  const [showConfirm, setShowConfirm] = useState(true)
  const [showProcess, setShowProcess] = useState(false)
  const [profile, setProfile] = useState({})
  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')

  const onChange = (date, dateString) => {
    console.log(date, dateString)
  }

  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }

  const getProfile = async () => {
    try {
      const resp = await user.getProfile()
      console.log(resp)
      setProfile(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  const confirm2FA = () => {
    setShowConfirm(false)
    setModalConfirm(false)
    setShowProcess(true)
  }

  useEffect(() => {
    getProfile()
    return () => {
    }
  }, [])

  return (
    <>

      { showConfirm ? (
        <>
          <>
            {/* Chưa active 2fa */}
            <div className="text-center">
              {(profile.tfa === 'I' || profile.tfa === null) ? (
                <>
                  <img src={verificationNone} />
                  <Typography.Title level={2} className="text-center">Chưa kích hoạt 2FA</Typography.Title>
                  <Typography.Title level={5} className="text-center">Bật tính năng Google Authenticator để bảo vệ tài khoản của bạn</Typography.Title>

                  <Button
                    type="primary"
                    htmlType="submit"
                    className="m-t-15"
                    size="large"
                    onClick={confirm2FA}
                  >
                    Xác minh ngay
                  </Button>

                  <p className="m-t-15">
                    <Link to="/dashboard">Hướng dẫn thiết lập Google Authenticator </Link>
                  </p>
                </>
              ) : (<></>)}
            </div>
          </>

          {/* Đã active 2fa */}
          <>
            <div className="text-center">
              {profile.tfa === 'A' ? (
                <>
                  <img src={verification} />
                  <Typography.Title level={2} className="text-center">Đã kích hoạt Google Authenticator</Typography.Title>
                  <Typography.Title level={5} className="text-center">
                    Bạn đã bật Google Authenticator
                    <br />
                    để bảo vệ tài khoản của mình
                  </Typography.Title>

                  <Button
                    type="primary"
                    className="m-t-15"
                    size="large"
                    onClick={() => history.push('/2fa/deactive')}
                  >
                    Tắt Google Authenticator
                  </Button>

                </>
              ) : (
                <></>
              )}
            </div>
          </>
        </>
      ) : (<></>)}
      {showProcess ? (
        <>
          <StepLayout />
        </>
      ) : (
        <>
        </>
      )}
    </>
  )
}

export default GoogleAuthen
