/* eslint-disable dot-notation */
/* eslint-disable indent */
/* eslint-disable keyword-spacing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs,
  InputNumber,
  Upload,
  Select,
  Radio,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
  PhoneOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined, PlusOutlined, CheckCircleOutlined, DeleteOutlined,
  SwapOutlined,
} from '@ant-design/icons'
// import './index.css'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import { toast } from 'react-toastify'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenUsdrIcon from '../../../static/images/TokenUSDR.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import ModalContactInfo from '../contact-info'
import walletAPI from '../../../provider/wallet'
import WalletInfo from '../wallet-info-usdr'

import * as walletAction from '../../../redux/action/wallet'
import { getWalletData } from '../../../redux/selector/wallet'
import { formatAmount } from '../../../utils/common'

const { Step } = Steps
const { Option } = Select
const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`

const suffixSelectorUSDR = (
  <Form.Item name="suffix" noStyle>
    USDR
  </Form.Item>
)
const columns = [
  {
    title: 'Mã tham chiếu',
    key: 'order_no',
    dataIndex: 'order_no',
    render: (text) => <span className="font-weight-500">{text || '-'}</span>,
  },

  {
    title: 'Symbol',
    key: 'symbol',
    dataIndex: 'symbol',
    render: (text) => <span className="font-weight-500">{text || '-'}</span>,
  },
  {
    title: 'Số lượng',
    key: 'quantity',
    dataIndex: 'quantity',
    render: (text) => (
      <div>
        <span className="mr-1 font-weight-500">{formatAmount(text || 0)}</span>
      </div>
    ),
  },
  {
    title: 'Thời gian',
    key: 'created_at_time',
    dataIndex: 'created_at_time',
    render: (text) => (
      <span className="font-weight-500">
        {moment(text).format('MM/DD/YYYY HH:mm:ss')}
      </span>
    ),
  },

  {
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    render: (text) => text === 'FF' ? (
      <div className="text-right">
        <span className="success">
          Hoàn tất
        </span>
      </div>
    ) : text === 'F' ? (
      <div className="text-right">
        <span className="fail">
          Lỗi xảy ra
        </span>
      </div>

    ) : text === 'PC' ? (
      <div className="text-right">
        <span className="waiting">
          Chờ phê duyệt
        </span>
      </div>
    )
      : text === 'PR' ? (
        <div className="text-right">
          <span className="waiting">
            PREPARING
          </span>
        </div>
      ) : text === 'P' ? (
        <div className="text-right">
          <span className="waiting">
            Đang chờ
          </span>
        </div>
      )
        : text === 'RF' ? (
          <div className="text-right">
            <span className="fail">
              Hoàn trả
            </span>
          </div>
        )
          : (
            { text }
          ),
  },
]

const Swap = ({ wallet, getWallet, id }) => {
  useEffect(() => { }, [])
  const { TabPane } = Tabs

  const [form] = Form.useForm()
  const [bankAccount] = Form.useForm()
  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')
  const [visible, setVisible] = useState(false)
  const [modalOtp, setModalOtp] = useState(false)

  const [pair, setPair] = useState('USDR/VND')
  const [source, setSource] = useState('VND')
  const [target, setTarget] = useState('USDR')

  const [pairList, setPairList] = useState({})
  const [rate, setRate] = useState(0)
  const [type, setType] = useState('B')
  const [quantityExchange, setQuantityExchange] = useState(0)
  const [totalExchange, setTotalExchange] = useState(0)
  const [sumbitSwap, setSumbitSwap] = useState(false)

  const [sltCurrentAccountBank, setSltCurrentAccountBank] = useState({})
  const [infoRequest, setInfoRequest] = useState({})
  const [defaultValuesBankAccount, setDefaultValuesBankAccount] = useState({
    bankType: '',
    numberCard: '',
    fullName: '',
    bankBranch: '',
  })

  const [currentStep, setCurrentStep] = useState(0)
  const [statusStep, setStatusStep] = useState({
    step1: '',
    step2: '',
  })

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  })

  const [listBankAccount, setListBankAccount] = useState([])
  const [data, setData] = useState([])

  const [dataForm, setDataForm] = useState({
    radio: '',
    quantityExchange: 0,
    quantityTarget: 0,
    suffix: 'VND',
    suffixTarget: 'USDR',

  })

  const [supportedPairs, setSupportedPairs] = useState({})

  const fetchSupportedPairs = async () => {
    try {
      const resp = await walletAPI.getSwapPairs()
      setSupportedPairs(resp.data)
      let rexVndRate = [0, 0]
      let rexUsdtRate = [0, 0]
      let usdrVndRate = [0, 0]
      let usdrUsdtRate = [0, 0]

      // eslint-disable-next-line keyword-spacing
      if (resp && resp.data && resp.data.length > 0) {
        for (const pair of resp.data) {
          const symbol = `${pair.pair.asset}/${pair.pair.base}`
          switch (symbol) {
            case 'REX/VND':
              rexVndRate = [pair.ask, pair.bid]
              break
            case 'REX/USDT':
              rexUsdtRate = [pair.ask, pair.bid]
              break
            case 'USDR/VND':
              usdrVndRate = [pair.ask, pair.bid]
              break
            case 'USDR/USDT':
              usdrUsdtRate = [pair.ask, pair.bid]
              break
            default:
              break
          }
        }
        const pairData = {
          VND: {
            REX: { pair: 'REX/VND', rate: rexVndRate[0], type: 'B' },
            USDR: { pair: 'USDR/VND', rate: usdrVndRate[0], type: 'B' },
          },

          USDT: {
            REX: { pair: 'REX/USDT', rate: rexUsdtRate[0], type: 'B' },
            USDR: { pair: 'USDR/USDT', rate: usdrUsdtRate[0], type: 'B' },
          },

          REX: {
            VND: { pair: 'REX/VND', rate: (1 / rexVndRate[1]), type: 'S' },
            USDT: { pair: 'REX/USDT', rate: (1 / rexUsdtRate[1]), type: 'S' },
          },

          USDR: {
            VND: { pair: 'USDR/VND', rate: (1 / usdrVndRate[1]), type: 'S' },
            USDT: { pair: 'USDR/USDT', rate: (1 / usdrUsdtRate[1]), type: 'S' },
          },
        }
        setRate(pairData[source][target]?.rate)
        setPairList(pairData)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onChangeCoin = (value) => {
    setSource(value)
    setPair(pairList[value][target]?.pair)
    setRate(pairList[value][target]?.rate)
    setType(pairList[value][target]?.type)

    setDataForm({
      ...dataForm, ...{ suffix: value }, ...{ quantityExchange: 0 }, ...{ quantityTarget: 0 },
    })

    if (value === 'VND') {
      setTotalExchange(wallet?.data?.fiat?.balance)
    }
    if (value === 'USDT') {
      setTotalExchange(wallet?.data?.usdt?.balance)
      // setDataForm({
      //   ...dataForm, ...{ quantityExchange: dataForm.usdr },
      // })
    }

    if (value === 'USDR') {
      setTotalExchange(wallet?.data?.token?.balance)
      // setDataForm({
      //   ...dataForm, ...{ quantityExchange: dataForm.usdr },
      // })
    }
    if (value === 'REX') {
      setTotalExchange(wallet?.data?.public_token?.balance)
      // setDataForm({
      //   ...dataForm, ...{ quantityExchange: dataForm.rex },
      // })
    }
  }

  const onChangeCoinTarge = (value) => {
    setTarget(value)
    console.log(value)
    console.log(pairList[source][value]?.pair, pairList[source][value]?.rate, pairList[source][value]?.type)
    setPair(pairList[source][value]?.pair)
    setRate(pairList[source][value]?.rate)
    setType(pairList[source][value]?.type)

    setDataForm({
      ...dataForm, ...{ suffixTarget: value }, ...{ quantityExchange: 0 }, ...{ quantityTarget: 0 },
    })
  }
  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select value="USDR" onChange={onChangeCoin} style={{ width: 120 }}>
        <Option value="VND">
          <span className="mr-2">VND</span>
          <Avatar src={tokenVndIcon} size={18} />
        </Option>

        <Option value="USDT">
          <span className="mr-2">USDT</span>
          <Avatar src={tokenUsdtIcon} size={18} />
        </Option>
        <Option value="USDR">
          <span className="mr-2">USDR</span>
          <Avatar src={tokenUsdrIcon} size={18} />
        </Option>
        <Option value="REX">
          <span className="mr-2">REX</span>
          <Avatar src={tokenBreIcon} size={18} />
        </Option>

      </Select>
    </Form.Item>
  )

  const suffixSelectorTarget = (
    <Form.Item name="suffixTarget" noStyle>
      <Select value="USDR" onChange={onChangeCoinTarge} style={{ width: 120 }}>
        <Option value="VND">
          <span className="mr-2">VND</span>
          <Avatar src={tokenVndIcon} size={18} />
        </Option>

        <Option value="USDT">
          <span className="mr-2">USDT</span>
          <Avatar src={tokenUsdtIcon} size={18} />
        </Option>
        <Option value="USDR">
          <span className="mr-2">USDR</span>
          <Avatar src={tokenUsdrIcon} size={18} />
        </Option>
        <Option value="REX">
          <span className="mr-2">REX</span>
          <Avatar src={tokenBreIcon} size={18} />
        </Option>

      </Select>
    </Form.Item>
  )

  useEffect(() => {
    form.setFieldsValue(defaultValuesBankAccount)
  }, [bankAccount, defaultValuesBankAccount])

  useEffect(() => {
    setTotalExchange(wallet?.data?.fiat?.balance)
    return () => { }
  }, [wallet])

  const openDetailRequest = (data) => {
    setInfoRequest(data)
    setModalTransactionDetailWithDraw(true)
  }

  const getHistorySwap = async (params) => {
    const resp = await walletAPI.getHistory(params)
    setData(resp.data)
  }

  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }

  useEffect(() => {
    // if (id === 'usdr') {
    //   onChangeCoinTarge('USDR')
    // }
    // if (id === 'rex') {
    //   onChangeCoinTarge('REX')
    // }
    getHistorySwap(query)
    fetchSupportedPairs(query)
  }, [])

  const handleVerifyOtp = async () => {
    try {
      setSumbitSwap(true)
      let quantity = 0
      if (type === 'B') {
        quantity = dataForm.quantityTarget.toFixed(6)
      } else
        if (type === 'S') {
          quantity = dataForm.quantityExchange.toFixed(6)
        }
      const payload = {
        quantity,
        type,
        pair,
      }
      console.log(payload)
      const resp = await walletAPI.swapUSDR(payload, { tfa_code: otp })
      toast.success('yêu cầu của bạn đã được gửi.')
      getHistorySwap(query)
      getWallet()
      setModalOtp(false)
    } catch (error) {
      setSumbitSwap(false)
    }
  }

  const clearOTP = () => {
    setModalOtp(false)
    setSumbitSwap(false)
    setOTP('')
  }

  const handleResendOtp = () => {
  }

  const handleSwap = () => {
    setModalOtp(true)
  }

  const requestSwap = async (value) => {
    let quantity = 0
    if (type === 'B') {
      quantity = value.quantityTarget.toFixed(6)
    } else
      if (type === 'S') {
        quantity = value.quantityExchange.toFixed(6)
      }
    const payload = {
      quantity,
      type,
      pair,
    }
    console.log(payload)
    if (value.quantityTarget > 0 && value.quantityExchange > 0) {
      const resp = await walletAPI.swapUSDR(payload, { tfa_send: 'auto' })
      setModalOtp(true)
    } else {
      toast.error('Số lượng phải lớn hơn 0')
    }
  }

  const onchangeValue = (value) => {
    console.log(value)
    setDataForm({
      ...dataForm, ...{ quantityExchange: value }, ...{ quantityTarget: value / rate }, ...{ rate }, ...{ radio: '' },
    })
  }

  const onchangeValueTarget = (value) => {
    console.log(value)
    setDataForm({
      ...dataForm, ...{ quantityTarget: value }, ...{ quantityExchange: value * rate }, ...{ rate }, ...{ radio: '' },
    })
  }
  useEffect(() => {
    form.setFieldsValue(dataForm)
  }, [form, dataForm])

  const onChangeRadio = (e) => {
    console.log('rate', rate)
    console.log('totalExchange', totalExchange)
    switch (e.target.value) {
      case 'a':
        setDataForm({
          ...dataForm, ...{ quantityExchange: totalExchange * 0.25 }, ...{ radio: e.target.value }, ...{ quantityTarget: totalExchange * 0.25 / rate },
        })
        return
      case 'b':
        setDataForm({
          ...dataForm, ...{ quantityExchange: totalExchange * 0.5 }, ...{ radio: e.target.value }, ...{ quantityTarget: totalExchange * 0.5 / rate },
        })
        return
      case 'c':
        setDataForm({
          ...dataForm, ...{ quantityExchange: totalExchange * 0.75 }, ...{ radio: e.target.value }, ...{ quantityTarget: totalExchange * 0.75 / rate },
        })
        return
      case 'd':
        setDataForm({
          ...dataForm, ...{ quantityExchange: totalExchange }, ...{ radio: e.target.value }, ...{ quantityTarget: totalExchange / rate },
        })
        return

      default:
        setDataForm({
          ...dataForm, ...{ quantityExchange: 0 }, ...{ radio: '' }, ...{ quantityTarget: 0 },
        })
    }
  }

  const swapToken = () => {
    setTarget(source)
    setSource(target)
    setPair(pairList[target][source]?.pair)
    setRate(pairList[target][source]?.rate)
    setType(pairList[target][source]?.type)

    // setPair(pairList[source][value]?.pair)
    // setRate(pairList[source][value]?.rate)
    // setType(pairList[source][value]?.type)

    setDataForm({
      ...dataForm, ...{ suffixTarget: source }, ...{ suffix: target }, ...{ quantityExchange: 0 }, ...{ quantityTarget: 0 },
    })

    if (target === 'VND') {
      setTotalExchange(wallet?.data?.fiat?.balance)
    }
    if (target === 'USDT') {
      setTotalExchange(wallet?.data?.usdt?.balance)
      // setDataForm({
      //   ...dataForm, ...{ quantityExchange: dataForm.usdr },
      // })
    }

    if (target === 'USDR') {
      setTotalExchange(wallet?.data?.token?.balance)
      // setDataForm({
      //   ...dataForm, ...{ quantityExchange: dataForm.usdr },
      // })
    }
    if (target === 'REX') {
      setTotalExchange(wallet?.data?.public_token?.balance)
      // setDataForm({
      //   ...dataForm, ...{ quantityExchange: dataForm.rex },
      // })
    }
  }
  return (
    <>
      {/* Modal Otp */}
      <Modal
        className="modalOtp"
        footer={null}
        visible={modalOtp}
        onCancel={() => clearOTP()}
        onOk={() => clearOTP()}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Xác minh mã bảo mật
        </Title>
        <p style={{ marginBottom: 32 }}>
          Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
        </p>
        <OtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={6}
          separator=""
          inputStyle={{
            height: 60,
            width: 50,
            marginRight: 4,
            marginLeft: 4,
            background: '#F5F5F5',
            border: '2px solid #F5F5F5',
            borderRadius: 4,
            marginBottom: 32,
          }}
          containerStyle={{ justifyContent: 'center' }}
          focusStyle={{ border: '2px solid #cf9900' }}
        />
        <p style={{ marginBottom: 32, color: '#121212' }}>
          Nhập mã xác minh gồm 6 chữ số được gửi đến địa email của bạn.
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            disabled={otp.length < 6 || sumbitSwap}
            onClick={handleVerifyOtp}
          >
            Xác nhận
          </Button>
        </p>
        <p style={{ marginBottom: 5, textAlign: 'center' }}>
          Bạn chưa nhận được email?
        </p>
        <div className="text-center">
          <a href="javascript:void(0)" onClick={handleResendOtp}>
            Gửi lại mã xác nhận
          </a>
        </div>
      </Modal>

      {/* Wallet info */}
      {/* <WalletInfo /> */}

      {/* Withdraw amount */}
      <Card className="walletContent" bordered={false}>
        <Form
          form={form}
          name="deposit-amount"
          initialValues={dataForm}
          onFinish={requestSwap}
        >
          <Typography.Title level={5} style={{ fontWeight: 500, marginBottom: 16 }}>
            THÔNG TIN TÀI SẢN NGUỒN
          </Typography.Title>
          <Form.Item
            name="quantityExchange"
            label="Số lượng"
            rules={[{ required: true, message: 'Please input!' }, {
              message: 'Số tiền đổi quá lớn',
              validator: (_, values) => {
                if (values > totalExchange) {
                  return Promise.reject('Some message here')
                }
                return Promise.resolve('')
              },

            },
            ]}
            help={`Số tiền đổi tối đa là ${formatAmount(totalExchange || 0)}`}
            className="mb-3"
          >
            <InputNumber
              formatter={(value) => `${formatAmount(value)}`}
              // parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonAfter={suffixSelector}
              className="w-100"
              defaultValue={quantityExchange}
              onChange={onchangeValue}
            />
          </Form.Item>

          <Form.Item name="radio">
            <Radio.Group onChange={onChangeRadio} defaultValue="">
              <Radio.Button value="a">25%</Radio.Button>
              <Radio.Button value="b">50%</Radio.Button>
              <Radio.Button value="c">75%</Radio.Button>
              <Radio.Button value="d">100%</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <div className="w-100 text-center">
            <Button onClick={swapToken} className="mb-4" type="primary" shape="circle" icon={<SwapOutlined />} />
          </div>
          {/* <Button className="mr-2" onClick={() => {}}>
            <span className="d-flex justify-content-center">
              <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_1941_29776)">
                  <path d="M20.5 40C9.45431 40 0.5 31.0457 0.499999 20C0.499999 8.95431 9.4543 -3.91405e-07 20.5 -8.74228e-07C31.5457 -1.35705e-06 40.5 8.9543 40.5 20C40.5 31.0457 31.5457 40 20.5 40Z" fill="white" />
                  <path d="M22.1078 13.2525L22.1078 27.2326C22.1078 27.321 22.1801 27.3933 22.2685 27.3933L23.4738 27.3933C23.5622 27.3933 23.6346 27.321 23.6346 27.2326L23.6346 15.0746L27.3109 17.9735C27.4154 18.0558 27.5721 17.9815 27.5721 17.8469L27.5721 16.3904C27.5721 16.292 27.5279 16.1996 27.4495 16.1373L23.1484 12.7462C22.7265 12.4148 22.1078 12.7141 22.1078 13.2525ZM17.3667 12.7683L17.3667 24.9264L13.6904 22.0275C13.5859 21.9451 13.4292 22.0195 13.4292 22.154L13.4292 23.6105C13.4292 23.709 13.4734 23.8014 13.5517 23.8636L17.8529 27.2547C18.2747 27.5862 18.8935 27.2869 18.8935 26.7505L18.8935 12.7683C18.8935 12.6799 18.8212 12.6076 18.7328 12.6076L17.5274 12.6076C17.439 12.6076 17.3667 12.6799 17.3667 12.7683Z" fill="#121212" />
                  <path d="M39.5 20C39.5 30.4934 30.9934 39 20.5 39L20.5 41C32.098 41 41.5 31.598 41.5 20L39.5 20ZM20.5 0.999999C30.9934 0.999999 39.5 9.50659 39.5 20L41.5 20C41.5 8.40202 32.098 -1 20.5 -1L20.5 0.999999ZM1.5 20C1.5 9.50659 10.0066 1 20.5 0.999999L20.5 -1C8.90202 -1 -0.500001 8.40202 -0.500001 20L1.5 20ZM-0.500001 20C-0.5 31.598 8.90202 41 20.5 41L20.5 39C10.0066 39 1.5 30.4934 1.5 20L-0.500001 20Z" fill="#D9D9D9" />
                </g>
                <defs>
                  <filter id="filter0_d_1941_29776" x="0.5" y="0" width="40" height="42" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.043 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1941_29776" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1941_29776" result="shape" />
                  </filter>
                </defs>
              </svg>

            </span>
          </Button> */}

          <Typography.Title level={5} style={{ fontWeight: 500, marginBottom: 16 }}>
            THÔNG TIN TÀI SẢN NHẬN
          </Typography.Title>
          <Form.Item
            name="quantityTarget"
            label="Số lượng"
            rules={[
              { required: true, message: 'Please input quantity!' },
            ]}
          >
            <InputNumber
              formatter={(value) => `${formatAmount(value)}`}
              // parser={(value) => formatAmount(value)}
              addonAfter={suffixSelectorTarget}
              style={{ width: '100%' }}
              onChange={onchangeValueTarget}

            />
          </Form.Item>

          <Form.Item
            name="rate"
            label="Tỉ giá quy đổi "

          >
            {
              pair === 'USDR/VND' ? (
                <span style={{ color: '#CF9900' }}>
                  1 USDR =  23,000 VND
                </span>
              ) : pair === 'USDR/USDT' ? (
                <span style={{ color: '#CF9900' }}>
                  1 USDR =  1 USDT
                </span>
              )
                : pair === 'REX/USDT' ? (
                  <span style={{ color: '#CF9900' }}>
                    1 REX =  1 USDT
                  </span>
                )
                  : pair === 'REX/VND' ? (
                    <span style={{ color: '#CF9900' }}>
                      1 REX =  23,000 VND
                    </span>
                  )
                    : pair === 'REX/USDR' ? (
                      <span style={{ color: '#CF9900' }}>
                        1 REX =  1 USDR
                      </span>
                    )
                      : (
                        <></>
                      )
            }

          </Form.Item>

          <Button type="primary" className="mr-2" htmlType="submit">
            Mua Token
          </Button>
          {/* <Button icon={<PhoneOutlined />} onClick={() => setModalContactInfo(true)}>
            Liên hệ hỗ trợ
          </Button> */}
        </Form>
      </Card>
      <div className="m-t-40">

        {/* table balance */}
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          pagination={{ position: 'bottomCenter' }}
        />
      </div>
    </>
  )
}

Swap.propTypes = {
  wallet: PropTypes.shape(),
  getWallet: PropTypes.func,

}

const mapDispatchToProps = (dispatch) => ({
  getWallet: () => dispatch(walletAction.get()),

})
export default connect(
  (state) => (
    {
      wallet: getWalletData(state),
    }), mapDispatchToProps,
)(Swap)
