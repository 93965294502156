import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import { Route, Link, BrowserRouter as Router } from 'react-router-dom'
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Layout,
  Steps,
} from 'antd'
import ReactCodeInput from 'react-verification-code-input'
import successIcon from '../../../static/images/reset-pwd.png'
import user from '../../../provider/user'

const { Step } = Steps

const steps = [
  {
    title: 'Nhập email',
    content: 'Xác minh địa chỉ email',
    subcontent: 'Nhập địa chỉ email được dùng để đăng ký tài khoản REX Block',
  },
  // {
  //   title: 'Xác minh',
  //   content: 'Xác minh bảo mật',
  //   subcontent: 'Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau',
  // },
  {
    title: 'Xác minh và đặt lại mật khẩu',
    content: 'Đặt lại mật khẩu',
    subcontent: 'Vui lòng nhập lại mật khẩu mới, yêu cầu 8 ký tự trở lên',
  },
  {
    title: 'Hoàn tất',
    content: 'Xin chúc mừng',
    subcontent: 'Bạn đã đặt lại mật khẩu mới thành công. Vui lòng đăng nhập lại bằng mật khẩu mới',
  },
]

const ResetPassword = () => {
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const [currentStep, setCurrentStep] = useState(0)
  const [codeInput, setCodeInput] = useState({ width: 58, height: 54 })
  useEffect(() => {
    const createdWidth = window.innerWidth
    if (createdWidth >= 1440) setCodeInput({ width: 58, height: 54 })
    if (createdWidth >= 1024 && createdWidth < 1440) setCodeInput({ width: 54, height: 50 })
    if (createdWidth > 768 && createdWidth < 1024) setCodeInput({ width: 50, height: 46 })
    if (createdWidth <= 768) setCodeInput({ width: 46, height: 42 })

    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1440) setCodeInput({ width: 58, height: 54 })
      if (window.innerWidth >= 1024 && window.innerWidth < 1440) setCodeInput({ width: 54, height: 50 })
      if (window.innerWidth > 768 && window.innerWidth < 1024) setCodeInput({ width: 50, height: 46 })
      if (window.innerWidth <= 768) setCodeInput({ width: 46, height: 42 })
    })
  }, [])

  const [form] = Form.useForm()
  const [form1] = Form.useForm()

  const sendEmail = async (value) => {
    const resp = await user.forgotPassword(value)
    console.log(resp)
    if (resp.code === 200) {
      setUserId(resp.data.user_id)
      setCurrentStep(1)
    }
  }

  const confirmPassword = async (data) => {
    const resp = await user.confirmForgotPassword({ ...data, ...{ user_id: userId } })
    console.log(resp)
    if (resp.code === 200) {
      setCurrentStep(2)
    }
  }

  const stepContent = () => {
    console.log(currentStep)
    return (
      <div className="mt-4">
        <div className="text-center">
          <h4>{steps[currentStep].content}</h4>
          <p>{steps[currentStep].subcontent}</p>
        </div>
        {
          (() => {
            switch (currentStep) {
              case 0:
                return (
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={sendEmail}
                    className="login-form"

                  >
                    <Form.Item name="email" className="item-form" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        placeholder="Email"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        htmlType="submit"
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                  </Form>
                )

                // case 1:
                // return (
                //   <Form className="login-form">
                //     <Form.Item className="item-form" rules={[{ required: true }]}>
                //       <div className="d-flex justify-content-center">
                //         <ReactCodeInput
                //           fieldWidth={codeInput.width}
                //           fieldHeight={codeInput.height}
                //         />
                //       </div>
                //       <div className="text-center">Nhập mã xác minh gồm 6 chữ số</div>
                //     </Form.Item>
                //     <Form.Item>
                //       <Button
                //         type="primary"
                //         className="btn-block m-t-15"
                //         size="large"
                //         onClick={() => setCurrentStep(2)}
                //       >
                //         Tiếp tục
                //       </Button>
                //     </Form.Item>
                //     <div className="text-center">
                //       <div>Bạn chưa nhận được mã?</div>
                //       <Link href="#">Gửi lại mã xác nhận</Link>
                //     </div>
                //   </Form>
                // )

              case 1:
                return (
                  <Form
                    form={form1}
                    layout="vertical"
                    onFinish={confirmPassword}
                    className="login-form"
                  >
                    <Form.Item name="number_verify" className="item-form" label="Mã xác thực" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        placeholder="Nhập Mã xác thực"
                      />
                    </Form.Item>

                    <Form.Item name="password" className="item-form" label="Mật khẩu mới" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        type="password"
                        placeholder="Nhập mật khẩu mới"
                      />
                    </Form.Item>
                    <Form.Item name="confirm_password" className="item-form" label="Xác nhận mật khẩu" rules={[{ required: true }]}>
                      <Input
                        size="large"
                        type="password"
                        placeholder="Xác nhận mật khẩu"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        size="large"
                        htmlType="submit"
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                  </Form>
                )

              case 2:
                return (
                  <div className="text-center">
                    <img src={successIcon} />
                    <Link to="/">
                      <Button
                        type="primary"
                        className="btn-block m-t-15"
                        size="large"
                      >
                        Đăng nhập
                      </Button>
                    </Link>
                  </div>
                )

              default:
                return null
            }
          })()
        }
      </div>
    )
  }

  return (
    <Layout>
      <Row>
        <Col span={24} className="form">
          <div className="form-container">
            <div className="text-left form-title">
              {/* <img src={logo} /> */}
              <h1 className="my-4 text-center" style={{ color: 'black' }}>Đặt lại mật khẩu</h1>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <div className="step-title">
                <Steps current={currentStep}>
                  {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
              </div>
            </div>
            <div className="step-content">
              {stepContent()}
            </div>
          </div>
        </Col>
      </Row>
    </Layout>
  )
}

export default ResetPassword
