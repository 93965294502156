/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import styled from 'styled-components'
import {
  Row,
  Col,
  Card,
  Typography, Tabs,
  Button, Form, Input,
  DatePicker,
  Modal,
  Table,
  Tag,
  Space,
  Avatar,
  Select, message,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  ClockCircleOutlined,
  CloseOutlined, RightOutlined, UserOutlined, LockOutlined, DollarOutlined, StockOutlined, UserSwitchOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { TablePaginationConfig } from 'antd/lib/table'
import tokenBreIcon from '../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../static/images/TokenVNĐ.png'
import tokenUsdrIcon from '../../static/images/TokenUSDR.png'
import product from '../../provider/product'
import wallet from '../../provider/wallet'
import ido from '../../provider/ido'

import { formatAmount } from '../../utils/common'

// const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`
const { TabPane } = Tabs
const { Option } = Select
const { Title } = Typography

export const ASSET = {
  USDT: 'USDT',
  VND: 'VND',
  USDR: 'USDR',
  REX: 'REX',
}

const ASSET_ICON = {
  [ASSET.USDT]: tokenUsdtIcon,
  [ASSET.VND]: tokenVndIcon,
  [ASSET.USDR]: tokenUsdrIcon,
  [ASSET.REX]: tokenBreIcon,
}

const ASSET_COIN = {
  [ASSET.USDT]: 'Tether',
  [ASSET.VND]: 'Việt Nam đồng',
  [ASSET.USDR]: 'USDR Coin',
  [ASSET.REX]: 'REX Coin',
}

export const WALLET_STATUS = {
  W: 'W',
  P: 'P',
  PC: 'PC',
  C: 'C',
  CL: 'CL',
  F: 'F',
  WUT: 'WUT',
  WSV: 'WSV',
  RF: 'RF',
}

export const IDO_STATUS = {
  FF: 'FF',
  F: 'F',
  PC: 'PC',
  PR: 'PR',
  P: 'P',
  RF: 'RF',
  FN: 'FN',
}

export const ORDER_STATUS = {
  WFS: 'WFS',
  WFC: 'WFC',
  FF: 'FF',
  F: 'F',
  PC: 'PC',
  PR: 'PR',
  P: 'P',
  CL: 'CL',
  RF: 'RF',
  DP: 'DP',
}

const STATUS_COLORS = {
  [WALLET_STATUS.W]: 'waiting',
  [WALLET_STATUS.P]: 'waiting',
  [WALLET_STATUS.PC]: 'success',
  [WALLET_STATUS.C]: 'success',
  [WALLET_STATUS.CL]: 'fail',
  [WALLET_STATUS.F]: 'fail',
  [WALLET_STATUS.WSV]: 'waiting',
  [WALLET_STATUS.WUT]: 'waiting',
  [WALLET_STATUS.RF]: 'fail',
  [IDO_STATUS.FF]: 'success',
  [IDO_STATUS.F]: 'fail',
  [IDO_STATUS.PC]: 'waiting',
  [IDO_STATUS.PR]: 'waiting',
  [IDO_STATUS.P]: 'waiting',
  [IDO_STATUS.RF]: 'success',
  [IDO_STATUS.FN]: 'success',
}

export const STATUS_NAMES = {
  [WALLET_STATUS.W]: 'Chờ xác nhận',
  [WALLET_STATUS.P]: 'Chưa xử lý',
  [WALLET_STATUS.PC]: 'Tiến hành trả',
  [WALLET_STATUS.C]: 'Giao dịch thành công',
  [WALLET_STATUS.CL]: 'Không xác nhận',
  [WALLET_STATUS.F]: 'Thất bại',
  [WALLET_STATUS.WSV]: 'Đang xác nhận',
  [WALLET_STATUS.WUT]: 'Chờ chuyển tiền',
  [WALLET_STATUS.RF]: 'Hoàn tiền',
}
export const IDO_STATUS_NAMES = {
  [IDO_STATUS.FF]: 'Hoàn tất',
  [IDO_STATUS.F]: 'Thất bại',
  [IDO_STATUS.PC]: 'Đang xử lý',
  [IDO_STATUS.PR]: 'Chuẩn bị',
  [IDO_STATUS.P]: 'Chưa xử lý',
  [IDO_STATUS.RF]: 'Hoàn tiền',
  [IDO_STATUS.FN]: 'Kết thúc',
}

export const ORDER_STATUS_NAMES = {
  [ORDER_STATUS.WFS]: 'Chờ gửi tiền',
  [ORDER_STATUS.WFC]: 'Chờ xác nhân tiền',
  [ORDER_STATUS.FF]: 'Hoàn tất',
  [ORDER_STATUS.P]: 'Chưa xử lý',
  [ORDER_STATUS.PC]: 'Đang xử lý',
  [ORDER_STATUS.PR]: 'Chuẩn bị',
  [ORDER_STATUS.F]: 'Thất bại',
  [ORDER_STATUS.RF]: 'Hoàn tiền',
  [ORDER_STATUS.FN]: 'Kết thúc',
  [ORDER_STATUS.DP]: 'Khiếu nại',
}

export const STATUS_ORDER_COLORS = {
  [ORDER_STATUS.WFS]: 'waiting',
  [ORDER_STATUS.WFC]: 'waiting',
  [ORDER_STATUS.P]: 'waiting',
  [ORDER_STATUS.W]: 'waiting',
  [ORDER_STATUS.PC]: 'waiting',
  [ORDER_STATUS.PR]: 'waiting',
  [ORDER_STATUS.RF]: 'fail',
  [ORDER_STATUS.FF]: 'success',
  [ORDER_STATUS.F]: 'fail',
  [ORDER_STATUS.DP]: 'fail',

}
const History = () => {
  const columnsWallet = [
    {
      title: 'Loại tài sản',
      dataIndex: 'asset',
      key: 'asset',
      render: (text) => (
        <div className="d-flex align-items-center">

          <span className="mr-2">
            <Avatar src={ASSET_ICON[text]} />
          </span>
          <div>
            <p className="mr-1 m-b-0 font-weight-500">{text}</p>
            <p className="mr-1 m-b-0">{ASSET_COIN[text]}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'ID giao dịch',
      dataIndex: 'no',
      key: 'no',
      render: (text) => (
        <span className="">{text}</span>
      ),
    },
    {
      title: 'Hình thức',
      dataIndex: 'type',
      key: 'type',
      render: (text) => text === 'D' ? (
        <div className="recharge font-weight-500">Nạp</div>
      ) : text === 'W' ? (
        <div className="withdraw font-weight-500">Rút</div>
      ) : text === 'S' ? (
        <div className="recharge font-weight-500">Swap</div>
      ) : ({ text }),
    },

    {
      title: 'Số lượng',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">{formatAmount(text)}</span>,
    },
    {
      title: 'Đích đến',
      key: 'targetAsset',
      dataIndex: 'targetAsset',
      render: (text) => (
        <div className="d-flex align-items-center">
          <p className="mr-1 m-b-0 font-weight-500">{text}</p>
        </div>
      ),
    },
    {
      title: 'Phí giao dịch (REX)',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">-</span>,
    },
    {
      title: 'Thời gian',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text) => <span className="font-weight-500">{moment(text).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text) => (
        <p className={`text-row ${STATUS_COLORS[text]}`}>
          {STATUS_NAMES[text]}
        </p>
      ),
    },
  ]

  const columnsIDO = [

    {
      title: 'ID giao dịch',
      dataIndex: 'order_no',
      key: 'order_no',
      render: (text) => (
        <span>{text}</span>
      ),
    },
    {
      title: 'Mã cổ phần',
      dataIndex: 'symbol',
      key: 'symbol',
      render: (text) => (
        <span>{text}</span>
      ),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{text}</span>
        </div>
      ),
    },
    {
      title: 'Phí giao dịch',
      dataIndex: 'fee',
      key: 'fee',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">-</span>
        </div>
      ),
    },
    {
      title: 'Thời gian',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text) => <span className="font-weight-500">{moment(text).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text) => (
        <p className={`text-row ${STATUS_COLORS[text]}`}>
          {IDO_STATUS_NAMES[text]}
        </p>
      ),
    },
  ]

  const columnsOrder = [

    {
      title: 'ID giao dịch',
      dataIndex: 'order_no',
      key: 'order_no',
      render: (text) => (
        <span className="">{text}</span>
      ),
    },
    {
      title: 'Mã cổ phần',
      dataIndex: 'stockInfo',
      key: 'stockInfo',
      render: (stockInfo) => (
        <div className="d-flex align-items-center">
          {stockInfo?.symbol}
        </div>
      ),
    },
    {
      title: 'Loại giao dich',
      dataIndex: 'type',
      key: 'type',
      render: (text) => text === 'B' ? (
        <div className="recharge font-weight-500">Mua</div>
      ) : text === 'S' ? (
        <div className="withdraw font-weight-500">Bán</div>
      ) : ({ text }),
    },

    {
      title: 'Số lượng',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">{formatAmount(text)}</span>,
    },
    {
      title: 'Thông tin người nhận',
      key: 'owner',
      dataIndex: 'owner',
      render: (text) => (
        <div className="d-flex align-items-center">
          <p className="mr-1 m-b-0 font-weight-500">{text}</p>
        </div>
      ),
    },
    {
      title: 'Đơn giá',
      key: 'price',
      dataIndex: 'price',
      render: (text, row) => (
        <span className="font-weight-500">
          {formatAmount(text)}
          {' '}
          {row?.orderbook?.bases}
        </span>
      ),
    },
    {
      title: 'Phí giao dịch (REX)',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">-</span>,
    },
    {
      title: 'Thời gian',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text) => <span className="font-weight-500">{moment(text).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text) => (
        <p className={`text-row ${STATUS_ORDER_COLORS[text]}`}>
          {ORDER_STATUS_NAMES[text]}
        </p>
      ),
    },
  ]
  const [modalTransactionDetail, setModalTransactionDetail] = useState(false)
  const [modalOtpSuccess, setModalOtpSuccess] = useState(false)
  const [total, setTotal] = useState(10)
  const [data, setData] = useState([])
  const [totalHistoryWallet, setTotalHistoryWallet] = useState(10)

  const [dataHistoryWallet, setDataHistoryWallet] = useState([])
  const [dataHistoryOrder, setDataHistoryOrder] = useState([])
  const [dataHistoryIDO, setDataHistoryIDO] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
  })

  const [querySearchWalletHistory, setQuerySearchWalletHistory] = useState({
    current: 1,
    pageSize: 10,
    type: '',
    asset: '',
    date: '',
    status: '',
  })

  const [querySearchOrderHistory, setQuerySearchOrdertHistory] = useState({
    current: 1,
    pageSize: 10,
    status: '',
  })

  const [querySearchIDO, setQuerySearchIDO] = useState({
    current: 1,
    pageSize: 10,
    status: '',
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })

  const [paginationWallet, setPaginationWallet] = useState({
    current: 1,
    pageSize: 10,
  })

  const [paginationOrder, setPaginationOrder] = useState({
    current: 1,
    pageSize: 10,
  })

  const [paginationIDO, setPaginationIDO] = useState({
    current: 1,
    pageSize: 10,
  })

  const onChange = (date, dateString) => {
    console.log(date, dateString)
  }

  const searchHistoryWallet = async (param) => {
    let filter = {

    }
    if (param?.type) {
      filter = {
        ...filter,
        ...{
          type: {
            $in: [param?.type],
          },
        },

      }
    }

    if (param?.asset) {
      filter = {
        ...filter,
        ...{
          asset: {
            $in: [param?.asset],
          },
        },

      }
    }

    if (param?.status) {
      filter = {
        ...filter,
        ...{
          status: {
            $in: [param?.status],
          },
        },

      }
    }
    const payload = {
      filter,
      sort: {
        updatedAt: -1,
      },

    }
    console.log(payload)
    const resp = await wallet.searchHistory({ page: param.current || 1, limit: param.pageSize || 10 }, payload)
    console.log(resp)
    setDataHistoryWallet(resp.data?.transactions)
    setPaginationWallet({ ...paginationWallet, ...{ total: resp.data?.total }, ...{ current: param.current } })
    // setTotalHistoryWallet(resp.data?.total)
  }

  const searchHistoryOrder = async (param) => {
    let filter = {

    }
    if (param?.type) {
      filter = {
        ...filter,
        ...{
          type: {
            $in: [param?.type],
          },
        },

      }
    }

    if (param?.asset) {
      filter = {
        ...filter,
        ...{
          asset: {
            $in: [param?.asset],
          },
        },

      }
    }

    if (param?.status) {
      filter = {
        ...filter,
        ...{
          status: {
            $in: [param?.status],
          },
        },

      }
    }
    const payload = {
      query: filter,
      order: {
        updatedAt: -1,
      },

    }
    console.log(payload)
    const resp = await ido.getHistory({ page: param.current || 1, limit: param.pageSize || 10, type: 'order' }, payload)
    console.log(resp)
    setDataHistoryOrder(resp.data?.data)
    setPaginationOrder({ ...paginationOrder, ...{ total: resp.data?.total }, ...{ current: param.current } })
    // setTotalHistoryWallet(resp.data?.total)
  }

  const searchHistoryIDO = async (param) => {
    let filter = {

    }
    if (param?.status) {
      filter = {
        ...filter,
        ...{
          status: {
            $in: [param?.status],
          },
        },

      }
    }
    const payload = {
      query: filter,
      order: {
        updatedAt: -1,
      },

    }
    console.log(payload)
    const resp = await product.getHistory({ page: param.current || 1, limit: param.pageSize || 10, status: param?.status }, payload)
    console.log(resp)
    setDataHistoryIDO(resp.data?.data)
    setPaginationIDO({ ...paginationIDO, ...{ total: resp.data?.total }, ...{ current: param.current } })
    // setTotalHistoryWallet(resp.data?.total)
  }
  const fetchHistoryIDO = async (params) => {
    await searchHistoryIDO({ ...querySearchIDO, current: params?.current || 1, pageSize: params?.pageSize || 10 })
    setPaginationIDO({ ...params })
  }

  const fetchHistorywallet = async (params) => {
    await searchHistoryWallet({ ...querySearchWalletHistory, current: params?.current || 1, pageSize: params?.pageSize || 10 })
    setPaginationWallet({ ...params })
  }

  const fetchHistoryOrder = async (params) => {
    await searchHistoryOrder({ ...querySearchOrderHistory, current: params?.current || 1, pageSize: params?.pageSize || 10 })
    setPaginationOrder({ ...params })
  }

  const onchangeTypeSearchWallet = async (e) => {
    setQuerySearchWalletHistory({ ...querySearchWalletHistory, type: e })
    await searchHistoryWallet({ ...querySearchWalletHistory, type: e })
    console.log(e)
  }

  const onchangeAssetSearchWallet = async (e) => {
    setQuerySearchWalletHistory({ ...querySearchWalletHistory, asset: e })
    await searchHistoryWallet({ ...querySearchWalletHistory, asset: e })
    console.log(e)
  }

  const onchangeStatusSearchWallet = async (e) => {
    setQuerySearchWalletHistory({ ...querySearchWalletHistory, status: e })
    await searchHistoryWallet({ ...querySearchWalletHistory, status: e })
    console.log(e)
  }

  const onchangeTypeSearchOrder = async (e) => {
    setQuerySearchOrdertHistory({ ...querySearchOrderHistory, type: e })
    await searchHistoryOrder({ ...querySearchOrderHistory, type: e })
    console.log(e)
  }

  const onchangeStatusSearchOrder = async (e) => {
    setQuerySearchOrdertHistory({ ...querySearchOrderHistory, status: e })
    await searchHistoryOrder({ ...querySearchOrderHistory, status: e })
    console.log(e)
  }

  const onchangeStatusSearchIDO = async (e) => {
    setQuerySearchIDO({ ...querySearchIDO, status: e })
    await searchHistoryIDO({ ...querySearchIDO, status: e })
  }
  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }
  useEffect(() => {
    searchHistoryIDO(querySearchIDO)
    searchHistoryWallet(querySearchWalletHistory)
    searchHistoryOrder(querySearchOrderHistory)

    return () => {}
  }, [])

  return (
    <>
      <Modal
        footer={null}
        visible={modalTransactionDetail}
        onOk={() => setModalTransactionDetail(false)}
        onCancel={() => setModalTransactionDetail(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Chi tiết giao dịch
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>ID NDT</DescriptionTextLeft>
          <DescriptionTextRight>123456789</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Mã giao dịch</DescriptionTextLeft>
          <DescriptionTextRight>hoangtaynapvnd1</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
          <TextColorGreen>Nạp / VND</TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Ngày</DescriptionTextLeft>
          <DescriptionTextRight>30/01/2021 23:59</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số lượng</DescriptionTextLeft>
          <DescriptionTextRight>+12.000.000 VND</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
          <TextColorGreen>Hoàn tất</TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Ngân hàng</DescriptionTextLeft>
          <DescriptionTextRight>Vietcombank</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số tài khoản</DescriptionTextLeft>
          <DescriptionTextRight>001100345678</DescriptionTextRight>
        </DescriptionText>
        <DescriptionTextEnd>
          <DescriptionTextLeft>Chủ tài khoản</DescriptionTextLeft>
          <DescriptionTextRight>Hoang Tay</DescriptionTextRight>
        </DescriptionTextEnd>
        <p>
          <Button
          // type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => setModalTransactionDetail(false)}
          >
            Đóng
          </Button>
        </p>
      </Modal>
      <Typography.Title level={2}>Lịch sử giao dịch</Typography.Title>
      <div className="mb-4">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={(
              <span>
                <DollarOutlined />
                Nạp / Rút / Swap
              </span>
            )}
            key="1"
          >
            <Row gutter={16} className="m-b-20 m-t-10 m-l-3">
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Hình thức: </span>
                  <Select
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    defaultValue={querySearchWalletHistory.type || ''}
                    onChange={(e) => onchangeTypeSearchWallet(e)}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="D">Nạp</Option>
                    <Option value="W">Rút</Option>
                    <Option value="S">Swap</Option>

                  </Select>
                </div>
              </Col>
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Loại tài sản: </span>
                  <Select
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    defaultValue={querySearchWalletHistory.asset || ''}
                    onChange={(e) => onchangeAssetSearchWallet(e)}

                  >
                    <Option value="">Tất cả</Option>
                    <Option value="USDT">USDT</Option>
                    <Option value="USDR">USDR</Option>
                    <Option value="VND">VND</Option>
                    <Option value="REX">REX</Option>

                  </Select>
                </div>
              </Col>
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Thời gian: </span>
                  <Select
                    defaultValue=""
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="W">7 ngày</Option>
                    <Option value="M">30 Ngày</Option>
                    <Option value="3M">3 tháng</Option>
                  </Select>
                </div>
              </Col>
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Trạng thái: </span>
                  <Select
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    defaultValue={querySearchWalletHistory.status || ''}
                    onChange={(e) => onchangeStatusSearchWallet(e)}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="W">Chờ xác nhận</Option>
                    <Option value="P">Chưa xử lý</Option>
                    <Option value="PC">Tiến hành trả</Option>
                    <Option value="CL">không xác nhận</Option>
                    <Option value="C">Giao dịch thành công</Option>
                    <Option value="F">Thất bại</Option>
                    <Option value="WSV">Đang xác nhận</Option>
                    <Option value="WUT">Chờ chuyển tiền</Option>
                    <Option value="RF">Hoàn tiền</Option>

                  </Select>
                </div>
              </Col>
            </Row>
            <Table
              scroll={{ x: true }}
              columns={columnsWallet}
              dataSource={dataHistoryWallet}
              pagination={paginationWallet}
              onChange={fetchHistorywallet}
            />
          </TabPane>
          <TabPane
            tab={(
              <span>
                <StockOutlined />
                Đầu tư
              </span>
            )}
            key="2"
          >
            <Row gutter={16} className="m-b-20 m-t-10 m-l-3">
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Thời gian: </span>
                  <Select
                    defaultValue=""
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="W">7 ngày</Option>
                    <Option value="M">30 Ngày</Option>
                    <Option value="3M">3 tháng</Option>
                  </Select>
                </div>
              </Col>

              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Trạng thái: </span>
                  <Select
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    defaultValue={querySearchIDO.status || ''}
                    onChange={(e) => onchangeStatusSearchIDO(e)}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="FF">Hoàn tất</Option>
                    <Option value="F">Thất bại</Option>
                    <Option value="PC">Đang xử lý</Option>
                    <Option value="PR">Chuẩn bị</Option>
                    <Option value="P">Chưa xử lý</Option>
                    <Option value="RF">Hoàn tiền</Option>
                    <Option value="FN">Kết thúc</Option>

                  </Select>
                </div>
              </Col>
            </Row>
            <Table
              scroll={{ x: true }}
              columns={columnsIDO}
              dataSource={dataHistoryIDO}
              pagination={paginationIDO}
              onChange={fetchHistoryIDO}
            />
          </TabPane>
          <TabPane
            tab={(
              <span>
                <UserSwitchOutlined />
                Chuyển nhượng
              </span>
            )}
            key="3"
          >

            <Row gutter={16} className="m-b-20 m-t-10 m-l-3">
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Loại giao dịch: </span>
                  <Select
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    defaultValue={querySearchOrderHistory.type || ''}
                    onChange={(e) => onchangeTypeSearchOrder(e)}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="B">Mua</Option>
                    <Option value="S">Bán</Option>
                  </Select>
                </div>
              </Col>
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Thời gian: </span>
                  <Select
                    defaultValue=""
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="W">7 ngày</Option>
                    <Option value="M">30 Ngày</Option>
                    <Option value="3M">3 tháng</Option>
                  </Select>
                </div>
              </Col>
              <Col className="gutter-row my-2" xs={{ span: 12 }} lg={{ span: 6 }}>
                <div>
                  <span>Trạng thái: </span>
                  <Select
                    optionFilterProp="children"
                    style={{ minWidth: 150 }}
                    defaultValue={querySearchOrderHistory.status || ''}
                    onChange={(e) => onchangeStatusSearchOrder(e)}
                  >
                    <Option value="">Tất cả</Option>
                    <Option value="W">Chờ xác nhận</Option>
                    <Option value="P">Chưa xử lý</Option>
                    <Option value="PC">Tiến hành trả</Option>
                    <Option value="CL">không xác nhận</Option>
                    <Option value="C">Giao dịch thành công</Option>
                    <Option value="F">Thất bại</Option>
                    <Option value="WSV">Đang xác nhận</Option>
                    <Option value="WUT">Chờ chuyển tiền</Option>
                    <Option value="RF">Hoàn tiền</Option>

                  </Select>
                </div>
              </Col>
            </Row>
            <Table
              scroll={{ x: true }}
              columns={columnsOrder}
              dataSource={dataHistoryOrder}
              pagination={paginationOrder}
              onChange={fetchHistoryOrder}
            />
          </TabPane>

        </Tabs>

      </div>

    </>
  )
}

export default History
