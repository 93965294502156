import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs,
  InputNumber,
  Upload,
  Select,
  Radio,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
  PhoneOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined, PlusOutlined, CheckCircleOutlined, DeleteOutlined,
} from '@ant-design/icons'
// import './index.css'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import { toast } from 'react-toastify'
import moment from 'moment'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import ModalContactInfo from '../contact-info'
import WalletInfoUsdt from '../wallet-info-usdt'
import wallet from '../../../provider/wallet'
import { formatAmount } from '../../../utils/common'

const { Step } = Steps
const { Option } = Select
const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`

const TitleDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 16px;
`

const suffixSelector = (
  <Form.Item name="suffix" noStyle>
    <div className="d-flex align-items-center">
      <span className="mr-2">USDT</span>
      <Avatar src={tokenUsdtIcon} size={18} />
    </div>
  </Form.Item>
)

const
  WithDrawUSDT = (classe) => {
    useEffect(() => { }, [])
    const { TabPane } = Tabs

    const [modalConfirm, setModalConfirm] = useState(false)
    const [otp, setOTP] = useState('')
    const [visible, setVisible] = useState(false)
    const [modalOtp, setModalOtp] = useState(false)
    const [modalTransactionDetail, setModalTransactionDetail] = useState(false)
    const [modalContactInfo, setModalContactInfo] = useState(false)

    const [modalTransactionDetailWithDraw, setModalTransactionDetailWithDraw] = useState(false)

    const [query, setQuery] = useState({
      currency: 'USDT',
      page: 1,
      limit: 10,
    })

    const [infoRequest, setInfoRequest] = useState({})
    const [data, setData] = useState([])

    const openDetailRequest = (data) => {
      setInfoRequest(data)
      setModalTransactionDetail(true)
    }

    const columns = [
      {
        title: 'Mã tham chiếu',
        key: 'no',
        dataIndex: 'no',
        render: (text) => <span className="font-weight-500">{text || '-'}</span>,
      },
      {
        title: 'Số lượng',
        key: 'amount',
        dataIndex: 'amount',
        render: (text) => (
          <div>
            <span className="mr-1 font-weight-500">{formatAmount(text || 0)}</span>
            <span>USDT</span>
          </div>
        ),
      },
      {
        title: 'Thời gian',
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        render: (text) => (
          <span className="font-weight-500">
            {moment(text).format('MM/DD/YYYY HH:mm:ss')}
          </span>
        ),
      },
      {
        title: 'Trạng thái',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: (text, row) => (
          <div className="text-right">
            {text === 'C' ? (
              <span className="success">
                Hoàn tất
              </span>
            )
              : text === 'F' ? (
                <span className="fail">
                  Lỗi xảy ra
                </span>
              )
                : text === 'W' ? (
                  <span className="waiting">
                    Khởi tạo
                  </span>
                )
                  : text === 'P' ? (
                    <span className="waiting">
                      Chờ xác nhận
                    </span>
                  )

                    : text === 'PC' ? (
                      <span className="waiting">
                        Đang xử lý
                      </span>
                    )

                      : text === 'CL' ? (
                        <span className="fail">
                          Hủy
                        </span>
                      )
                        : (
                          <>{text}</>
                        )}
            <span>
              <Button
                onClick={() => openDetailRequest(row)}
                shape="circle"
                icon={<RightOutlined className="colorIcon" />}
                size="small"
              />
            </span>

          </div>
        ),
      },
    ]

    const onChange = () => {

    }

    const getListwithdraw = async (params) => {
      const resp = await wallet.getListwithdraw(params)
      console.log('list twithdraw', resp)
      setData(resp.data)
    }
    useEffect(() => {
      getListwithdraw(query)
    }, [])

    const handleChangeOtp = (otp) => {
      setOTP(otp)
    }

    const handleVerifyOtp = async () => {
      try {
        console.log(otp)
        const payload = {
          no: infoRequest.no,
          code: otp,
          currency: 'USDT',
        }
        console.log(payload)
        const resp = await wallet.verifyWithdraw(payload)
        toast.success('yêu cầu của bạn đã được gửi.')
        getListwithdraw(query)
        setModalOtp(false)
        console.log(resp)
      } catch (error) {
        console.log(error)
      }
    }

    const clearOTP = () => {
      setModalOtp(false)
      setOTP('')
    }

    const handleResendOtp = () => {
    }

    const handleWithDraw = () => {
      setModalTransactionDetailWithDraw(false)
      setModalOtp(true)
    }

    const requestWithdraw = async (data) => {
      console.log(data)
      const payload = {
        amount: data.amount,
        toAddr: data.address,
        currency: 'USDT',
      }
      const resp = await wallet.withdraw(payload)
      console.log(resp)
      getListwithdraw(query)
      setInfoRequest(resp.data)
      setModalTransactionDetailWithDraw(false)
      setModalOtp(true)
    }
    return (
      <>

        <Modal
          footer={null}
          visible={modalTransactionDetail}
          onOk={() => setModalTransactionDetail(false)}
          onCancel={() => setModalTransactionDetail(false)}
        >
          <Title level={3} className="font-weight-500 mb-3">
            Chi tiết giao dịch
          </Title>
          <DescriptionText>
            <DescriptionTextLeft>ID NDT</DescriptionTextLeft>
            <DescriptionTextRight>{infoRequest?.user}</DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Mã giao dịch</DescriptionTextLeft>
            <DescriptionTextRight>{infoRequest?.no}</DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
            <TextColorGreen>Rút / USDT</TextColorGreen>
          </DescriptionText>
          <hr />
          <DescriptionText>
            <DescriptionTextLeft>Ngày</DescriptionTextLeft>
            <DescriptionTextRight>{infoRequest?.createdAt}</DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Số lượng</DescriptionTextLeft>
            <DescriptionTextRight>
              {infoRequest?.amount?.toLocaleString()}
              {' '}
              USDT
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
            <TextColorGreen>
              {infoRequest?.status === 'C' ? (
                <span className="success">
                  Hoàn tất
                </span>
              )
                : infoRequest?.status === 'F' ? (
                  <span className="fail">
                    Lỗi xảy ra
                  </span>
                )
                  : infoRequest?.status === 'W' ? (
                    <span className="waiting">
                      Khởi tạo
                    </span>
                  )
                    : infoRequest?.status === 'P' ? (
                      <span className="waiting">
                        Chờ xác nhận
                      </span>
                    )

                      : infoRequest?.status === 'PC' ? (
                        <span className="waiting">
                          Đang xử lý
                        </span>
                      )

                        : infoRequest?.status === 'CL' ? (
                          <span className="fail">
                            Hủy
                          </span>
                        )
                          : (
                            <>{infoRequest?.status}</>
                          )}

            </TextColorGreen>
          </DescriptionText>
          <hr />
          <DescriptionText>
            <DescriptionTextLeft>Địa chỉ nhận</DescriptionTextLeft>
            <DescriptionTextRight>{infoRequest?.to}</DescriptionTextRight>
          </DescriptionText>
          <DescriptionTextLeft>TX_ID</DescriptionTextLeft>
          <DescriptionTextRight>{infoRequest?.tx_id}</DescriptionTextRight>
          <p>
            <Button
          // type="primary"
              htmlType="submit"
              className="btn-block m-t-20"
              size="large"
              onClick={() => setModalTransactionDetail(false)}
            >
              Đóng
            </Button>
          </p>
        </Modal>
        <ModalContactInfo
          visible={modalContactInfo}
          onOK={() => setModalContactInfo(false)}
          onCancel={() => setModalContactInfo(false)}
        />
        {/* Modal Otp */}
        <Modal
          className="modalOtp"
          footer={null}
          visible={modalOtp}
          onCancel={() => clearOTP()}
          onOk={() => clearOTP()}
        >
          <Title level={3} className="text-title">
            Xác minh mã bảo mật
          </Title>
          <p style={{ marginBottom: 32 }}>
            Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
          </p>
          <OtpInput
            value={otp}
            onChange={handleChangeOtp}
            numInputs={6}
            separator=""
            inputStyle={{
              height: 60,
              width: 50,
              marginRight: 4,
              marginLeft: 4,
              background: '#F5F5F5',
              border: '2px solid #F5F5F5',
              borderRadius: 4,
              marginBottom: 32,
            }}
            containerStyle={{ justifyContent: 'center' }}
            focusStyle={{ border: '2px solid #cf9900' }}
          />
          <p style={{ marginBottom: 32, color: '#121212' }}>
            Nhập mã xác minh gồm 6 chữ số được gửi đến địa email của bạn.
          </p>
          <p style={{ marginBottom: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              disabled={otp.length < 6}
              onClick={handleVerifyOtp}
            >
              Xác nhận
            </Button>
          </p>
          <p style={{ marginBottom: 5, textAlign: 'center' }}>
            Bạn chưa nhận được email?
          </p>
          <div className="text-center">
            <a href="javascript:void(0)" onClick={handleResendOtp}>
              Gửi lại mã xác nhận
            </a>
          </div>
        </Modal>

        {/* Modal Info WithDraw  */}
        <Modal
          footer={null}
          visible={modalTransactionDetailWithDraw}
          onOk={() => setModalTransactionDetailWithDraw(false)}
          onCancel={() => setModalTransactionDetailWithDraw(false)}
        >
          <Title level={3} className="font-weight-500 mb-3">
            Thông tin rút USDT
          </Title>

          <DescriptionText>
            <DescriptionTextLeft>Định dạng ví</DescriptionTextLeft>
            <DescriptionTextRight>ERC20</DescriptionTextRight>
          </DescriptionText>
          <hr />
          <TitleDescription>Thông tin tài khoản nhận</TitleDescription>
          <Form
            name="deposit-amount"
            initialValues={{
            }}
            onFinish={requestWithdraw}
          >
            <Form.Item
              name="address"
              label="Địa chỉ ví"
              rules={[{ required: true, message: 'Please input deposit amount!' }]}
              className="mb-3"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="amount"
              label="Số tiền rút"
              rules={[{ required: true, message: 'Please input deposit amount!' }]}
              className="mb-3"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                addonAfter={suffixSelector} className="w-100"
              />
            </Form.Item>

            <DescriptionText>
              <DescriptionTextLeft>Số lượng rút tối thiểu</DescriptionTextLeft>
              <DescriptionTextRight>1 USDT</DescriptionTextRight>
            </DescriptionText>
            <DescriptionText>
              <DescriptionTextLeft>Số lượng rút tối đa</DescriptionTextLeft>
              <DescriptionTextRight>
                {(1245763501).toLocaleString()}
                {' '}
                USDT
              </DescriptionTextRight>
            </DescriptionText>
            <DescriptionTextEnd>
              <DescriptionTextLeft>Phí rút </DescriptionTextLeft>
              <DescriptionTextRight>0 USDT</DescriptionTextRight>
            </DescriptionTextEnd>
            <p>
              <Button
                type="primary"
                htmlType="submit"
                className="btn-block"
                size="large"
              >
                Rút USDT
              </Button>
            </p>
            <p>
              <Button
// type="primary"
                htmlType="submit"
                className="btn-block"
                size="large"
                onClick={() => setModalTransactionDetailWithDraw(false)}
              >
                Hủy lệnh
              </Button>
            </p>
          </Form>
        </Modal>
        {/* Wallet info */}
        <WalletInfoUsdt />

        <Card className="walletContent" bordered={false}>

          <p>
            <Button type="primary" className="mr-2" onClick={() => setModalTransactionDetailWithDraw(true)}>
              Rút USDT
            </Button>
            <Button icon={<PhoneOutlined />} onClick={() => setModalContactInfo(true)}>
              Liên hệ hỗ trợ
            </Button>

          </p>
        </Card>

        <div className="m-t-40">

          {/* table balance */}
          <Table
            scroll={{ x: true }}
            columns={columns}
            dataSource={data}
            pagination={{ position: 'bottomCenter' }}
          />
        </div>
      </>
    )
  }

export default WithDrawUSDT
