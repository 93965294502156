/* eslint-disable no-restricted-properties */
export const convertValue = (number = 0) => {
  if (number >= 1000000) {
    return number / 100000
  }
  if (number >= 1000) {
    return number / 100
  }
  if (number >= 100) {
    return number / 10
  }

  return number
}

export const transformTypeToTitle = (text) => {
  const str = text.split('-')
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
  }
  return str.join(' ')
}

export const transformTypeOptions = (list) => Object.values(list).map((value) => ({
  value,
  label: transformTypeToTitle(value),
}))

export const urlWithStoreID = (url, storeId) => `${storeId ? `/s/${storeId}` : ''}${url}`

export const urlWithStoreIDAndSearch = (url, search, storeId) => `${storeId ? `/s/${storeId}` : ''}${url}${search}`

export const urlWithStoreIDAndFilter = (url, storeId) => `${storeId ? `/s/${storeId}` : ''}${url}`

export const formatNumberWithDot = (number = 0) => number ? number.toString().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0

export const formatNumberWithSign = (number = 0) => {
  if (number >= 1000000000) {
    return `${(number / 1000000000).toFixed(2).replace(/\.0$/, '')}G`
  }
  if (number >= 1000000) {
    return `${(number / 1000000).toFixed(2).replace(/\.0$/, '')}M`
  }
  if (number >= 10000) {
    return `${(number / 1000).toFixed(2).replace(/\.0$/, '')}K`
  }
  return parseFloat(number.toFixed(2))
}

export const formatDecimalNumber = (number = 0) => parseFloat(number.toFixed(2))

export const formatIntegerNumber = (number = 0) => parseFloat(number.toFixed(0))

export const convertCategoryList = (list) => {
  const map = {}
  const roots = []
  for (let i = 0; i < list.length; i += 1) {
    map[list[i].id] = i
    // eslint-disable-next-line no-param-reassign
    list[i].children = []
  }
  for (let i = 0; i < list.length; i += 1) {
    const node = list[i]
    if (node.parent !== 0 && map[node.parent] && list[map[node.parent]] && list[map[node.parent]].children) {
      list[map[node.parent]].children.push(node)
    } else {
      roots.push(node)
    }
  }
  return roots
}
// migration from V1
const convertFilters = ({ sortId, desc, ...params }) => {
  const newFilters = { ...params, ...((sortId && desc) ? { sort: { [sortId]: Number(desc) } } : {}) }
  Object.keys(newFilters).forEach((key) => {
    if (key !== 'sort') {
      if (key !== 'search_parameter' && typeof newFilters[key] === 'string' && newFilters[key].includes('[') && newFilters[key].includes(']')) {
        // array
        // eslint-disable-next-line
        const arr = newFilters[key].replace(/[\[\]]+/g, '').split(',')
        newFilters[key] = arr
      } else if (key.includes('.')) {
        const arr = key.split('.')
        const itemKey = arr[0]
        const itemComponent = arr[1]
        // object contains .
        if (newFilters[itemKey]) {
          newFilters[itemKey][itemComponent] = newFilters[key]
        } else {
          newFilters[itemKey] = { [itemComponent]: newFilters[key] }
        }
        delete newFilters[key]
      }
      switch (key) {
        case 'product_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'category_ids':
          newFilters[key] = newFilters[key].map((id) => Number(id))
          break
        case 'clv.from':
          newFilters.clv.from = Number(newFilters.clv.from)
          break
        case 'clv.to':
          newFilters.clv.to = Number(newFilters.clv.to)
          break
        case 'total_number_of_orders.from':
          newFilters.total_number_of_orders.from = Number(newFilters.total_number_of_orders.from)
          break
        case 'total_number_of_orders.to':
          newFilters.total_number_of_orders.to = Number(newFilters.total_number_of_orders.to)
          break
        case 'total_value_of_orders.from':
          newFilters.total_value_of_orders.from = Number(newFilters.total_value_of_orders.from)
          break
        case 'total_value_of_orders.to':
          newFilters.total_value_of_orders.to = Number(newFilters.total_value_of_orders.to)
          break
        case 'point.from':
          newFilters.point.from = Number(newFilters.point.from)
          break
        case 'point.to':
          newFilters.point.to = Number(newFilters.point.to)
          break
        default:
      }
    }
  })
  return newFilters
}

export const convertFieldInFilters = (filters) => {
  const newFilters = { ...filters }
  // not push empty filters to url
  // eslint-disable-next-line
  for (const key in newFilters) {
    if (!newFilters[key]) delete newFilters[key]
  }

  Object.keys(newFilters).forEach((ft) => {
    if (newFilters[ft] && typeof newFilters[ft] === 'object' && newFilters[ft].length && typeof newFilters[ft].length === 'number') {
      // array
      if (newFilters[ft].length > 0) {
        newFilters[ft] = `[${newFilters[ft].join(',')}]`
      } else {
        delete newFilters[ft]
      }
    } else if (newFilters[ft] && typeof newFilters[ft] === 'object') {
      // object
      const arr = Object.keys(newFilters[ft]).filter((item) => newFilters[ft][item] || (typeof newFilters[ft][item] === 'number' && newFilters[ft][item] === 0))
      if (arr.length > 0) {
        arr.forEach((key) => {
          newFilters[`${ft}.${key}`] = newFilters[ft][key]
        })
        delete newFilters[ft]
      } else {
        delete newFilters[ft]
      }
    } else if (!filters[ft]) {
      // other type
      delete newFilters[ft]
    }
  })
  return newFilters
}

export const getSortParams = (sort) => {
  let sortParams = {}
  if (sort && Object.keys(sort).length > 0) {
    const key = Object.keys(sort)[0]
    sortParams = {
      sortId: key,
      desc: Number(sort[key]),
    }
  }
  return sortParams
}

export const isValidEmailFormat = (str) => {
  const re = /^[-a-z0-9~!$%^&*_=+}{\\'?]+(\.[-a-z0-9~!$%^&*_=+}{\\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.([a-z][a-z]+)|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/

  return re.test(String(str).toLowerCase())
}
export const randomstring = (length) => {
  let result = 'TDT_'
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const formatAmount = (amount, pow = 6) => {
  let amount1 = Number(amount).toFixed(pow)
  amount1 = Math.round(amount1 * Math.pow(10, pow)) / Math.pow(10, pow)
  amount1 = String(amount1)
  if (amount1.indexOf('.') < 0) {
    amount1 = `${amount1}.`
    return String(amount1)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      .replace('.', '')
  }
  return String(amount1).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}
