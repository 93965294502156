const dev = {
  endpoint: {
    api: "https://api.realgem.com/api/v1",
  },
};

const config = () => dev;

export default {
  ...config(),
};
