import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import {
  Layout,
  Menu,
  Divider,
  Avatar,
  Drawer,
} from 'antd'
import styled from 'styled-components'
import {
  MailOutlined,
  CloseCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  GoogleOutlined,
  WalletOutlined,
  HistoryOutlined,
  OrderedListOutlined,
  DashboardOutlined,
  BarChartOutlined,
  HeartOutlined,
  MenuOutlined,
} from '@ant-design/icons'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import userAvatar from '../static/images/flat-avatar.png'
import HeaderDiv from '../components/HeaderDiv'
import * as userAction from '../redux/action/user'
import * as walletAction from '../redux/action/wallet'
import { getUserData } from '../redux/selector/user'
import { getWalletData } from '../redux/selector/wallet'

const { SubMenu } = Menu
const { Header, Sider, Content } = Layout

const WrapperMenu = styled(Menu).attrs({
  className: 'layout-menu-content',
})`
  padding-top: 30px;
  padding-left: 50px;
  padding-right: 20px;
  font-weight: 500;
`
const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
`
const WrapperContent = styled(Content)`
  padding: 24px;
  min-height: 100vh;
`
const WrapperUserContent = styled.div`
  padding: 0 16px;
`
const RejectStatusItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #D94046;

  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const VerifyStatusItem = styled.div`
  display: flex;
  align-items: center;
  color: #FDB515;
  font-weight: 400;
  height:5px;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const SuccessStatusItem = styled.div`
  display: flex;
  align-items: center;
  color: #11C55D;
  font-weight: 400;
  height:5px;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const UserEmailItem = styled.div`
  display: flex;
  align-items: center;
  color: #838383;
  font-weight: 400;
  & span {
    margin-left: 8px;
  }
  margin-bottom: 8px;
`

const DrawerHanlder = styled.div`
  position: absolute;
  top:65px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 9;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 2px 0 8px #00000026;
  border-radius: 0 4px 4px 0;
`

const PrivateLayout = ({
  user,
  wallet,
  children,
  active,
  classname,
  getInfo,
  getWallet,
}) => {
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(false)
  const [openKeys, setOpenKeys] = useState('')
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    getWallet()
    return () => { }
  }, [])

  useEffect(() => {
    if (!user?.data?.username) {
      getInfo()
    }

    return () => { }
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   if (!user?.data?.username) {
  //     history.push('/sign-in')
  //   }
  // }, [user?.data])

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const onOpenChange = (openKeysData) => {
    console.log(openKeysData)
  }

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  if (!user) return null

  return (
    <>

      <DrawerHanlder className="drawer-handle" onClick={showDrawer}>
        <MenuOutlined />
      </DrawerHanlder>
      <Drawer width={250} placement="left" closable={false} onClose={onClose} visible={visible}>
        <WrapperMenu
          defaultSelectedKeys={[active]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <WrapperUserContent>
            <Avatar size={78} src={userAvatar} className="mb-3" />
            <h4 style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{user?.data?.name || ''}</h4>
            <UserEmailItem>
              <MailOutlined />
              <span>
                {' '}
                {user?.data?.email}
              </span>
            </UserEmailItem>

          </WrapperUserContent>
          <Divider />
          <Menu.Item key="1">
            <Link to="/dashboard">
              <MenuItemContent>
                <DashboardOutlined />
                <span> Dashboard </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/personal-info">
              <MenuItemContent>
                <UserOutlined />
                <span> Thông tin cá nhân </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="8" style={{ height: 60 }}>
            <Link to="/kyc">
              <MenuItemContent>
                <CheckCircleOutlined />
                <span>Xác minh tài khoản</span>
              </MenuItemContent>
              {
                (user?.data?.status === 'PC' || user?.data?.status === 'CL') ? (
                  <>
                    <VerifyStatusItem style={{ height: 5, paddingLeft: 25 }}>
                      <span> Đang xác minh </span>
                    </VerifyStatusItem>
                  </>
                ) : (<></>)
              }
              {(user?.data?.status === 'C') ? (
                <>
                  <SuccessStatusItem style={{ height: 5, paddingLeft: 25 }}>
                    <span> Đã xác minh </span>
                  </SuccessStatusItem>
                </>
              ) : (<></>)}
              {(user?.data?.status === 'RJ') ? (
                <>
                  <RejectStatusItem style={{ height: 5, paddingLeft: 25 }}>
                    <span> Xác minh lỗi </span>
                  </RejectStatusItem>
                </>
              ) : (<></>)}
              {(user?.data?.status === 'P' || user?.data?.status === 'W') ? (
                <>
                  <VerifyStatusItem style={{ height: 5, paddingLeft: 25 }}>
                    <span> Chưa xác minh </span>
                  </VerifyStatusItem>
                </>
              ) : (<></>)}
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/2fa">
              <MenuItemContent>
                <GoogleOutlined />
                <span>Google Authenticator</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="10">
            <Link to="/bounty-program">
              <MenuItemContent>
                <HeartOutlined />
                <span>Bounty Program</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Divider />
          <Menu.Item key="11">
            <Link to="/wallet">
              <MenuItemContent>
                <WalletOutlined />
                <span>Ví tài khoản</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="12">
            <Link to="/history">
              <MenuItemContent>
                <HistoryOutlined />
                <span>Lịch sử giao dịch</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="13">
            <Link to="/management">
              <MenuItemContent>
                <OrderedListOutlined />
                <span>Sản phẩm đang đầu tư</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="14">
            <Link to="/distribute">
              <MenuItemContent>
                <BarChartOutlined />
                <span>Quản lý lãi</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
        </WrapperMenu>
      </Drawer>

      <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: '#EAEEF9', overflow: 'auto' }} className="sidebar-left" onCollapse={toggle}>
        <WrapperMenu
          defaultSelectedKeys={[active]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        >
          <WrapperUserContent>
            <Avatar size={78} src={userAvatar} className="mb-3" />
            <h4 style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{user?.data?.name || ''}</h4>
            <UserEmailItem>
              <MailOutlined />
              <span>
                {' '}
                {user?.data?.email}
              </span>
            </UserEmailItem>

          </WrapperUserContent>
          <Divider />
          <Menu.Item key="1">
            <Link to="/dashboard">
              <MenuItemContent>
                <DashboardOutlined />
                <span> Dashboard </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="7">
            <Link to="/personal-info">
              <MenuItemContent>
                <UserOutlined />
                <span> Thông tin cá nhân </span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="8" style={{ height: 60 }}>
            <Link to="/kyc">
              <MenuItemContent>
                <CheckCircleOutlined />
                <span>Xác minh tài khoản</span>
              </MenuItemContent>
              {
                (user?.data?.status === 'PC' || user?.data?.status === 'CL') ? (
                  <>
                    <VerifyStatusItem style={{ height: 5, paddingLeft: 25 }}>
                      <span> Đang xác minh </span>
                    </VerifyStatusItem>
                  </>
                ) : (<></>)
              }
              {(user?.data?.status === 'C') ? (
                <>
                  <SuccessStatusItem style={{ height: 5, paddingLeft: 25 }}>
                    <span> Đã xác minh </span>
                  </SuccessStatusItem>
                </>
              ) : (<></>)}
              {(user?.data?.status === 'RJ') ? (
                <>
                  <RejectStatusItem style={{ height: 5, paddingLeft: 25 }}>
                    <span> Xác minh lỗi </span>
                  </RejectStatusItem>
                </>
              ) : (<></>)}
              {(user?.data?.status === 'P' || user?.data?.status === 'W') ? (
                <>
                  <VerifyStatusItem style={{ height: 5, paddingLeft: 25 }}>
                    <span> Chưa xác minh </span>
                  </VerifyStatusItem>
                </>
              ) : (<></>)}
            </Link>
          </Menu.Item>
          <Menu.Item key="9">
            <Link to="/2fa">
              <MenuItemContent>
                <GoogleOutlined />
                <span>Google Authenticator</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="10">
            <Link to="/bounty-program">
              <MenuItemContent>
                <HeartOutlined />
                <span>Bounty Program</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Divider />
          <Menu.Item key="11">
            <Link to="/wallet">
              <MenuItemContent>
                <WalletOutlined />
                <span>Ví tài khoản</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="12">
            <Link to="/history">
              <MenuItemContent>
                <HistoryOutlined />
                <span>Lịch sử giao dịch</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="13">
            <Link to="/management">
              <MenuItemContent>
                <OrderedListOutlined />
                <span>Sản phẩm đang đầu tư</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
          <Menu.Item key="14">
            <Link to="/distribute">
              <MenuItemContent>
                <BarChartOutlined />
                <span>Quản lý lãi</span>
              </MenuItemContent>
            </Link>
          </Menu.Item>
        </WrapperMenu>
      </Sider>
      <Layout className={`${classname}`}>
        <Header className="headerTop">
          <HeaderDiv />
        </Header>
        <WrapperContent className={collapsed ? 'collapsed mainContent ' : 'mainContent'}>
          {children}
        </WrapperContent>
        <ToastContainer />
      </Layout>
    </>
  )
}

PrivateLayout.propTypes = {
  user: PropTypes.shape(),
  wallet: PropTypes.shape(),
  children: PropTypes.shape(),
  active: PropTypes.bool,
  classname: PropTypes.shape(),
  getInfo: PropTypes.func,
  getWallet: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
  signout: () => dispatch(userAction.signout()),
  getWallet: () => dispatch(walletAction.get()),
})

export default connect(
  (state) => (
    {
      wallet: getWalletData(state),
      user: getUserData(state),
    }),

  mapDispatchToProps,
)(PrivateLayout)
