import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
} from '@ant-design/icons'
import './index.css'
import PropTypes from 'prop-types'
import { getWalletData } from '../../../redux/selector/wallet'
import { formatAmount } from '../../../utils/common'

const WalletInfoUsdr = ({ wallet, getWallet }) => {
  const history = useHistory()
  console.log('walletttttttttttttt', wallet)
  useEffect(() => { }, [])

  const [visible, setVisible] = useState(false)

  const onChange = () => {
  }
  return (
    <>

      {/* Wallet info */}
      <Card className="walletContent" bordered={false}>
        <p>Số dư tài khoản</p>
        <div className="balance">
          {
            (visible) ? (
              <>
                <span>
                  {formatAmount(wallet?.data?.token?.balance)}
                  {' '}
                  USDR
                </span>
                <EyeOutlined onClick={() => setVisible(false)} className="m-l-20" />
              </>

            ) : (
              <>
                <span>
                  ****** USDR
                </span>
                <EyeInvisibleOutlined onClick={() => setVisible(true)} className="m-l-20" />
              </>

            )
          }
        </div>
      </Card>
    </>
  )
}

WalletInfoUsdr.propTypes = {
  wallet: PropTypes.shape(),
  getWallet: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getWallet: () => dispatch(walletAction.get()),
})

export default connect(
  (state) => (
    {
      wallet: getWalletData(state),
    }),

  mapDispatchToProps,
)(WalletInfoUsdr)
