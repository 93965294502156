/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getWallet = async () => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_WALLET, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getBankAcoount = async () => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.BANK_ACCOUNT, {
      headers: {
        // lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const createBankAccount = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.BANK_ACCOUNT, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const deleteBankAccount = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.delete(ENDPOINT.BANK_ACCOUNT, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const uploadDoc = async (param, file) => {
  const { KEYS } = storage
  const formData = new FormData()
  console.log(file.originFileObj)
  formData.append('file', file.originFileObj)
  console.log(formData)
  try {
    const resp = await axios.post(`${ENDPOINT.UPLOAD_DOC}`, formData, {
      params: param,
      headers: {
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getListDeposit = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.LIST_DEPOSIT, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const requestDeposit = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.REQUEST_DEPOSIT, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const markDeposit = async (param, file) => {
  const { KEYS } = storage
  const formData = new FormData()
  formData.append('file', file)
  try {
    const resp = await axios.put(`${ENDPOINT.MARK_DEPOSIT}`, formData, {
      params: param,
      headers: {
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getListwithdraw = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.WITH_DRAW, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const withdraw = async (type, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.WITH_DRAW, data, {
      params: type,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const verifyWithdraw = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.VERIFY_WITH_DRAW, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const cancelWithdraw = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.CANCEL_WITH_DRAW, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const cancelDeposit = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.CANCEL_DEPOSIT, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const swapUSDR = async (data, param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.SWAP_USDR, data, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getSwapPairs = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.SWAP_PAIR, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getHistory = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.SWAP_HISTORY, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getStock = async (param, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.GET_STOCK, data, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const searchHistory = async (param, data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.SEARCH_HISTORY, data, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  searchHistory,
  getStock,
  markDeposit,
  cancelWithdraw,
  verifyWithdraw,
  withdraw,
  getListwithdraw,
  requestDeposit,
  getListDeposit,
  getWallet,
  getBankAcoount,
  createBankAccount,
  deleteBankAccount,
  swapUSDR,
  getHistory,
  cancelDeposit,
  getSwapPairs,
}
