import { connect } from 'react-redux'
import KYC from '../pages/kyc/index'
import * as userAction from '../redux/action/user'
import { getUserData } from '../redux/selector/user'

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
})

export default connect((state) => ({
  user: getUserData(state),
}),
mapDispatchToProps)(KYC)
