import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import {
  Route, Link, BrowserRouter as Router, useHistory,
} from 'react-router-dom'
import {
  Form, Input, Button, Checkbox, Row, Col, Layout, Modal, Typography,
} from 'antd'
import OtpInput from 'react-otp-input'
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons'
import banner from '../../../static/images/login.png'
import emailSuccess from '../../../static/images/email-success.png'
import Header from '../../layout/header'

const { Title } = Typography

const SignUp = ({ register, verifyOTP, resendOTP }) => {
  useEffect(() => {
  }, [])
  const history = useHistory()
  const [user, setUser] = useState({})
  const [modalOtp, setModalOtp] = useState(false)
  const [modalOtpSuccess, setModalOtpSuccess] = useState(false)
  const [otp, setOTP] = useState('')

  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }

  const handleVerifyOtp = () => {
    verifyOTP({ otp, ...user }, () => {
      setModalOtp(false)
      setModalOtpSuccess(true)
    })
  }

  const clearOTP = () => {
    setModalOtp(false)
    setOTP('')
  }

  const handleResendOtp = () => {
    resendOTP(user)
  }

  const onSubmit = (data) => {
    register(data, (userData) => {
      setModalOtp(true)
      setUser(userData)
    })
  }

  const onSubmitFailed = (error) => {
    console.log(error)
  }

  return (
    <Layout title="login" className="login">
      {/* <Header /> */}
      {/* Modal Otp */}
      <Modal
        className="modalOtp"
        footer={null}
        visible={modalOtp}
        onCancel={() => clearOTP()}
        onOk={() => clearOTP()}
      >
        <Title level={3} className="text-title">
          Xác minh địa chỉ email
        </Title>
        <p style={{ marginBottom: 32 }}>
          Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
        </p>
        <OtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={6}
          separator=""
          inputStyle={{
            height: 60,
            width: 50,
            marginRight: 4,
            marginLeft: 4,
            background: '#F5F5F5',
            border: '2px solid #F5F5F5',
            borderRadius: 4,
            marginBottom: 32,
          }}
          containerStyle={{ justifyContent: 'center' }}
          focusStyle={{ border: '2px solid #cf9900' }}
        />
        <p style={{ marginBottom: 32, color: '#121212' }}>
          Nhập mã xác minh gồm 6 chữ số được gửi đến địa chỉ email:
          {' '}
          <span style={{ fontWeight: 500 }}>{user.email}</span>
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            disabled={otp.length < 6}
            onClick={handleVerifyOtp}
          >
            Xác nhận
          </Button>
        </p>
        <p style={{ marginBottom: 5, textAlign: 'center' }}>
          Bạn chưa nhận được email?
        </p>
        <div className="text-center">
          <a href="javascript:void(0)" onClick={handleResendOtp}>
            Gửi lại mã xác nhận
          </a>
        </div>
      </Modal>

      {/* Modal Otp Success */}
      <Modal
        footer={null}
        className="modalOtp"
        visible={modalOtpSuccess}
        onCancel={() => setModalOtpSuccess(false)}
        onOk={() => setModalOtpSuccess(false)}
      >
        <Title level={3} className="text-title">
          Xác minh thông tin thành công!
        </Title>
        <p style={{ marginBottom: 32 }}>
          Bạn đã tạo thành công tài khoản tại Quesera, vui lòng đăng nhập để
          tiếp tục
        </p>
        <p style={{ marginBottom: 32, textAlign: 'center' }}>
          <img src={emailSuccess} />
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => history.push('/sign-in')}
          >
            Đăng nhập tài khoản
          </Button>
        </p>
        <p>
          <Button
          // type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            onClick={() => history.push('/sign-in')}
          >
            Trở về trang chủ
          </Button>
        </p>
      </Modal>

      <Row>
        <Col md={{ span: 12, order: 1 }} span={24} order={2} className="loginForm">
          <div style={{ marginTop: 64 }} className="login-container">
            <div className="text-left">
              {/* <img src={logo} /> */}
              <h1 className="m-b-10 m-t-15">Đăng Ký</h1>
            </div>
            <Form
              layout="vertical"
              className="register-form"
              initialValues={{
                name: '',
                email: '',
                username: '',
                password: '',
                referrer: '',
              }}
              onFinish={onSubmit}
              onFinishFailed={onSubmitFailed}
            >
              <Form.Item
                name="name"
                label="Họ và tên" className="item-form"
                rules={[{ required: true, message: 'Họ và tên không được để trống.' }]}
              >
                <Input placeholder="Vui lòng nhập" />
              </Form.Item>
              <Form.Item
                name="email" label="Email" className="item-form"
                rules={[{ required: true, message: 'Địa chỉ email không được để trống.', type: 'email' }]}
              >
                <Input placeholder="Vui lòng nhập" />
              </Form.Item>
              <Form.Item
                name="username" label="Tên tài khoản" className="item-form"
                rules={[{ required: true, message: 'Tên tài khoản không được để trống.' }]}
              >
                <Input placeholder="Vui lòng nhập" />
              </Form.Item>
              <Form.Item
                name="password" label="Mật khẩu" className="item-form"
                rules={[{ required: true, min: 8, message: 'Mật khẩu không được để trống.' }]}
              >
                {/* <Input type="password" placeholder="Vui lòng nhập" /> */}
                <Input.Password
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  type="password"
                  placeholder="Vui lòng nhập"
                  iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
              </Form.Item>
              {/* <Form.Item label="Xác nhận mật khẩu" className="item-form">
             <Input type="password" placeholder="Vui lòng nhập" />
             </Form.Item> */}
              <Form.Item
                name="referrer" label="Mã giới thiệu" className="item-form"
              >
                <Input placeholder="Vui lòng nhập" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-block m-t-15"
                  size="large"
                >
                  Đăng ký
                </Button>
                <div className="p-t-5">
                  <span>
                    {/* Already have an account? */}
                    Bạn đã có tài khoản? Vui lòng
                    <a className="m-l-5" href="/sign-in">
                      {/* Log in */}
                      Đăng nhập
                    </a>
                  </span>
                </div>
              </Form.Item>
            </Form>
          </div>
        </Col>
        <Col md={{ span: 12, order: 2 }} span={24} order={1}>
          <div className="banner-container-signin" />
        </Col>
      </Row>
    </Layout>
  )
}

SignUp.propTypes = {
  register: PropTypes.func,
  verifyOTP: PropTypes.func,
  resendOTP: PropTypes.func,
}
export default SignUp
