/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getListProduct = async (param) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_LIST_PRODUCT, {
      params: param,
      headers: {
        lang: 'en',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const searchProject = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.SEARCH_PRODUCT, data, {
      headers: {
        lang: 'en',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getDetailProduct = async (data) => {
  console.log(data)
  try {
    const resp = await axios.get(ENDPOINT.GET_DETAIL_PRODUCT, {
      params: data,
      headers: {
        lang: 'en',
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const investment = async (data, param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.INVEST_PRODUCT, data, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getHistory = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_HISTORY_IDO, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getBuyerOffer = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.GET_BUYER_OFFER, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const acceptVote = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.VOTE_ACCEPT_OFFER, {}, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const rejectVote = async (param) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.VOTE_REJECT_OFFER, {}, {
      params: param,
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const claimReward = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.post(ENDPOINT.CLAIM_REWARD, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}
export default {
  claimReward,
  rejectVote,
  acceptVote,
  getBuyerOffer,
  getListProduct,
  getDetailProduct,
  investment,
  getHistory,
  searchProject,
}
