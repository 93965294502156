import AppConfig from '../config'
// import qs from 'query-string'

export const LOGIN = `${AppConfig.endpoint.api}/user/login`
export const GET_USER = `${AppConfig.endpoint.api}/kyc/get`
export const GET_PROFILE = `${AppConfig.endpoint.api}/user/profile`
export const UPDATE_INFO_PERSONAL = `${AppConfig.endpoint.api}/user/profile/update-info`
export const CHANGE_PASSWORD = `${AppConfig.endpoint.api}/user/profile/change-password`
export const NEW_TOKEN = `${AppConfig.endpoint.api}/user/new-access-token`

export const REGISTER = `${AppConfig.endpoint.api}/user/register`
export const VERIFY_USER = `${AppConfig.endpoint.api}/user/verify-user`
export const RESEND_VERIFY_EMAIL = `${AppConfig.endpoint.api}/user/resend-verify-email`
export const FORGOT_PASSWORD = `${AppConfig.endpoint.api}/user/forgot-password`
export const CONFIRM_FORGOT_PASSWORD = `${AppConfig.endpoint.api}/user/confirm-forgot-password`

// kyc
export const UPDATE_INFO = `${AppConfig.endpoint.api}/kyc/update-info`
export const GET_INFO = `${AppConfig.endpoint.api}/kyc/get`
export const UPLOAD_DOC = `${AppConfig.endpoint.api}/kyc/upload-doc`
export const GET_ADDRESS = `${AppConfig.endpoint.api}/kyc/address`

// 2fa
export const GEN_TFA = `${AppConfig.endpoint.api}/tfa/generate`
export const ACTIVE_TFA = `${AppConfig.endpoint.api}/tfa/active`
export const DEACTIVE_TFA = `${AppConfig.endpoint.api}/tfa/deactive`

// product
export const GET_LIST_PRODUCT = `${AppConfig.endpoint.api}/project`
export const SEARCH_PRODUCT = `${AppConfig.endpoint.api}/project/search`
export const GET_DETAIL_PRODUCT = `${AppConfig.endpoint.api}/project/detail`
export const GET_BUYER_OFFER = `${AppConfig.endpoint.api}/project/get-buyer-offer`
export const VOTE_ACCEPT_OFFER = `${AppConfig.endpoint.api}/project/vote-accept-offer`
export const VOTE_REJECT_OFFER = `${AppConfig.endpoint.api}/project/vote-reject-offer`
export const CANCEL_VOTE_OFFER = `${AppConfig.endpoint.api}/project/cancel-vote-offer`
export const CLAIM_REWARD = `${AppConfig.endpoint.api}/project/claim-reward`
export const GET_CLAIM_HISTORY = `${AppConfig.endpoint.api}/project/get-claim-history`
export const CHECK_VOTE = `${AppConfig.endpoint.api}/project/check-vote`

// wallet
export const GET_WALLET = `${AppConfig.endpoint.api}/wallet/get`
export const BANK_ACCOUNT = `${AppConfig.endpoint.api}/wallet/bank-account`
export const REQUEST_DEPOSIT = `${AppConfig.endpoint.api}/wallet/request-deposit`
export const LIST_DEPOSIT = `${AppConfig.endpoint.api}/wallet/deposit`
export const MARK_DEPOSIT = `${AppConfig.endpoint.api}/wallet/mark-deposit`
export const WITH_DRAW = `${AppConfig.endpoint.api}/wallet/withdraw`
export const VERIFY_WITH_DRAW = `${AppConfig.endpoint.api}/wallet/verify-withdraw`
export const CANCEL_WITH_DRAW = `${AppConfig.endpoint.api}/wallet/cancel-withdraw`
export const CANCEL_DEPOSIT = `${AppConfig.endpoint.api}/wallet/cancel-deposit`
export const GET_STOCK = `${AppConfig.endpoint.api}/wallet/get-stock`
export const SEARCH_HISTORY = `${AppConfig.endpoint.api}/wallet/search-history`

// referal
export const GET_REFERAL = `${AppConfig.endpoint.api}/ref/get`
export const GET_REFERAL_INFO = `${AppConfig.endpoint.api}/ref/list-referrals`

// market
export const INVEST_PRODUCT = `${AppConfig.endpoint.api}/market/ido/fulfill`
export const GET_HISTORY_IDO = `${AppConfig.endpoint.api}/market/ido/history`
export const SWAP_PAIR = `${AppConfig.endpoint.api}/market/swap/orders`
export const SWAP_USDR = `${AppConfig.endpoint.api}/market/swap/fulfill`
export const SWAP_HISTORY = `${AppConfig.endpoint.api}/market/swap/history`
export const SEARCH_ORDER_BOOK = `${AppConfig.endpoint.api}/market/p2p/order-book`
export const HISTORY_ORDER_BOOK = `${AppConfig.endpoint.api}/market/p2p/history`
export const PLACE_ORDER_BOOK = `${AppConfig.endpoint.api}/market/p2p/place-order-book`
export const MARK_RECEIVE_FIAT = `${AppConfig.endpoint.api}/market/p2p/mark-receive-fiat`
export const P2P_FULFILL = `${AppConfig.endpoint.api}/market/p2p/fulfill`

// Bounty Program
export const BOUNTY_PROGRAM_GET_LIST = `${AppConfig.endpoint.api}/bounty/get-program`
export const BOUNTY_PROGRAM_CREATE = `${AppConfig.endpoint.api}/bounty/new-program-completion`
export const BOUNTY_PROGRAM_REQUEST_REWARD = `${AppConfig.endpoint.api}/bounty/requesst-program-completion-reward`
// ?completionId=62ad95034bb7f81ff179c990
