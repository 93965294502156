/* eslint-disable no-useless-catch */
import axios from 'axios'
import { toast } from 'react-toastify'
import * as storage from '../utils/storage'
import * as ENDPOINT from './url'

const getReferal = async () => {
  const { KEYS } = storage
  try {
    const resp = await axios.get(ENDPOINT.GET_REFERAL, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

const getReferalInfo = async (data) => {
  const { KEYS } = storage
  try {
    const resp = await axios.put(ENDPOINT.GET_REFERAL_INFO, data, {
      headers: {
        lang: 'vn',
        Authorization: `Bearer ${storage.get(KEYS.TOKEN)}`,
      },
    })
    return resp.data
  } catch (e) {
    if (e.response) {
      toast.error(e.response.data.msg)
    } else {
      toast.error('sorry, we are having some issues at the moment. Please try again later.')
    }
    throw e
  }
}

export default {
  getReferal, getReferalInfo,
}
