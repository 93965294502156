/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Upload,
} from 'antd'
import {
  DiffOutlined,
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  IdcardOutlined,
  ReadOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  HourglassOutlined,
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import moment from 'moment'
import { toast } from 'react-toastify'
import { Label } from 'recharts'
import PropTypes from 'prop-types'
import kyc from '../../provider/kyc'
import hcIcon from '../../static/images/hochieu-icon.png'
import idIcon from '../../static/images/cmnd-icon.png'
import idFront from '../../static/images/id_front.png'
import idBack from '../../static/images/id_back.png'
import passportBack from '../../static/images/passport_back.png'
import passportFront from '../../static/images/passport_front.png'
import target from '../../static/images/target.png'
import bill from '../../static/images/bill.png'
import './index.css'
import verification from '../../static/images/Verification-intro.png'
import process from '../../static/images/Verification-process.png'
import success from '../../static/images/Verification-success.png'
import fail from '../../static/images/Verification-fail.png'
import StepLayout from './steps'

const { Step } = Steps
const { Option } = Select

const steps = [
  {
    title: 'Tên, Quốc Tịch',
    description: 'Đang thực hiện',
    content: 'Xác minh Tên, Quốc tịch',
    subcontent: 'Nhập địa chỉ email được dùng để đăng ký tài khoản REX Block',
  },
  {
    title: 'CMND / Passport',
    description: 'Đang chờ',
    content: 'Xác minh CMND/Passport',
    subcontent: 'Chọn một loại giấy tờ cần xác minh',
  },
  {
    title: 'Hình ảnh',
    description: 'Đang chờ',
    content: 'Xác minh hình ảnh',
    subcontent: 'Cung cấp ảnh chụp khuôn mặt cùng với giấy tờ xác minh',
  },
  {
    title: 'Địa chỉ hóa đơn',
    description: 'Đang chờ',
    content: 'Xác minh địa chỉ, hóa đơn',
    subcontent: 'Thông tin liên hệ',
  },
  {
    title: 'Hoàn tất',
    description: 'Đang chờ',
    content: '',
    subcontent: '',
  },
]

const KYC = ({ user, getInfo }) => {
  const history = useHistory()
  useEffect(() => { }, [])
  const [showConfirm, setShowConfirm] = useState(true)
  const [showProcess, setShowProcess] = useState(false)
  const [modalConfirm, setModalConfirm] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [showIDcard, setShowIDcard] = useState(false)
  const [showPassport, setShowPassport] = useState(false)
  const [fileList, setFileList] = useState([])
  const [imageUrlFront, setImageUrlFront] = useState('')
  const [fileIdFront, setFileIdFront] = useState(null)
  const [imageUrlBack, setImageUrlBack] = useState('')
  const [fileIdBack, setFileIdBack] = useState(null)
  const [imageUrlPassportFront, setImageUrlPassportFront] = useState('')
  const [imageUrlPassportBack, setImageUrlPassportBack] = useState('')
  const [imageUrlTarget, setImageUrlTarget] = useState('')
  const [imageUrlBill, setImageUrlBill] = useState('')
  const [filePassportFront, setFilePassportFront] = useState(null)
  const [filePassportBack, setFilePassportBack] = useState(null)
  const [fileTarget, setFileTarget] = useState(null)
  const [fileBill, setFileBill] = useState(null)
  const [country, setCountry] = useState('VN')
  const [defaultProvince, setDefaultProvince] = useState('')
  const [defaultDistrist, setDefaultDistrist] = useState('')

  const [birthday, setBirthday] = useState('')
  const [typeDoc, setTypeDoc] = useState('')
  const [zipCode, setZipcode] = useState('')
  const [address, setAddress] = useState('')
  const [additional, setAdditional] = useState('')

  const [dataInffo, setDataInffo] = useState({})
  const [statusStep, setStatusStep] = useState({
    step1: '',
    step2: '',
    step3: '',
    step4: '',
    step5: '',
  })

  const [descriptonStep, setDescriptonStep] = useState({
    step1: 'Đang thực hiện',
    step2: 'Đang chờ',
    step3: 'Đang chờ',
    step4: 'Đang chờ',
    step5: 'Đang chờ',
  })
  const [form] = Form.useForm()
  const [form1] = Form.useForm()

  const [defaultValuesStep1, setDefaultValuesStep1] = useState({
    country: 'VN',
    name: '',
    birthday: null,
  })

  const [defaultValuesSelectStep2, setDefaultValuesSelectStep2] = useState(null)

  const [defaultValuesStep4, setDefaultValuesStep4] = useState({
    province: '',
    district: '',
    sub_district: '',
    address: '',
    zip: '',
  })

  const [district, setDistrict] = useState([])
  const [province, setProvince] = useState([])
  const [ward, setWard] = useState([])
  const [CurrentProvince, setCurrentProvince] = useState('')

  const getProvince = async () => {
    const resp = await kyc.getAddress('')
    setProvince(resp.data)
  }

  const onProvinceSelect = async (value) => {
    setCurrentProvince(value)
    const resp = await kyc.getAddress({ province: value })
    setDistrict(resp.data)
    setDefaultValuesStep4({
      ...defaultValuesStep4, ...{ province: value }, ...{ district: '' }, ...{ sub_district: '' },
    })
  }

  const onDistrictSelect = async (provinc, value) => {
    const resp = await kyc.getAddress({ province: provinc, district: value })
    setDefaultValuesStep4({
      ...defaultValuesStep4, ...{ province: provinc }, ...{ district: value }, ...{ sub_district: '' },
    })
    setWard(resp.data)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      toast.warning('You can only upload JPG/PNG file!')
      return ''
    }
    // const isLt2M = file.size / 1024 / 1024 < 2
    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!')
    // }
    return isJpgOrPng
  }

  const handleChangeDoc = (e) => {
    setDefaultValuesSelectStep2(e.target.value)
    if (e.target.value === 1) {
      setShowIDcard(true)
      setShowPassport(false)
    }
    if (e.target.value === 2) {
      setShowPassport(true)
      setShowIDcard(false)
    }
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  const onChangeStep = (current) => {
    setCurrentStep(current)
    if (current === 0) {
      form1.setFieldsValue({ name: 'nguyễn văn A' })
    }
  }
  const handleChangeIdFront = (info) => {
    if (info.file.status === 'done') {
      setFileIdFront(info.file)
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrlFront(imageUrl))
    }
  }

  const handleChangeIdBack = (info) => {
    if (info.file.status === 'done') {
      setFileIdBack(info.file)
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrlBack(imageUrl))
    }
  }

  const handleChangePassportFront = (info) => {
    if (info.file.status === 'done') {
      setFilePassportFront(info.file)
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrlPassportFront(imageUrl))
    }
  }

  const handleChangePassportBack = (info) => {
    if (info.file.status === 'done') {
      setFilePassportBack(info.file)
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrlPassportBack(imageUrl))
    }
  }

  const handleChangeTarget = (info) => {
    if (info.file.status === 'done') {
      setFileTarget(info.file)
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrlTarget(imageUrl))
    }
  }

  const handleChangeBill = (info) => {
    if (info.file.status === 'done') {
      setFileBill(info.file)
      getBase64(info.file.originFileObj, (imageUrl) => setImageUrlBill(imageUrl))
    }
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok')
    }, 0)
  }

  const uploadButton = (
    <div>
      <CloudUploadOutlined style={{ fontSize: 40 }} />
      <div className="m-t-20">Click hoặc kéo ảnh vào khung hình</div>
    </div>
  )
  function getStatus(value) {
    switch (value) {
      case 'PC':
        return 'Chờ phê duyệt'
      case 'P':
        return 'Chờ phê duyệt'
      case 'W':
        return 'Đang chờ'
      case 'C':
        return 'Đã phê duyệt'
      case 'RJ':
        return 'Cần thực hiện lại'
      default:
        return 'Đang thực hiện'
    }
  }
  function getStatusStep(value) {
    switch (value) {
      // case 'PC':
      //   return 'process'
      // case 'P':
      //   return 'wait'
      // case 'W':
      //   return 'wait'
      case 'C':
        return 'finish'
      case 'RJ':
        return 'error'
      default:
        return ''
    }
  }
  const getKycInfo = async () => {
    try {
      const resp = user
      setDataInffo(resp.data)
      if (resp.data?.add_info?.province) {
        await onProvinceSelect(resp.data?.add_info?.province)
      }

      if (resp.data?.add_info?.district) {
        await onDistrictSelect(resp.data?.add_info?.province, resp.data?.add_info?.district)
      }

      setDefaultValuesStep1({
        ...defaultValuesStep1,
        ...{ name: resp.data.name },
        ...{ country: resp.data.country },
        ...{ birthday: resp.data.birthday ? moment(resp.data.birthday) : '' },
      })

      setDefaultValuesStep4({
        ...setDefaultValuesStep4,
        ...{ province: resp.data?.add_info?.province },
        ...{ district: resp.data?.add_info?.district },
        ...{ sub_district: resp.data?.add_info?.sub_district },
        ...{ address: resp.data?.add_info?.address },
        ...{ zip: resp.data?.add_info?.zip },
      })

      setStatusStep({
        ...statusStep,
        ...{ step1: getStatusStep(resp.data?.step_1?.status) },
        ...{ step2: getStatusStep(resp.data?.step_2?.status) },
        ...{ step3: getStatusStep(resp.data?.step_3?.status) },
        ...{ step4: getStatusStep(resp.data?.step_4?.status) },
        ...{ step5: getStatusStep(resp.data?.status) },

      })
      setDescriptonStep({
        ...descriptonStep,
        ...{ step1: getStatus(resp.data?.step_1?.status) },
        ...{ step2: getStatus(resp.data?.step_2?.status) },
        ...{ step3: getStatus(resp.data?.step_3?.status) },
        ...{ step4: getStatus(resp.data?.step_4?.status) },
        ...{ step5: getStatus(resp.data?.status) },

      })
      if (resp.data?.step_2) {
        if (resp.data?.step_2?.type === 'ID') {
          setDefaultValuesSelectStep2(1)
          setShowIDcard(true)
          setShowPassport(false)
          setImageUrlFront(`data:image/jpeg;base64,${resp.data.step_2.front_doc_img}`)
          setImageUrlBack(`data:image/jpeg;base64,${resp.data.step_2.behind_doc_img}`)
        }
        if (resp.data?.step_2?.type === 'PP') {
          setDefaultValuesSelectStep2(2)
          setShowPassport(true)
          setShowIDcard(false)
          setImageUrlPassportFront(`data:image/jpeg;base64,${resp.data.step_2.front_doc_img}`)
          setImageUrlPassportBack(`data:image/jpeg;base64,${resp.data.step_2.behind_doc_img}`)
        }
      }
      if (resp.data?.step_3) {
        if (resp.data?.step_3?.status !== 'W') {
          setAdditional(resp.data?.step_3?.additional)
          setImageUrlTarget(`data:image/jpeg;base64,${resp.data?.step_3?.img_location}`)
        }
      }

      if (resp.data?.step_4) {
        if (resp.data?.step_4?.status !== 'W') {
          setImageUrlBill(`data:image/jpeg;base64,${resp.data?.step_4?.img_location}`)
        }
      }
    } catch (error) {
      toast.error(error)
    }
  }

  useEffect(() => {
    form1.setFieldsValue(defaultValuesStep1)
  }, [form1, defaultValuesStep1])

  useEffect(() => {
    form.setFieldsValue(defaultValuesStep4)
  }, [form, defaultValuesStep4])

  useEffect(() => {
    getKycInfo()
    getProvince()
    return () => {
    }
  }, [])

  useEffect(() => {
    getKycInfo()
    return () => {
    }
  }, [user])

  const onFinishStep1 = async (value) => {
    setCountry(value.country)
    if (value.country === 'NN') {
      setDefaultValuesSelectStep2(2)
      setShowIDcard(false)
      setShowPassport(true)
    }
    if (dataInffo?.step_1?.status === 'C') {
      setCurrentStep(1)
    } else {
      const birthday = moment(value.birthday).format('YYYY-MM-DD')
      const payload = {
        name: value.name,
        country: value.country,
        birthday,
      }
      await kyc.updateInfo(payload)
      getInfo()
      // console.log(resp)
      toast.success('update sucess')
      setCurrentStep(1)
    }
  }

  // logic step2
  const onFinishStep2 = async () => {
    if (dataInffo?.step_2?.status === 'C') {
      setCurrentStep(2)
    } else {
      try {
        let type = ''
        if (showIDcard) {
          type = 'ID'
          if (dataInffo?.step_2?.status === 'W') {
            if (fileIdFront !== null && fileIdBack !== null) {
              await kyc.uploadDoc({ type, side: 'FR' }, fileIdFront)
              await kyc.uploadDoc({ type, side: 'BA' }, fileIdBack)
              toast.success('Upload thành công')
              setCurrentStep(2)
            } else {
              toast.warning('Vui lòng upload đầy đủ 2 ảnh')
              return
            }
          } else if (dataInffo?.step_2?.status !== 'W' && (imageUrlBack) && (imageUrlFront)) {
            if (fileIdFront !== null) {
              await kyc.uploadDoc({ type, side: 'FR' }, fileIdFront)
              toast.success('Upload ảnh mặt trước thành công')
            }
            if (fileIdBack !== null) {
              await kyc.uploadDoc({ type, side: 'BA' }, fileIdBack)
              toast.success('Upload ảnh mặt sau thành công')
            }
            setCurrentStep(2)
          } else {
            toast.warning('Vui lòng upload đầy đủ 2 ảnh')
          }
        }
        if (showPassport) {
          type = 'PP'
          if (dataInffo?.step_2?.status === 'W') {
            if (filePassportFront !== null && filePassportBack !== null) {
              await kyc.uploadDoc({ type, side: 'FR' }, filePassportFront)
              await kyc.uploadDoc({ type, side: 'BA' }, filePassportBack)
              toast.success('Upload thành công')
              setCurrentStep(2)
            } else {
              toast.warning('Vui lòng upload đầy đủ 2 ảnh')
              return
            }
          } else if (dataInffo?.step_2?.status !== 'W' && (imageUrlPassportBack) && (imageUrlPassportFront)) {
            if (filePassportFront !== null) {
              await kyc.uploadDoc({ type, side: 'FR' }, filePassportFront)
              toast.success('Upload ảnh mặt trước thành công')
            }
            if (filePassportBack !== null) {
              await kyc.uploadDoc({ type, side: 'BA' }, filePassportBack)
              toast.success('Upload ảnh mặt sau thành công')
            }
            setCurrentStep(2)
          } else {
            toast.warning('Vui lòng upload đầy đủ 2 ảnh')
          }
        }
        setDescriptonStep({
          ...descriptonStep,
          ...{ step2: getStatus('P') },
        })
      } catch (error) {
        toast.error(error)
      }
    }
  }

  // logic step3
  const onFinishStep3 = async () => {
    if (dataInffo?.step_2?.status === 'C') {
      setCurrentStep(2)
    } else {
      try {
        const type = 'PE'
        if (imageUrlTarget) {
          if (fileTarget !== null) {
            await kyc.uploadDoc({ type, side: 'DE', additional }, fileTarget)

            toast.success('Upload thành công')
            setDescriptonStep({
              ...descriptonStep,
              ...{ step3: getStatus('P') },
            })
          }
          setCurrentStep(3)
        } else {
          toast.warning('Vui lòng upload ảnh')
          return
        }
      } catch (error) {
        toast.error(error)
      }
    // }
    }
  }
  const onFinishStep4 = async (value) => {
    if (dataInffo?.step_4?.status === 'C') {
      setCurrentStep(4)
    } else {
      const dataPayload = {
        add_info: {
          province: value.province,
          district: value.district,
          sub_district: value.sub_district,
          address: value.address,
          zip: value.zip,
        },
      }
      await kyc.updateInfo(dataPayload)
      getInfo()
      try {
        const type = 'IV'
        if (imageUrlBill) {
          if (fileBill !== null) {
            await kyc.uploadDoc({ type, side: 'DE' }, fileBill)
            toast.success('Upload thành công')
            setDescriptonStep({
              ...descriptonStep,
              ...{ step4: getStatus('P') },
            })
          }
          setCurrentStep(4)
        } else {
          toast.warning('Vui lòng upload ảnh')
          return
        }
      } catch (error) {
        toast.error(error)
      }
    }
  }

  const confirmKYC = () => {
    setShowConfirm(false)
    setModalConfirm(false)
    setShowProcess(true)
  }

  const stepContent = () => (
    <div className="mt-4">
      <div className="">
        <h4>{steps[currentStep].content}</h4>
      </div>
      {
        (() => {
          switch (currentStep) {
            case 0:
              return (
                <>
                  <Form
                    form={form1}
                    layout="vertical" className="form-infor" onFinish={onFinishStep1}
                    initialValues={defaultValuesStep1}
                  >

                    <Form.Item name="country" className="item-form m-t-10" label="Quốc tịch" rules={[{ required: true }]}>
                      <Select>
                        <Option value="VN">Việt Nam</Option>
                        <Option value="NN">Nước Ngoài</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item name="name" className="item-form m-t-10" label="Họ và tên" rules={[{ required: true }]}>
                      <Input
                        placeholder="Họ Tên"
                      />
                    </Form.Item>

                    <Form.Item name="birthday" className="item-form m-t-10" label="Ngày tháng năm sinh" rules={[{ required: true }]}>
                      {/* <Space direction="vertical" style={{ width: '100%' }}> */}
                      <DatePicker
                        disabledDate={(current) => {
                          const customDate = moment().format('YYYY-MM-DD')
                          return current && current > moment(customDate, 'YYYY-MM-DD')
                        }} style={{ width: '100%' }}
                      />
                      {/* </Space> */}
                    </Form.Item>

                    <Form.Item className="block">
                      <Button style={{ margin: '0 8px', display: 'none' }} onClick={prev}>
                        Quay lại
                      </Button>
                      <Button
                        type="primary"
                        className=" m-t-15"
                        htmlType="submit"
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                  </Form>
                </>

              )

            case 1:
              return (
                <>

                  <div className="w-100">
                    {country === 'VN' ? (
                      <>
                        <h6 className="m-b-20">Chọn một loại giấy tờ cần xác minh</h6>
                        <Form
                          layout="vertical"
                        >
                          <Form.Item>
                            <Radio.Group defaultValue={defaultValuesSelectStep2} onChange={handleChangeDoc}>
                              <Space direction="vertical">
                                <Radio.Button className="btn-block" value={1} style={{ with: '100%' }}>
                                  <IdcardOutlined />
                                  <span className="m-l-10">CMND / CCCD </span>
                                </Radio.Button>
                                <Radio.Button className="btn-block" value={2} style={{ with: '100%' }}>
                                  <ReadOutlined />
                                  <span className="m-l-10">Hộ Chiếu</span>
                                </Radio.Button>
                              </Space>
                            </Radio.Group>

                          </Form.Item>
                        </Form>
                      </>

                    ) : (
                      <>

                      </>
                    )}

                    {showIDcard ? (
                      <>
                        Cung cấp ảnh chụp CMND / CCCD
                        <Row
                          className="m-t-10"
                        >
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                            <div className="border-card-upload">
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChangeIdFront}
                              >
                                {imageUrlFront
                                  ? (
                                    <>
                                      <div>
                                        <img src={imageUrlFront || ''} alt="avatar" className="img-upload" />
                                      </div>
                                    </>
                                  )
                                  : uploadButton}
                              </Upload>
                              {imageUrlFront
                                ? (
                                  <>
                                    <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                                      <div style={{ color: '#003262' }}>
                                        <HourglassOutlined />
                                        <span className="m-l-10">{descriptonStep.step2}</span>
                                      </div>
                                      <a
                                        href="#"
                                        className="m-l-30" style={{ color: 'red' }}
                                        onClick={() => setImageUrlFront(null)}
                                      >
                                        Xóa
                                      </a>

                                    </div>
                                  </>
                                )
                                : <></>}
                            </div>

                            <div>
                              <span>Mặt trước CMND / CCCD</span>
                            </div>
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Định dạng tải lên: JPEG, PNG, PDF
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp rõ ràng, không bị mờ
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp không bị che khuất, mất góc
                            </span>
                            <br />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-l-10 text-center">
                            <img style={{ height: 300 }} src={idFront} />
                            <div className="m-t-20">
                              <label>Hướng dẫn thực hiện</label>

                            </div>
                          </Col>
                        </Row>

                        <Row className="m-t-40">
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                            <div className="border-card-upload">
                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChangeIdBack}
                              >
                                {imageUrlBack ? <img src={imageUrlBack} alt="avatar" className="img-upload" /> : uploadButton}
                              </Upload>
                              {imageUrlBack
                                ? (
                                  <>
                                    <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                                      <div style={{ color: '#003262' }}>
                                        <HourglassOutlined />
                                        <span className="m-l-10">{descriptonStep.step2}</span>
                                      </div>
                                      <a
                                        href="#"
                                        className="m-l-30" style={{ color: 'red' }}
                                        onClick={() => setImageUrlBack(null)}
                                      >
                                        Xóa
                                      </a>

                                    </div>
                                  </>
                                )
                                : <></>}
                            </div>

                            <div>
                              <span>Mặt sau CMND / CCCD</span>
                            </div>
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Định dạng tải lên: JPEG, PNG, PDF
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp rõ ràng, không bị mờ
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp không bị che khuất, mất góc
                            </span>
                            <br />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-l-10 text-center">
                            <img style={{ height: 300 }} src={idBack} />
                            <div className="m-t-20">
                              <label>Hướng dẫn thực hiện</label>

                            </div>
                          </Col>
                        </Row>

                      </>
                    ) : (
                      <></>

                    )}

                    {showPassport ? (
                      <>
                        Cung cấp ảnh thông tin hộ chiếu
                        <Row className="m-t-10">
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                            <div className="border-card-upload">

                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChangePassportFront}
                              >
                                {imageUrlPassportFront ? <img src={imageUrlPassportFront} alt="avatar" className="img-upload" /> : uploadButton}
                              </Upload>
                              {imageUrlPassportFront
                                ? (
                                  <>
                                    <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                                      <div style={{ color: '#003262' }}>
                                        <HourglassOutlined />
                                        <span className="m-l-10">{descriptonStep.step2}</span>
                                      </div>
                                      <a
                                        href="#"
                                        className="m-l-30" style={{ color: 'red' }}
                                        onClick={() => setImageUrlPassportFront(null)}
                                      >
                                        Xóa
                                      </a>

                                    </div>
                                  </>
                                )
                                : <></>}
                            </div>

                            <div>
                              <span>Bìa hộ chiếu</span>
                            </div>
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Định dạng tải lên: JPEG, PNG, PDF
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp rõ ràng, không bị mờ
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp không bị che khuất, mất góc
                            </span>
                            <br />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-l-10 text-center">
                            <img style={{ height: 300 }} src={passportFront} />
                            <div className="m-t-20">
                              <label>Hướng dẫn thực hiện</label>

                            </div>
                          </Col>
                        </Row>

                        <Row className="m-t-40">
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                            <div className="border-card-upload">

                              <Upload
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                customRequest={dummyRequest}
                                beforeUpload={beforeUpload}
                                onChange={handleChangePassportBack}
                              >
                                {imageUrlPassportBack ? <img src={imageUrlPassportBack} alt="avatar" className="img-upload" /> : uploadButton}
                              </Upload>
                              {imageUrlPassportBack
                                ? (
                                  <>
                                    <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                                      <div style={{ color: '#003262' }}>
                                        <HourglassOutlined />
                                        <span className="m-l-10">{descriptonStep.step2}</span>
                                      </div>
                                      <a
                                        href="#"
                                        className="m-l-30" style={{ color: 'red' }}
                                        onClick={() => setImageUrlPassportBack(null)}
                                      >
                                        Xóa
                                      </a>

                                    </div>
                                  </>
                                )
                                : <></>}
                            </div>
                            <div>
                              <span>Trang thông tin hộ chiếu</span>
                            </div>
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Định dạng tải lên: JPEG, PNG, PDF
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp rõ ràng, không bị mờ
                            </span>
                            <br />
                            <span>
                              <ExclamationCircleOutlined className="m-r-10" />
                              Ảnh chụp không bị che khuất, mất góc
                            </span>
                            <br />
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-l-10 text-center">
                            <img style={{ height: 300 }} src={passportBack} />
                            <div className="m-t-20">
                              <label>Hướng dẫn thực hiện</label>

                            </div>
                          </Col>
                        </Row>

                      </>
                    ) : (
                      <></>

                    )}

                    <Form.Item className="block">
                      <Button style={{ margin: '0 8px' }} onClick={prev}>
                        Quay lại
                      </Button>
                      <Button
                        type="primary"
                        className=" m-t-15"
                        onClick={onFinishStep2}
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>
                  </div>
                </>

              )

            case 2:
              return (
                <>
                  <h6 className="m-b-20">Cung cấp ảnh chụp khuôn mặt cùng với giấy tờ xác minh</h6>
                  <Form className="w-100">
                    <Row className="m-t-10">
                      <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                        <div className="border-card-upload">

                          <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            beforeUpload={beforeUpload}
                            onChange={handleChangeTarget}
                          >
                            {imageUrlTarget ? <img src={imageUrlTarget} alt="avatar" className="img-upload" /> : uploadButton}
                          </Upload>
                          {imageUrlTarget
                            ? (
                              <>
                                <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                                  <div style={{ color: '#003262' }}>
                                    <HourglassOutlined />
                                    <span className="m-l-10">{descriptonStep.step3}</span>
                                  </div>
                                  <a
                                    href="#"
                                    className="m-l-30" style={{ color: 'red' }}
                                    onClick={() => setImageUrlTarget(null)}
                                  >
                                    Xóa
                                  </a>

                                </div>
                              </>
                            )
                            : <></>}
                        </div>
                        <div>
                          <span>Hình ảnh khuôn mặt cùng giấy tờ xác minh</span>
                        </div>
                        <span>
                          <ExclamationCircleOutlined className="m-r-10" />
                          Định dạng tải lên: JPEG, PNG, PDF
                        </span>
                        <br />
                        <span>
                          <ExclamationCircleOutlined className="m-r-10" />
                          Ảnh chụp rõ ràng, không bị mờ
                        </span>
                        <br />
                        <span>
                          <ExclamationCircleOutlined className="m-r-10" />
                          Ảnh chụp không bị che khuất, mất góc
                        </span>
                        <br />
                        <Form layout="vertical" className="m-t-30">
                          <Form.Item className="item-form" label="Tên giấy tờ xác minh cùng" rules={[{ required: true }]}>
                            <Input
                              onChange={(e) => setAdditional(e.target.value)}
                              value={additional}
                              placeholder="Vui lòng nhập"
                            />
                          </Form.Item>
                          <span>
                            Ví dụ: CMND/CCCD, Hộ Chiếu, Bằng lái xe,...
                          </span>
                        </Form>

                      </Col>
                      <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-l-10 text-center">
                        <img style={{ height: 300 }} src={target} />
                        <div className="m-t-20">
                          <label>Hướng dẫn thực hiện</label>

                        </div>
                      </Col>
                    </Row>

                    <Form.Item className="block">
                      <Button onClick={prev}>
                        Quay lại
                      </Button>
                      <Button
                        type="primary"
                        className=" m-t-15 m-l-10"
                        onClick={onFinishStep3}
                      >
                        Tiếp tục
                      </Button>
                    </Form.Item>

                  </Form>
                </>

              )

            case 3:
              return (
                // <>
                //   <h6 className="m-b-20">'Thông tin liên hệ</h6>
                <Form
                  form={form}
                  layout="vertical" className="w-100" onFinish={onFinishStep4}
                  initialValues={defaultValuesStep4}
                >
                  <Row className="m-t-10">
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                      <Form.Item name="zip" className="item-form  m-t-10" label="Mã bưu điện" rules={[{ required: true }]}>
                        <Input
                          placeholder="Nhập mã bưu điện"
                          onChange={(e) => setDefaultValuesStep4({ ...defaultValuesStep4, ...{ zip: e.target.value } })}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                      <Form.Item name="province" className="item-form m-t-10" label="Tỉnh/ Thành phố" rules={[{ required: true }]}>
                        <Select
                          placeholder="Lựa chọn"
                          onChange={(option) => onProvinceSelect(option)}
                        >
                          {province?.map((option) => (
                            <Option key={option.codename} value={option.codename}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                  </Row>

                  <Row>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                      <Form.Item name="district" className="item-form m-t-10" label="Quận/ Huyện" rules={[{ required: true }]}>
                        <Select
                          placeholder="Lựa chọn"
                          onChange={(option) => onDistrictSelect(CurrentProvince, option)}
                        >
                          {district?.map((option) => (
                            <Option key={option.codename} value={option.codename}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                      <Form.Item name="sub_district" className="item-form m-t-10" label="Phường/ Xã" rules={[{ required: true }]}>
                        <Select
                          placeholder="Lựa chọn"
                          onChange={(e) => setDefaultValuesStep4({ ...defaultValuesStep4, ...{ sub_district: e } })}
                        >
                          {ward?.map((option) => (
                            <Option key={option.codename} value={option.codename}>
                              {option.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24} className="p-r-10">
                      <Form.Item name="address" className="item-form" label="Địa chỉ cụ thể" rules={[{ required: true }]}>
                        <Input
                          placeholder="Địa chỉ cụ thể"
                          onChange={(e) => setDefaultValuesStep4({ ...defaultValuesStep4, ...{ address: e.target.value } })}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Typography.Title level={4} className="m-t-30"> Xác minh hóa đơn</Typography.Title>
                  <Row className="m-t-10">
                    <Col spxs={{ span: 24 }} lg={{ span: 12 }} className="p-r-10">
                      <div className="border-card-upload">
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          customRequest={dummyRequest}
                          beforeUpload={beforeUpload}
                          onChange={handleChangeBill}
                        >
                          {imageUrlBill ? <img src={imageUrlBill} alt="avatar" className="img-upload" /> : uploadButton}
                        </Upload>
                        {imageUrlBill
                          ? (
                            <>
                              <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                                <div style={{ color: '#003262' }}>
                                  <HourglassOutlined />
                                  <span className="m-l-10">{descriptonStep.step4}</span>
                                </div>
                                <a
                                  href="#"
                                  className="m-l-30" style={{ color: 'red' }}
                                  onClick={() => setImageUrlBill(null)}
                                >
                                  Xóa
                                </a>

                              </div>
                            </>
                          )
                          : <></>}
                      </div>

                      <div>
                        <span>Hình ảnh Hóa đơn điện nước trùng với địa chỉ khai báo</span>
                      </div>
                      <span>
                        <ExclamationCircleOutlined className="m-r-10" />
                        Định dạng tải lên: JPEG, PNG, PDF
                      </span>
                      <br />
                      <span>
                        <ExclamationCircleOutlined className="m-r-10" />
                        Ảnh chụp rõ ràng, không bị mờ
                      </span>
                      <br />
                      <span>
                        <ExclamationCircleOutlined className="m-r-10" />
                        Ảnh chụp không bị che khuất, mất góc
                      </span>
                      <br />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} className="p-l-10 text-center">
                      <img style={{ height: 300 }} src={bill} />
                      <div className="m-t-20">
                        <label>Hướng dẫn thực hiện</label>

                      </div>
                    </Col>
                  </Row>
                  <Form.Item className="block">
                    <Button style={{ margin: '0 8px' }} onClick={prev}>
                      Quay lại
                    </Button>
                    <Button
                      type="primary"
                      className=" m-t-15"
                      htmlType="submit"
                    >
                      Tiếp tục
                    </Button>
                  </Form.Item>
                </Form>
                // </>

              )

            case 4:
              return (
                <div className="text-center">
                  <div>
                    <img src={success} />
                    <Typography.Title level={2} className="text-center">Chờ phê duyệt</Typography.Title>
                    <Typography.Title level={5} className="text-center">
                      Cám ơn
                      {' '}
                      {user.data?.name}
                      <br />
                      Hồ sơ của bạn đang được đội ngũ REX phê duyệt.
                      <br />
                      Chúng tôi sẽ phản hồi trong thời gian sớm nhất!
                      <br />

                    </Typography.Title>
                  </div>
                  <div>
                    <Link to="/dashboard">
                      <Button
                        type="primary"
                        className="m-t-15"
                        size="large"
                      >
                        Về trang chủ
                      </Button>
                    </Link>
                  </div>
                </div>
              )

            default:
              return null
          }
        })()
      }
    </div>
  )

  return (
    <>
      {/* Modal Otp Success */}
      <Modal
        footer={null}
        className=""
        visible={modalConfirm}
        onCancel={() => setModalConfirm(false)}
        onOk={() => setModalConfirm(false)}
      >
        <Typography.Title level={3} className="text-title">
          Hãy sẵn sàng
        </Typography.Title>
        <p style={{ marginBottom: 10 }}>
          Để cho tiến trình xác minh danh tính được thuận tiện nhất, Quý khách vui lòng chuẩn bị các giấy tờ liên quan, bao gồm:
        </p>
        <p style={{ marginBottom: 20, textAlign: 'left' }}>
          <ul className="m-l-20">
            <li>CMND / CCCD</li>
            <li>Hộ chiếu</li>
            <li>Hoá đơn bất kỳ</li>
          </ul>
        </p>
        <div style={{ marginBottom: 16, textAlign: 'right' }}>
          <Button onClick={() => setModalConfirm(false)} className="m-r-10">
            Quay về
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={confirmKYC}
          >
            Tiếp tục
          </Button>
        </div>
      </Modal>
      {showConfirm ? (
        <>
          <div className="text-center">
            {user.data.status === 'C' ? (
              <>
                <img src={success} />

                <Typography.Title level={2} className="text-center">Tài khoản đã xác minh</Typography.Title>
                <Typography.Title level={5} className="text-center">
                  Tài khoản
                  {' '}
                  {user.data.username}
                  {' '}
                  đã xác minh KYC
                </Typography.Title>

              </>
            ) : user.data.status === 'RJ' ? (
              <>
                <img src={fail} />
                <Typography.Title level={2} className="text-center">Xác minh chưa thành công</Typography.Title>
                <Typography.Title level={5} className="text-center">
                  Rất tiếc vì một vài lý do, hồ sơ của quý khách chưa được phê duyệt thành công.
                  Vui lòng hoàn thiện hoặc chỉnh sửa hồ sơ để khám phá ngay các cơ hội tại BRE.
                </Typography.Title>
              </>

            ) : user.data.status === 'PC' ? (
              <>
                <img src={process} />
                <Typography.Title level={2} className="text-center">Tài Khoản đang chờ phê duyệt</Typography.Title>
              </>
            ) : (
              <>
                <img src={verification} />
                <Typography.Title level={2} className="text-center">Tài Khoản Chưa Xác Minh</Typography.Title>
                <Typography.Title level={5} className="text-center">Thực hiện xác minh tài khoản với công nghệ KYC chỉ với 1 phút!</Typography.Title>
              </>
            )}

          </div>
          {user.data.status === 'P' ? (
            <>
              <div className="site-card-wrapper text-center m-t-30">
                <Row gutter={16}>
                  <Col span={8}>
                    <Card style={{ boxShadow: '0px 22px 36px rgba(0, 0, 0, 0.04)' }}>
                      <Typography.Title level={5}>1 phút</Typography.Title>
                      <span>
                        Xác minh tài khoản
                        {' '}
                        <br />
                        chỉ với 1 phút
                      </span>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card style={{ boxShadow: '0px 22px 36px rgba(0, 0, 0, 0.04)' }}>
                      <Typography.Title level={5}>4 bước</Typography.Title>
                      <span>
                        Quá trình 4 bước
                        {' '}
                        <br />
                        đơn giản
                      </span>

                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card style={{ boxShadow: '0px 22px 36px rgba(0, 0, 0, 0.04)' }}>
                      <Typography.Title level={5}>24 giờ</Typography.Title>
                      <span>
                        {' '}
                        Nhận kết quả
                        {' '}
                        <br />
                        nhanh chóng

                      </span>

                    </Card>
                  </Col>
                </Row>
              </div>
              <div className="text-center m-t-15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="m-t-15"
                  size="large"
                  onClick={() => setModalConfirm(true)}
                >
                  Xác minh ngay
                </Button>

                <p className="m-t-15">
                  <span className="mr-1">Bằng việc chọn “Xác minh ngay”, Quý khách đồng ý với các</span>
                  {' '}
                  <br />
                  <Link to="/dashboard">điều khoản và điều kiện </Link>
                  <span className="mr-1">của REX Block</span>
                </p>
              </div>
            </>
          ) : (<></>)}
          {user.data.status === 'RJ' || user.data.status === 'PC' ? (
            <>
              <div className="text-center m-t-15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="m-t-15"
                  size="large"
                  onClick={confirmKYC}
                >
                  Bắt đầu chỉnh sửa
                </Button>
              </div>
            </>
          ) : (<></>)}
          {user.data.status === 'C' ? (
            <>
              <div className="text-center m-t-15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="m-t-15"
                  size="large"
                  onClick={() => history.push('/dashboard')}
                >
                  Về trang chủ
                </Button>
              </div>
            </>
          ) : (<></>)}
        </>
      ) : (
        <>
        </>
      )}

      {showProcess ? (
        <>
          <Row>
            <Col span={24} className="loginForm">
              <div className="d-flex  justify-content-center flex-column m-t-30">
                <Steps current={currentStep} onChange={onChangeStep}>
                  <Step status={statusStep?.step1} title="Tên, Quốc Tịch" description={descriptonStep.step1} />
                  <Step status={statusStep?.step2} title="CMND / Passport" description={descriptonStep.step2} />
                  <Step status={statusStep?.step3} title="Hình ảnh" description={descriptonStep.step3} />
                  <Step status={statusStep?.step4} title="Địa chỉ hóa đơn" description={descriptonStep.step4} />
                  <Step status={statusStep?.step5} title="Hoàn tất" description={descriptonStep.step5} />
                </Steps>
                <div className="w-100 m-t-40">
                  {stepContent()}
                </div>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <>
        </>
      )}
    </>
  )
}
KYC.propTypes = {
  user: PropTypes.shape(),
  getInfo: PropTypes.func,
}

export default KYC
