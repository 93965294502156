/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
} from '@ant-design/icons'
import './index.css'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import tokenBreIcon from '../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../static/images/TokenVNĐ.png'
import tokenUSDRIcon from '../../static/images/TokenUSDR.png'
import { getUserData } from '../../redux/selector/user'
import * as userAction from '../../redux/action/user'
import { formatAmount } from '../../utils/common'

import WalletInfo from './wallet-info'

const { Title } = Typography

const Wallet = ({ wallet, user, getInfo }) => {
  console.log(user, getInfo)
  const history = useHistory()
  console.log('walletttttttttttttt', wallet)
  useEffect(() => { }, [])

  const data = [
    {
      key: '1',
      name: 'vnd',
      amount: wallet?.data?.fiat?.balance + wallet?.data?.lock_amounts?.VND || 0,
      availability: wallet?.data?.fiat?.balance || 0,
      order: wallet?.data?.lock_amounts?.VND || 0,
      exchange: wallet?.data?.fiat?.balance + wallet?.data?.lock_amounts?.VND || 0,
    },
    {
      key: '2',
      name: 'usdt',
      amount: wallet?.data?.usdt?.balance + wallet?.data?.lock_amounts?.USDT || 0,
      availability: wallet?.data?.usdt?.balance,
      order: wallet?.data?.lock_amounts?.USDT || 0,
      exchange: (wallet?.data?.usdt?.balance + wallet?.data?.lock_amounts?.USDT) * 23000 || 0,
    },
    {
      key: '4',
      name: 'usdr',
      amount: wallet?.data?.token?.balance + wallet?.data?.lock_amounts?.USDR || 0 || 0,
      availability: wallet?.data?.token?.balance || 0,
      order: wallet?.data?.lock_amounts?.USDR || 0,
      exchange: (wallet?.data?.token?.balance + wallet?.data?.lock_amounts?.USDR) * 23000 || 0,
    },
    {
      key: '3',
      name: 'rex',
      amount: wallet?.data?.public_token?.balance + wallet?.data?.lock_amounts?.REX || 0,
      availability: wallet?.data?.public_token?.balance || 0,
      order: wallet?.data?.lock_amounts?.REX || 0,
      exchange: (wallet?.data?.public_token?.balance + wallet?.data?.lock_amounts?.REX) * 23000 || 0,
    },
  ]
  const handleDeposit = (value) => {
    if (user.data.status === 'C') { history.push(`/wallet/${value}/deposit`) } else { setModalConfirmKyc(true) }
  }

  const handleWithDraw = (value) => {
    history.push(`/wallet/${value}/withdraw`)
  }

  const handleSwap = (value) => {
    history.push(`/wallet/swap/${value}`)
  }

  const columns = [
    {
      title: 'Tài sản',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        text === 'usdt' ? (
          <div className="d-flex align-items-center">

            <span className="mr-2">
              <Avatar src={tokenUsdtIcon} />
            </span>
            <span className="mr-1 font-weight-500">USDT</span>
            <span className="mr-1">Tether</span>

          </div>
        ) : text === 'rex' ? (
          <div className="d-flex align-items-center">

            <span className="mr-2">
              <Avatar src={tokenBreIcon} />
            </span>
            <span className="mr-1 font-weight-500">REX</span>
            <span className="mr-1">REX</span>
            <span className="mr-1">Coin</span>

          </div>
        )
          : text === 'vnd' ? (
            <div className="d-flex align-items-center">

              <span className="mr-2">
                <Avatar src={tokenVndIcon} />
              </span>
              <span className="mr-1 font-weight-500">VND</span>

            </div>
          )
            : text === 'usdr' ? (
              <div className="d-flex align-items-center">

                <span className="mr-2">
                  <Avatar src={tokenUSDRIcon} />
                </span>
                <span className="mr-1 font-weight-500">USDR</span>
                <span className="mr-1">USDR</span>
                <span className="mr-1">Coin</span>
              </div>
            )
              : '-'
      ),
    },
    {
      title: 'Tổng',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{formatAmount(text)}</span>
        </div>
      ),
    },
    {
      title: 'Khả dụng',
      key: 'availability',
      dataIndex: 'availability',
      render: (text) => <span className="font-weight-500">{formatAmount(text)}</span>,
    },
    {
      title: 'Đang đặt lệnh',
      key: 'order',
      dataIndex: 'order',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{formatAmount(text)}</span>
        </div>
      ),
    },
    {
      title: 'Đổi ra VND',
      key: 'exchange',
      dataIndex: 'exchange',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{formatAmount(text)}</span>
          {/* <span>VND</span> */}
        </div>
      ),
    },
    // {
    //   title: 'Thời gian',
    //   key: 'time',
    //   dataIndex: 'time',
    //   render: (text) => <span className="font-weight-500">30/01/2021 23:59</span>,
    // },
    {
      title: 'Trạng thái',
      key: 'name',
      dataIndex: 'name',
      align: 'center',
      render: (text) => (

        text === 'usdt' ? (
          <div className="text-right d-flex">

            <Button
              type="primary"
              className=""
              size="small"
              onClick={() => handleDeposit(text)}
            >
              Nạp
            </Button>

            <Button
              className="m-l-10"
              size="small"
              onClick={() => handleWithDraw(text)}
            >
              Rút
            </Button>

          </div>
        )
          : text === 'vnd' ? (
            <div className="text-right d-flex">

              <Button
                type="primary"
                className=""
                size="small"
                onClick={() => handleDeposit(text)}
              >
                Nạp
              </Button>

              <Button
                className="m-l-10"
                size="small"
                onClick={() => handleWithDraw(text)}
              >
                Rút
              </Button>

            </div>
          )
            : text === 'usdr' ? (
              <div className="text-right d-flex">
                <Button
                  type="primary"
                  className=""
                  size="small"
                  onClick={() => handleSwap(text)}
                >
                  Swap
                </Button>
              </div>
            )
              : text === 'rex' ? (
                <div className="text-right d-flex">

                  <Button
                    type="primary"
                    className=""
                    size="small"
                    onClick={() => handleSwap(text)}
                  >
                    Swap
                  </Button>
                </div>
              )
                : '-'
      ),
    },
  ]
  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')
  const [visible, setVisible] = useState(false)
  const [modalConfirmKyc, setModalConfirmKyc] = useState(false)

  const onChange = () => {
  }
  return (
    <>
      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalConfirmKyc}
        onOk={() => setModalConfirmKyc(false)}
        onCancel={() => setModalConfirmKyc(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Tài khoản chưa được xác minh
        </Title>
        <p>Rất tiếc, bạn chưa sử dụng được chức năng này. Vui lòng thực hiện xác minh tài khoản để tiếp tục hành trình.</p>
        <p className="mb-0">
          <Button
            type="primary"
            className=" m-t-15 mr-2 w-100"
            htmlType="button"
            onClick={() => { history.push('/kyc') }}
          >
            Xác minh ngay
          </Button>
        </p>
      </Modal>
      <Typography.Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
        Ví tài khoản
      </Typography.Title>

      <WalletInfo reload />

      <div className="m-t-40">

        {/* table balance */}
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          pagination={{ position: 'bottomCenter' }}
        />
      </div>
    </>
  )
}

Wallet.propTypes = {
  user: PropTypes.shape(),
  getInfo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
})
export default connect(
  (state) => (
    {
      user: getUserData(state),
    }), mapDispatchToProps,
)(Wallet)
