import walletApi from '../../provider/wallet'
import { WALLET_ACTION } from '../type'

export const get = () => async (dispatch) => {
  dispatch({
    type: WALLET_ACTION.GET_WALLET_INFO,
  })
  try {
    const wallet = await walletApi.getWallet()
    console.log(wallet)
    dispatch({
      type: WALLET_ACTION.GET_WALLET_OK,
      payload: wallet,
    })
  } catch (e) {
    dispatch({
      type: WALLET_ACTION.GET_WALLET_ERR,
      message: '',
    })
  }
}
