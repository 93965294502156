/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography, Tabs,
  Button, Form, Input,
  DatePicker,
  Modal,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination, Select, message,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  UserOutlined,
  LockOutlined,
  QrcodeOutlined,
  DollarOutlined,
  CopyOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import qrCode from '../../static/images/qr-code.png'
import donation from '../../static/images/donation.png'
import curriculum from '../../static/images/curriculum.png'
import user from '../../provider/user'
import wallet from '../../provider/wallet'
import referal from '../../provider/referal'
import './index.css'
import * as userAction from '../../redux/action/user'
import * as walletAction from '../../redux/action/wallet'
import { getUserData } from '../../redux/selector/user'
import { getWalletData } from '../../redux/selector/wallet'

const { TabPane } = Tabs
const { Option } = Select
const { Title } = Typography

const PersonalInfo = ({
  getInfo,
}) => {
  useEffect(() => { }, [])
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [bankAccount] = Form.useForm()
  // const [name, setName] = useState('')
  // const [password, setPassword] = useState('')
  const [modalOtp, setModalOtp] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [otp, setOTP] = useState('')
  const [defaultValuesStep1, setDefaultValuesStep1] = useState({
    name: '',
    birthday: null,
    email: '',
  })
  const [defaultValuesStep2, setDefaultValuesStep2] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })
  const [defaultValuesBankAccount, setDefaultValuesBankAccount] = useState({
    bankType: '',
    numberCard: '',
    fullName: '',
    bankBranch: '',
  })
  const [modalAddBankAccount, setModalAddBankAccount] = useState(false)
  const [listBankAccount, setListBankAccount] = useState([])
  const [dataReferal, setDataReferal] = useState({})
  const [currentReferral, setCurrentReferral] = useState(null)
  const [currentBank, setCurrentBank] = useState({})
  const [deleteBank, setDeleteBank] = useState({})
  useEffect(() => {
    form.setFieldsValue(defaultValuesStep1)
  }, [form, defaultValuesStep1])

  useEffect(() => {
    form.setFieldsValue(defaultValuesStep2)
  }, [form1, defaultValuesStep2])

  // useEffect(() => {
  //   form.setFieldsValue(defaultValuesBankAccount)
  // }, [bankAccount, defaultValuesBankAccount])

  const callback = (key) => {
    console.log(key)
  }

  useEffect(() => {
    getProfile()
    fetchListBankAccount()
    fetchReferal()
    fetchReferalInfo()
    return () => {
    }
  }, [])

  const getProfile = async () => {
    try {
      const resp = await user.getProfile()
      console.log(resp)
      setDefaultValuesStep1({
        ...defaultValuesStep1,
        ...{ name: resp.data.name },
        ...{ country: resp.data.country },
        ...{ birthday: resp.data.birthday ? moment(resp.data.birthday) : '' },
        ...{ email: resp.data.email },
      })
    } catch (e) {
      console.log(e)
    }
  }

  const updateInfoPersonal = async (values) => {
    const data = {
      name: values.name,
      country: values.country || '',
      birthday: values.birthday ? moment(values.birthday).format('YYYY-MM-DD') : '',
    }
    try {
      const resp = await user.updateInfoPersonal(data)
      console.log(resp)
      if (resp.code === 200) {
        toast.success('Cập nhật thông tin thành công.')
        getInfo()
      } else { toast.error(resp.msg) }
    } catch (e) {
      console.log(e)
    }
  }

  const updatePassword = async (values) => {
    const data = {
      old_password: values.oldPassword,
      new_password: values.newPassword,
      confirm_new_password: values.confirmNewPassword,
    }
    try {
      if (values.newPassword === values.confirmNewPassword) {
        const resp = await user.changePassword(data)
        if (resp.code === 200) { toast.success('Thay đổi mật khẩu thành công.') } else { return }
      } else { toast.error('Mật khẩu mới và xác minh mật khẩu mới không khớp. Vui lòng thử lại.') }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchListBankAccount = async () => {
    try {
      const resp = await wallet.getBankAcoount()
      console.log(resp)
      setListBankAccount(resp.data)
      if (resp.data.length > 0) {
        setCurrentBank(resp.data[0])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addBankAccount = async (values) => {
    const data = {
      bank_type: values.bankType,
      number_card: values.numberCard,
      full_name: values.fullName,
      bank_branch: values.bankBranch,
    }
    try {
      const resp = await wallet.createBankAccount(data)
      console.log(resp)
      if (resp?.code === 200) {
        toast.success('Thêm mới tài khoản ngân hàng thành công')
        fetchListBankAccount()
        setModalAddBankAccount(false)
        bankAccount.resetFields()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const deleteBankAccount = async (values) => {
    setConfirmModal(false)
    console.log(values)
    const data = {
      // eslint-disable-next-line no-underscore-dangle
      bank_account_id: values._id,
    }
    console.log(data)
    try {
      const resp = await wallet.deleteBankAccount(data)
      console.log(resp)
      if (resp?.code === 200) {
        toast.success('Xóa tài khoản ngân hàng thành công')
        fetchListBankAccount()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const fetchReferal = async () => {
    try {
      const resp = await referal.getReferal()
      console.log(resp)
      setDataReferal(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchReferalInfo = async () => {
    const data = {}
    try {
      const resp = await referal.getReferalInfo(data)
      console.log(resp)
      setCurrentReferral(resp.data)
    } catch (e) {
      console.log(e)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const copyToClipboard = async (values) => {
    try {
      await navigator.clipboard.writeText(values)
      message.success('Sao chép thành công.')
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>

      {/* Modal Add Bank Account */}

      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={confirmModal}
        onOk={() => setConfirmModal(false)}
        onCancel={() => setConfirmModal(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Xác nhận xóa tài khoản ngân hàng.
        </Title>
        <p>Bạn có muốn xóa tài khoản ngân hàng này ?</p>
        <Button
          className=" m-t-15 mr-2"
          htmlType="submit"
          onClick={() => setModalAddBankAccount(false)}
        >
          Hủy
        </Button>
        <Button
          type="primary"
          className=" m-t-15"
          onClick={() => deleteBankAccount(deleteBank)}
        >
          Xác nhân
        </Button>
      </Modal>

      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalAddBankAccount}
        onOk={() => { setModalAddBankAccount(false); bankAccount.resetFields() }}
        onCancel={() => { setModalAddBankAccount(false); bankAccount.resetFields() }}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Tạo tài khoản ngân hàng mới
        </Title>
        <Form
          form={bankAccount}
          layout="vertical"
          // initialValues={defaultValuesBankAccount}
          onFinish={addBankAccount}
        >
          <Form.Item name="bankType" className="item-form m-t-10" label="Tên ngân hàng" rules={[{ required: true, message: 'Tên ngân hàng không được để trống.' }]}>
            <Select
              placeholder="Vui lòng chọn ngân hàng."
              showSearch
            >
              <Option value="VIETINBANK">VIETINBANK</Option>
              <Option value="VIETCOMBANK">VIETCOMBANK</Option>
              <Option value="VPBANK">VPBANK</Option>
              <Option value="MBBANK">MBBANK</Option>
            </Select>
          </Form.Item>

          <Form.Item name="numberCard" className="item-form m-t-10" label="Số tài khoản" rules={[{ required: true, message: 'Số tài khoản không được để trống.' }]}>
            <Input
              placeholder="Vui lòng nhập"
            />
          </Form.Item>

          <Form.Item name="fullName" className="item-form m-t-10" label="Tên chủ tài khoản" rules={[{ required: true, message: 'Tên chủ tài khoản không được để trống."' }]}>
            <Input
              placeholder="Vui lòng nhập"
            />
          </Form.Item>

          <Form.Item className="block right">
            <Button
              className=" m-t-15 mr-2"
              htmlType="submit"
              onClick={() => { setModalAddBankAccount(false); bankAccount.resetFields() }}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              className=" m-t-15"
              htmlType="submit"
            >
              Thêm
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Typography.Title level={2}>Thông tin cá nhân</Typography.Title>
      {/* Fillter */}
      <div className="mb-4">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={(
              <span>
                <UserOutlined />
                Thông tin cá nhân
              </span>
            )}
            key="1"
          >
            <Form
              form={form}
              // wrapperCol={{ span: 8 }}
              layout="vertical"
              initialValues={defaultValuesStep1}
              onFinish={updateInfoPersonal}
              onFinishFailed={onFinishFailed}
              className="form-infor"
            >
              <Form.Item
                label="Họ và tên"
                name="name"
                rules={[{ required: true, message: 'Họ và tên không được để trống.' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Ngày tháng năm sinh"
                name="birthday"
                // rules={[{ required: true, message: 'Please input your birthday!' }]}
              >
                <DatePicker
                  disabledDate={(current) => {
                    const customDate = moment().format('YYYY-MM-DD')
                    return current && current > moment(customDate, 'YYYY-MM-DD')
                  }} style={{ width: '100%' }}
                />
              </Form.Item>
              {/*
              <Form.Item
                label="Quốc tịch"
                name="country"
                rules={[{ required: true, message: 'Please input your country!' }]}
              >
                <Input />
              </Form.Item> */}

              <Form.Item
                name="country" label="Quốc tịch"
              // rules={[{ required: true }]}
              >
                <Select>
                  <Option value="VN">Việt Nam</Option>
                  <Option value="NN">Nước Ngoài</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Địa chỉ email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Lưu thay đổi
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={(
              <span>
                <LockOutlined />
                Đổi mật khẩu
              </span>
            )}
            key="2"
          >
            <Form
              form={form1}
              className="form-infor"
              layout="vertical"
              initialValues={defaultValuesStep2}
              onFinish={updatePassword}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Mật khẩu cũ"
                name="oldPassword"
                rules={[{ required: true, message: 'Mật khẩu không được để trống.' }]}
              >
                <Input.Password className="pl-2" />
              </Form.Item>
              <Form.Item
                label="Mật khẩu mới"
                name="newPassword"
                rules={[{ required: true, message: 'Mật khẩu không được để trống.' }]}
              >
                <Input.Password className="pl-2" />
              </Form.Item>
              <Form.Item
                label="Nhập lại mật khẩu mới"
                name="confirmNewPassword"
                rules={[{ required: true, message: 'Mật khẩu không được để trống.' }]}
              >
                <Input.Password className="pl-2" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Hoàn tất
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane
            tab={(
              <span>
                <DollarOutlined />
                Tài khoản ngân hàng
              </span>
            )}
            key="3"
          >
            <div className="form-infor">
              <div className="mb-3 text-end">
                {listBankAccount.length > 0 ? listBankAccount.map((item, index) => (
                  <>
                    <Button
                      onClick={() => setCurrentBank(item)}
                      className={`btn-block select-bank-account ${currentBank?._id === item?._id ? 'active-bank' : ''}`}
                    >

                      <Row className="catalog">
                        <Col span={24}>{item?.bank_type}</Col>
                        <Col span={24}>
                          {item?.full_name}
                          {' '}
                          -
                          {' '}
                          {item?.number_card}
                        </Col>
                      </Row>
                      {
                        currentBank?._id === item?._id ? (<CheckCircleOutlined style={{ color: '#FDB515' }} />) : (<></>)
                      }

                    </Button>
                    <div
                      className={`mb-2 ${currentBank?._id === item?._id ? 'd-flex justify-content-between align-items-center' : ''}`}
                    >
                      {
                        currentBank?._id === item?._id ? (<p className="my-0" style={{ color: '#FDB515' }}>Mặc định</p>) : (<></>)
                      }
                      <Button type="link" icon={<DeleteOutlined />} onClick={() => { setConfirmModal(true); setDeleteBank(item) }}>

                        Xóa
                      </Button>
                    </div>
                  </>
                )) : (<></>)}

              </div>
              <Button
                className="btn-block add-bank-account mb-4"
                onClick={() => setModalAddBankAccount(true)}
              >
                Thêm ngân hàng
                <PlusOutlined />
              </Button>
            </div>
            <Button type="primary" htmlType="submit" onClick={() => { }}>
              Lưu thay đổi
            </Button>
          </TabPane>
          <TabPane
            tab={(
              <span>
                <QrcodeOutlined />
                Mã giới thiệu
              </span>
            )}
            key="4"
          >
            <Card className="walletContent" bordered={false}>
              <div className="d-flex">
                <div className="flex-shrink-0 mr-3">
                  <img src={dataReferal.referUrlQR} />
                </div>
                <div className="flex-grow-1 ms-3 align-self-center">
                  <div className="mb-4">
                    <p className="mb-2 h6">Mã giới thiệu</p>
                    <div className="h6 d-flex justify-content-between">
                      <div>{dataReferal.code}</div>
                      <div><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(dataReferal.code)} /></div>
                    </div>
                  </div>
                  <div>
                    <p className="mb-2 h6">Link giới thiệu</p>
                    <div className="h6 d-flex justify-content-between">
                      <div>{dataReferal.referUrl}</div>
                      <div><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(dataReferal.referUrl)} /></div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            <Typography.Title level={3} className="text-title">
              Danh sách bạn giới thiệu
            </Typography.Title>

            {/* Referal */}
            <Card className="walletContent" bordered={false}>
              <div className="d-flex">
                <div className="flex-shrink-0 mr-3">
                  <img src={donation} style={{ width: 72, height: 72 }} />
                </div>
                <div className="flex-grow-1 ms-3 align-self-center">
                  <p className="m-0">Tổng số lượng bạn bè đã đăng ký</p>
                  <div className="balance">
                    {currentReferral?.total}
                  </div>
                </div>
              </div>
            </Card>

            <Card className="walletContent" bordered={false}>
              <div className="d-flex">
                <div className="flex-shrink-0 mr-3">
                  <img src={curriculum} style={{ width: 72, height: 72 }} />
                </div>
                <div className="flex-grow-1 ms-3 align-self-center">
                  <p className="m-0">Tổng số lượng bạn bè đã deposit</p>
                  <div className="balance">
                    {/* // eslint-disable-next-line no-underscore-dangle */}
                    {currentReferral?.totalDeposit}
                  </div>
                </div>
              </div>
            </Card>

          </TabPane>
        </Tabs>

      </div>

    </>
  )
}

PersonalInfo.propTypes = {
  getInfo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
  getWallet: () => dispatch(walletAction.get()),
})

export default connect(
  (state) => (
    {
      wallet: getWalletData(state),
      user: getUserData(state),
    }),

  mapDispatchToProps,
)(PersonalInfo)
