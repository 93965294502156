import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  useHistory, Route, Link, BrowserRouter as Router,
} from 'react-router-dom'

import {
  Menu, Input, Avatar, Layout, Button,
} from 'antd'
import logo from '../../../static/images/rex-logo.png'
import avatar from '../../../static/images/flat-avatar.png'

import './index.css'
import { getUserData } from '../../../redux/selector/user'
import * as userAction from '../../../redux/action/user'

const { SubMenu } = Menu

const { Search } = Input
const Header = ({ user, getInfo }) => {
  console.log(user)
  const history = useHistory()
  useEffect(() => {
    if (!user?.data?.username) {
      // getInfo()
    }
    return () => { }
  }, [])

  const [isLogout, setIsLogout] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')

  return (
    <header className="main-header">
      <div className="container">
        <Menu
          mode="horizontal"
        // className="d-flex align-items-center custom-navigation"
        >

          <Menu.Item key="brand-logo" className="brand-logo-bre">
            {/* <Link to="/dashboard"> */}
            <img src={logo} className="m-r-5" />
            {/* </Link> */}
          </Menu.Item>

          {/* <Menu className="auto" mode="horizontal">
            <Menu.Item className="menu-item" key="about">
              <a href="http://quesera.saturnthemes.com/">Về BRE</a>
            </Menu.Item> */}

          {/* <Menu.Item key="gp" className="menu-item">
              <a href="http://quesera.saturnthemes.com/">Giải Pháp</a>
            </Menu.Item> */}

          {/* <Menu.Item key="products" className="menu-item">
            <Link to="/products">Sản phẩm</Link>
          </Menu.Item> */}

          {/* <Menu.Item key="tt" className="menu-item">
              <a href="http://quesera.saturnthemes.com/tin-tuc/">Tin Tức</a>
            </Menu.Item> */}

          {
            user?.data?.name ? (
              <Menu.Item key="tt" className="menu-item">
                <span>
                  <Avatar size={24} src={avatar} />
                  <span>
                    {' '}
                    {user?.data?.name || ''}
                  </span>
                </span>
              </Menu.Item>
            ) : (
              <>
                <Menu.Item className="menu-item">
                  <Button type="primary" onClick={() => history.push('/sign-up')}>Đăng Ký</Button>
                </Menu.Item>

                <Menu.Item className="menu-item">
                  <Button onClick={() => history.push('/sign-in')}>Đăng nhập</Button>
                </Menu.Item>
              </>
            )

          }

        </Menu>
      </div>
    </header>
  )
}

Header.propTypes = {
  user: PropTypes.shape(),
  getInfo: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  getInfo: () => dispatch(userAction.get()),
})
export default connect(
  (state) => (
    {
      user: getUserData(state),
    }), mapDispatchToProps,
)(Header)
