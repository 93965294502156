import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs, InputNumber, Upload,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
  PhoneOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons'
// import './index.css'
import styled from 'styled-components'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import ModalContactInfo from '../contact-info'
import WithDrawVND from './withdraw'
import DepositVND from './deposit'

const VND = ({ id }) => {
  const history = useHistory()

  const { TabPane } = Tabs
  const [defaultActiveTab, setDefaultActiveTab] = useState('')

  useEffect(() => {
    console.log('idddddd', id)

    if (id === 'deposit') {
      setDefaultActiveTab('1')
    }
    if (id === 'withdraw') {
      setDefaultActiveTab('2')
    }
  }, [])

  const onChange = (value) => {
    console.log(value)
    if (value === '1') {
      history.push('/wallet/vnd/deposit')
      setDefaultActiveTab('1')
    }

    if (value === '2') {
      history.push('/wallet/vnd/withdraw')
      setDefaultActiveTab('2')
    }
  }

  return (
    <>
      <Typography.Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
        { defaultActiveTab === '1' ? (<>Nạp tiền VND</>) : defaultActiveTab === '2' ? (<>Rút tiền VND</>) : (<></>) }
      </Typography.Title>

      <Tabs activeKey={defaultActiveTab} onChange={onChange}>
        <TabPane tab="Nạp tiền" key="1">
          {/* Wallet info */}
          <DepositVND />

        </TabPane>
        <TabPane tab="Rút tiền" key="2">
          {/* Wallet info */}
          <WithDrawVND />
        </TabPane>

      </Tabs>

    </>
  )
}

export default VND
