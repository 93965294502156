/* eslint-disable no-sequences */
/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs, InputNumber, Upload, message,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  RightOutlined,
  PhoneOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  CopyOutlined,
} from '@ant-design/icons'

// import './index.css'
import styled from 'styled-components'
import moment from 'moment'
import { toast } from 'react-toastify'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import ModalContactInfo from '../contact-info'
import WithDrawVND from './withdraw'
import wallet from '../../../provider/wallet'
import WalletInfo from '../wallet-info'
import { formatAmount } from '../../../utils/common'

const { Step } = Steps

const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`

const TextColorRed = styled.div`
  color: #D94046;
  font-weight: 500;
`

const TextColorWarning = styled.div`
  color: #FDB515;
  font-weight: 500;
`

export const WALLET_STATUS = {
  W: 'W',
  S: 'S',
  P: 'P',
  PC: 'PC',
  C: 'C',
  CL: 'CL',
  F: 'F',
  WUT: 'WUT',
  WSV: 'WSV',
}
const STATUS_COLORS = {
  [WALLET_STATUS.W]: 'waiting',
  [WALLET_STATUS.S]: 'success',
  [WALLET_STATUS.P]: 'waiting',
  [WALLET_STATUS.PC]: 'success',
  [WALLET_STATUS.C]: 'success',
  [WALLET_STATUS.CL]: 'fail',
  [WALLET_STATUS.F]: 'fail',
  [WALLET_STATUS.WSV]: 'waiting',
  [WALLET_STATUS.WUT]: 'waiting',
}

export const STATUS_NAMES = {
  [WALLET_STATUS.W]: 'Chờ xác nhận',
  [WALLET_STATUS.S]: 'Hoàn tất',
  [WALLET_STATUS.P]: 'Chưa xử lý',
  [WALLET_STATUS.PC]: 'Tiến hành trả',
  [WALLET_STATUS.C]: 'Giao dịch thành công',
  [WALLET_STATUS.CL]: 'Đã hủy',
  [WALLET_STATUS.F]: 'Thất bại',
  [WALLET_STATUS.WSV]: 'Đang xác nhận',
  [WALLET_STATUS.WUT]: 'Chờ chuyển tiền',
}

const DepositVND = (classe) => {
  const { TabPane } = Tabs

  const goToDetailDeposit = (data) => {
    console.log(data)
    setInfoDetailDeposit(data)
    setModalDepositDetail(true)
  }

  const [form] = Form.useForm()
  const [currentDeposit, setCurrentDeposit] = useState({ deposit: '' })
  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')
  const [visible, setVisible] = useState(false)
  const [modalTransactionDetail, setModalTransactionDetail] = useState(false)
  const [modalDepositDetail, setModalDepositDetail] = useState(false)
  const [modalContactInfo, setModalContactInfo] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [statusStep, setStatusStep] = useState({
    step1: '',
    step2: '',
  })

  const [query, setQuery] = useState({
    currency: 'VND',
    page: 1,
    limit: 10,
  })

  const [infoRequest, setInfoRequest] = useState({})
  const [selectInfo, setselectInfo] = useState({})

  const [infoDetailDeposit, setInfoDetailDeposit] = useState({})
  const [data, setData] = useState([])
  const [descriptonStep, setDescriptonStep] = useState({
    step1: 'Đang thực hiện',
    step2: 'Đang chờ',
  })

  const [imageUrlPaymentProof, setImageUrlPaymentProof] = useState('')
  const [fileUpload, setFileUpload] = useState(null)

  const getListDeposit = async (params) => {
    const resp = await wallet.getListDeposit(params)
    console.log('list deposit', resp)
    setData(resp.data)
  }

  const columns = [
    {
      title: 'Mã tham chiếu',
      key: 'no',
      dataIndex: 'no',
      render: (text) => <span className="font-weight-500">{text || '-'}</span>,
    },
    {
      title: 'Số tiền nạp',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{formatAmount(text || 0) }</span>
          <span>VND</span>
        </div>
      ),
    },
    {
      title: 'Thời gian',
      key: 'created_at_time',
      dataIndex: 'created_at_time',
      render: (text) => (
        <span className="font-weight-500">
          {moment(text).format('MM/DD/YYYY HH:mm:ss')}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text) => (
        <p className={`text-row ${STATUS_COLORS[text]}`}>
          {STATUS_NAMES[text]}
        </p>
      ),
    },
    {

      title: 'Thao tác',
      align: 'center',
      key: 'status',
      dataIndex: 'status',
      render: (text, row) => row.status === 'WUT' ? (
        <div className="text-center">
          <Button
            onClick={() => { setInfoRequest({ ...infoRequest, ...{ request: row } }); setModalTransactionDetail(true) }}
            shape="circle"
            icon={<RightOutlined className="colorIcon" />}
            size="small"
          />
        </div>
      ) : (
        <div className="text-center">
          <Button
            onClick={() => goToDetailDeposit(row)}
            shape="circle"
            icon={<RightOutlined className="colorIcon" />}
            size="small"
          />
        </div>

      ),
    },
  ]

  const resetForm = () => {
    setInfoRequest({})
    setInfoDetailDeposit({})
    setFileUpload(null)
    setImageUrlPaymentProof('')
    setCurrentStep(0)
  }
  useEffect(() => {
    getListDeposit(query)
  }, [])

  useEffect(() => {
    form.setFieldsValue(currentDeposit)
  }, [form, currentDeposit])

  const onChangeStep = (current) => {
    setCurrentStep(current)
  }

  const cancelRequest = async () => {
    const payload = {
      no: infoRequest?.request?.no,
      currency: 'VND',
    }
    await wallet.cancelDeposit(payload)
    toast.success('yêu cầu của bạn đã được hủy.')
    getListDeposit(query)
    setModalTransactionDetail(false)
  }

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <div className="d-flex align-items-center">
        <span className="mr-2">VND</span>
        <Avatar src={tokenVndIcon} size={18} />
      </div>
    </Form.Item>
  )

  const uploadButton = (
    <div>
      <CloudUploadOutlined style={{ fontSize: 40 }} />
      <div className="m-t-20">Click hoặc kéo ảnh vào khung hình</div>
    </div>
  )

  const onFinishStep1 = async () => {
    setModalTransactionDetail(true)
    setCurrentStep(1)
  }

  const onFinishStep2 = async () => {
    console.log(fileUpload)
    if (fileUpload) {
      const payload = {
        currency: 'VND',
        no: infoRequest?.request?.no,
        status: 'WSV',
      }
      const resp = await wallet.markDeposit(payload, fileUpload)
      console.log(resp)
      toast.success('yêu cầu của bạn đã được gửi.')
      getListDeposit(query)
      setModalTransactionDetail(false)
      resetForm()
    } else {
      toast.error('Vui lòng tải bằng chứng nạp tiền.')
    }
  }

  const prev = async () => {
    setModalTransactionDetail(true)
    setCurrentStep(0)
  }

  const requestDeposit = async (data) => {
    const payload = { amount: data.deposit, currency: 'VND' }
    const resp = await wallet.requestDeposit(payload)
    console.log(resp.data)
    setInfoRequest(resp.data)
    getListDeposit(query)
    setModalTransactionDetail(true)
    clearDataDeposit()
  }

  const clearDataDeposit = () => {
    setCurrentDeposit({ deposit: '' })
  }

  const copyToClipboard = async (values) => {
    try {
      await navigator.clipboard.writeText(values)
      message.success('Sao chép thành công.')
    } catch (error) {
      console.log(error)
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const imageUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      toast.warning('You can only upload JPG/PNG file!')
      return ''
    }
    // const isLt2M = file.size / 1024 / 1024 < 2
    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!')
    // }
    return isJpgOrPng
  }

  const handleChangePaymentProof = async (info) => {
    getBase64(info.file.originFileObj, (imageUrl) => setImageUrlPaymentProof(imageUrl))
    setFileUpload(info?.file?.originFileObj)
  }

  const stepContent = () => (

    (() => {
      switch (currentStep) {
        case 0:
          return (
            <>
              <Title level={3} className="font-weight-500 mb-3">
                Thông tin nạp tiền
              </Title>
              <DescriptionText>
                <DescriptionTextLeft>Số tiền nạp</DescriptionTextLeft>
                <DescriptionTextRight>
                  {infoRequest?.request?.quantity?.toLocaleString()}
                  {' '}
                  VND
                </DescriptionTextRight>
              </DescriptionText>
              <DescriptionText>
                <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
                <TextColorGreen>Nạp / VND</TextColorGreen>
              </DescriptionText>
              <hr />
              <Title level={5} className="font-weight-500 mb-2">
                Thông tin chuyển khoản
              </Title>
              <DescriptionText>
                <DescriptionTextLeft>Ngân hàng</DescriptionTextLeft>
                <DescriptionTextRight>
                  <span className="mr-2">{infoRequest?.account?.bank_type || infoRequest?.request?.to?.bank_type}</span>
                  <span><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(infoRequest?.account?.bank_type)} /></span>
                </DescriptionTextRight>
              </DescriptionText>
              <DescriptionText>
                <DescriptionTextLeft>Số tài khoản</DescriptionTextLeft>
                <DescriptionTextRight>
                  <span className="mr-2">{infoRequest?.account?.number_card || infoRequest?.request?.to?.number_card}</span>
                  <span><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(infoRequest?.account?.number_card)} /></span>
                </DescriptionTextRight>
              </DescriptionText>
              <DescriptionText>
                <DescriptionTextLeft>Chủ tài khoản</DescriptionTextLeft>
                <DescriptionTextRight>
                  <span className="mr-2">{infoRequest?.account?.full_name || infoRequest?.request?.to?.full_name}</span>
                  <span><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(infoRequest?.account?.full_name)} /></span>
                </DescriptionTextRight>
              </DescriptionText>
              <DescriptionTextEnd>
                <DescriptionTextLeft>Nội dung</DescriptionTextLeft>
                <DescriptionTextRight>
                  <span className="mr-2">{infoRequest?.request?.no}</span>
                  <span><CopyOutlined style={{ color: '#1890FF' }} onClick={() => copyToClipboard(infoRequest?.request?.no)} /></span>
                </DescriptionTextRight>
              </DescriptionTextEnd>
              <p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-block"
                  size="large"
                  onClick={onFinishStep1}
                >
                  Gửi bằng chứng thanh toán
                </Button>
              </p>
              <p>
                <Button
          // type="primary"
                  htmlType="submit"
                  className="btn-block"
                  size="large"
                  onClick={cancelRequest}
                >
                  Hủy lệnh
                </Button>
              </p>
              <p>Sau khi chuyển khoản xong, vui lòng gửi bằng chứng thanh toán để hoàn tất quá trình</p>
            </>

          )

        case 1:
          return (
            <>
              <Title level={3} className="font-weight-500 mb-3">
                Cung cấp bằng chứng nạp tiền
              </Title>

              <div className="border-card-upload">
                <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // customRequest={dummyRequest}
                  beforeUpload={imageUpload}
                  onChange={handleChangePaymentProof}
                >
                  <div>
                    {imageUrlPaymentProof ? <img src={imageUrlPaymentProof} alt="avatar" className="img-upload" /> : uploadButton}
                  </div>

                </Upload>
                {/* <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                >
                  {uploadButton}
                  {imageUrlTarget ? <img src={imageUrlTarget} alt="avatar" className="img-upload" /> : uploadButton}
                </Upload> */}
                {/* {imageUrlTarget
                      ? (
                        <>
                          <div className="m-t-10" style={{ display: 'flex', justifyContent: 's' }}>
                            <div style={{ color: '#003262' }}>
                              <HourglassOutlined />
                              <span className="m-l-10">{descriptonStep.step3}</span>
                            </div>
                            <a
                              href="#"
                              className="m-l-30" style={{ color: 'red' }}
                              onClick={() => setImageUrlTarget(null)}
                            >
                              Xóa
                            </a>

                          </div>
                        </>
                      )
                      : <></>} */}
              </div>
              <p>
                <span>
                  <ExclamationCircleOutlined className="m-r-10" />
                  Vui lòng cung cấp ảnh xác nhận chuyển tiền
                </span>
              </p>
              <p>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-block mb-3"
                  size="large"
                  onClick={onFinishStep2}
                >
                  Xác nhận nạp tiền
                </Button>
              </p>
              {/* <p>
                <Button
          // type="primary"
                  htmlType="submit"
                  className="btn-block"
                  size="large"
                  onClick={prev}
                >
                  Quay lại
                </Button>
              </p> */}

            </>

          )

        default:
          return null
      }
    })()

  )

  return (
    <>
      <Modal
        footer={null}
        visible={modalDepositDetail}
        onOk={() => setModalDepositDetail(false)}
        onCancel={() => setModalDepositDetail(false)}
      >
        <>
          <Title level={3} className="font-weight-500 mb-3">
            Chi tiết giao dịch
          </Title>
          <DescriptionText>
            <DescriptionTextLeft>Mã giao dịch</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-2">{infoDetailDeposit?.no}</span>
              {/* <span><CopyOutlined onClick={() => copyToClipboard(infoRequest?.request?.no)} /></span> */}
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
            <TextColorGreen>
              Nạp /
              {' '}
              {infoDetailDeposit?.currency}
            </TextColorGreen>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Số tiền nạp</DescriptionTextLeft>
            <DescriptionTextRight>
              {infoDetailDeposit?.quantity?.toLocaleString()}
              {' '}
              VND
            </DescriptionTextRight>
          </DescriptionText>

          {/* <hr />
          <DescriptionText>
            <DescriptionTextLeft>Ngân hàng</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-2">{infoDetailDeposit?.bank_type}</span>
              <span><CopyOutlined onClick={() => copyToClipboard(infoRequest?.account?.bank_type)} /></span>
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Số tài khoản</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-2">{infoDetailDeposit?.number_card}</span>
              <span><CopyOutlined onClick={() => copyToClipboard(infoRequest?.account?.number_card)} /></span>
            </DescriptionTextRight>
          </DescriptionText>
          <DescriptionText>
            <DescriptionTextLeft>Chủ tài khoản</DescriptionTextLeft>
            <DescriptionTextRight>
              <span className="mr-2">{infoDetailDeposit?.full_name}</span>
              <span><CopyOutlined onClick={() => copyToClipboard(infoRequest?.account?.full_name)} /></span>
            </DescriptionTextRight>
          </DescriptionText> */}

          <DescriptionText>
            <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
            <span className={` ${STATUS_COLORS[infoDetailDeposit?.status]}`}>
              {STATUS_NAMES[infoDetailDeposit?.status]}
            </span>

          </DescriptionText>
          <DescriptionTextEnd>
            <DescriptionTextLeft>Ghi chú</DescriptionTextLeft>
            <DescriptionTextRight>
              {infoDetailDeposit?.confirmation?.note}
            </DescriptionTextRight>
          </DescriptionTextEnd>
          <p>
            <Button
          // type="primary"
              htmlType="submit"
              className="btn-block"
              size="large"
              onClick={() => setModalDepositDetail(false)}
            >
              Đóng
            </Button>
          </p>
        </>
      </Modal>
      <ModalContactInfo
        visible={modalContactInfo}
        onOK={() => setModalContactInfo(false)}
        onCancel={() => setModalContactInfo(false)}
      />

      <Modal
        footer={null}
        visible={modalTransactionDetail}
        onOk={() => setModalTransactionDetail(false)}
        onCancel={() => setModalTransactionDetail(false)}
      >
        {stepContent()}
        <Steps progressDot current={currentStep} onChange={onChangeStep} className="">
          <Step title="Bước 1" />
          <Step title="Bước 2" />
        </Steps>
      </Modal>

      <WalletInfo />

      {/* Deposit amount */}
      <Card className="walletContent" bordered={false}>
        <div className="balance">
          <Form
            form={form}
            name="deposit-amount"
            scrollToFirstError
            onFinish={requestDeposit}
            initialValues={currentDeposit}
          >
            <Form.Item
              name="deposit"
              label="Số tiền nạp"
              rules={[{ required: true, message: 'Please input deposit amount!' }, {
                message: 'this is custom',
                validator: (_, values) => {
                  if (values < 100000) {
                    return Promise.reject('Some message here')
                  }
                  return Promise.resolve('')
                },
              },
              ]}
              help="Số tiền nạp tối thiểu là 100.000 VND"
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                addonAfter={suffixSelector}
                className="w-100"
              />
            </Form.Item>
            <Button type="primary" className="mr-2" htmlType="submit">
              Nạp tiền
            </Button>
            <Button icon={<PhoneOutlined />} onClick={() => setModalContactInfo(true)}>
              Liên hệ hỗ trợ
            </Button>
          </Form>
        </div>
      </Card>
      <div className="m-t-40">

        {/* table balance */}
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          pagination={{ position: 'bottomCenter' }}
        />
      </div>
    </>
  )
}

export default DepositVND
