import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Redirect } from 'react-router-dom'
import Dashboard from './container/Dashboard'
import History from './container/History'
import Kyc from './container/Kyc'
import Wallet from './container/wallet'
import GoogleAuthen from './container/2FA'
import Deactive2FA from './container/Deactive2FA'
import VND from './container/wVND'
import WithDrawVND from './container/WithDrawVND'
import USDT from './container/wUSDT'
import WithDrawUSDT from './container/WithDrawUSDT'
import SignIn from './container/Signin'
import SignUp from './container/Signup'
import ResetPassword from './container/ResetPassword'
import NotFoundPage from './pages/NotFoundPage'
import ProductList from './pages/product'
import PrivateLayout from './layout/PrivateLayout'
import PublicLayout from './layout/PublicLayout'
import RouteWithLayout from './layout/RouteWithLayout'
import PersonalInfo from './pages/personalInfo'
import ProductSingle from './pages/product/single'
import Swap from './container/Swap'
import ManagementProduct from './container/ManagementProduct'
import ManagementDistribute from './container/ManagementDistribute'
import Transfer from './container/Transfer'
import OrderBookBuy from './container/OrderBookBuy'
import OrderBookSale from './container/OrderBookSale'
import Deal from './container/Deal'
import BountyProgram from './container/BountyProgram'

const Routes = () => {
  useEffect(() => {
    // Integrate socket
    console.log('route')
  }, [])

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/sign-in"
      />
      <RouteWithLayout
        component={Dashboard}
        exact
        layout={PrivateLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={Kyc}
        exact
        layout={PrivateLayout}
        path="/kyc"
      />
      <RouteWithLayout
        component={GoogleAuthen}
        exact
        layout={PrivateLayout}
        path="/2fa"
      />
      <RouteWithLayout
        component={Deactive2FA}
        exact
        layout={PrivateLayout}
        path="/2fa/deactive"
      />
      <RouteWithLayout
        component={BountyProgram}
        exact
        layout={PrivateLayout}
        path="/bounty-program"
      />
      <RouteWithLayout
        component={Wallet}
        exact
        layout={PrivateLayout}
        path="/wallet"
      />

      <RouteWithLayout
        component={VND}
        exact
        layout={PrivateLayout}
        path="/wallet/vnd/:id"
      />
      {/* <RouteWithLayout
        component={WithDrawVND}
        exact
        layout={PrivateLayout}
        path="/wallet/vnd/withdraw"
      /> */}
      <RouteWithLayout
        component={USDT}
        exact
        layout={PrivateLayout}
        path="/wallet/usdt/:id"
      />
      <RouteWithLayout
        component={Swap}
        exact
        layout={PrivateLayout}
        path="/wallet/swap/:id"
      />
      <RouteWithLayout
        component={WithDrawUSDT}
        exact
        layout={PrivateLayout}
        path="/wallet/usdt/withdraw"
      />
      <RouteWithLayout
        component={PersonalInfo}
        exact
        layout={PrivateLayout}
        path="/personal-info"
      />
      <RouteWithLayout
        component={History}
        exact
        layout={PrivateLayout}
        path="/history"
      />
      <RouteWithLayout
        component={Transfer}
        exact
        layout={PrivateLayout}
        path="/transfer"
      />

      <RouteWithLayout
        component={OrderBookBuy}
        exact
        layout={PrivateLayout}
        path="/transfer/buy"
      />

      <RouteWithLayout
        component={OrderBookSale}
        exact
        layout={PrivateLayout}
        path="/transfer/sale"
      />
      <RouteWithLayout
        component={SignUp}
        exact
        layout={PublicLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={ResetPassword}
        exact
        layout={PublicLayout}
        path="/reset-password"
      />
      <RouteWithLayout
        component={SignIn}
        exact
        layout={PublicLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={ManagementProduct}
        exact
        layout={PrivateLayout}
        path="/management"
      />
      <RouteWithLayout
        component={ManagementDistribute}
        exact
        layout={PrivateLayout}
        path="/distribute"
      />
      <RouteWithLayout
        component={NotFoundPage}
        exact
        layout={PrivateLayout}
        path="/not-found"
      />
      <RouteWithLayout
        component={ProductList}
        exact
        layout={PublicLayout}
        path="/products"
      />
      <RouteWithLayout
        component={Deal}
        exact
        layout={PublicLayout}
        path="/deal"
      />
      <RouteWithLayout
        component={ProductSingle}
        exact
        layout={PublicLayout}
        path="/product/:id"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

const mapDispatchToProps = (dispatch) => ({
})

Routes.propTypes = {
}

export default connect(false, mapDispatchToProps)(Routes)
