/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs, InputNumber, Upload, message, Select, Breadcrumb,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  RightOutlined,
  PhoneOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  CopyOutlined,
} from '@ant-design/icons'

// import './index.css'
import styled from 'styled-components'
import moment from 'moment'
import { toast } from 'react-toastify'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import tokenUsdrIcon from '../../../static/images/TokenUSDR.png'

import ModalContactInfo from '../contact-info'
import wallet from '../../../provider/wallet'
import product from '../../../provider/product'
import ido from '../../../provider/ido'
import { formatAmount } from '../../../utils/common'

const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`

export const ORDER_STATUS = {
  O: 'O',
  C: 'C',
  CL: 'CL',
}

export const ORDER_STATUS_NAMES = {
  [ORDER_STATUS.O]: 'Chờ khớp',
  [ORDER_STATUS.C]: 'Đã khớp',
  [ORDER_STATUS.CL]: 'Hủy lệnh',
}

export const STATUS_ORDER_COLORS = {
  [ORDER_STATUS.O]: 'waiting',
  [ORDER_STATUS.C]: 'success',
  [ORDER_STATUS.CL]: 'fail',
}

const OrderBookSale = (classe) => {
  const { Option } = Select

  const columns = [
    {
      title: 'Mã cổ phần',
      dataIndex: 'stockInfo',
      key: 'stockInfo',
      render: (stockInfo) => (
        <span className="">{stockInfo?.symbol}</span>
      ),
    },
    {
      title: 'Ngày đăng',
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (text) => <span className="font-weight-500">{moment(text).format('DD/MM/YYYY HH:mm')}</span>,
    },
    {
      title: 'Khối lượng',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => <span className="font-weight-500">{formatAmount(text)}</span>,
    },
    {
      title: 'Đơn giá',
      key: 'price',
      dataIndex: 'price',
      render: (text, row) => (
        <span className="font-weight-500">
          {formatAmount(text) || 0}
          {' '}
          {row.bases}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text) => (
        <p className={`text-row ${STATUS_ORDER_COLORS[text]}`}>
          {ORDER_STATUS_NAMES[text]}
        </p>
      ),
    },
  ]

  const [form] = Form.useForm()
  const [formvalue, setFormvalue] = useState(
    {
      project: '',
      quantity: '',
      suffix: 'VND',
      price: '',

    },
  )
  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')
  const [dataDetailStock, setDataDetailStock] = useState({})
  const [queryHistory, setQueryHistory] = useState({
    type: 'orderbook',
    current: 1,
    pageSize: 10,
  })

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  })

  const [data, setData] = useState([])
  const [listStock, setLishStock] = useState([])

  const getHistory = async (param) => {
    const payload = {
      query: {
        type: 'S',
      },
      order: {
        updatedAt: -1,
      },
    }
    const resp = await ido.getHistory({ page: param.current || 1, limit: param.pageSize || 10, type: 'orderbook' }, payload)
    setData(resp?.data?.data)
    setPagination({ ...pagination, ...{ total: resp.data?.total }, ...{ current: param.current } })
  }

  const fetchData = async (params) => {
    await getHistory({
      ...queryHistory, current: params?.current || 1, pageSize: params?.pageSize || 10, type: 'orderbook',
    })
    setPagination({ ...params })
  }

  const getListStock = async (searchText) => {
    const resp = await product.searchProject({
      key_search: searchText,
      status: {
        $in: ['P', 'PC', 'FN', 'RL'],
      },
      hasOffer: false,
    })
    setLishStock(resp.data?.projects)
  }

  useEffect(() => {
    getHistory(queryHistory)
    getListStock()
  }, [])

  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select value="USDR" style={{ width: 120 }}>
        <Option value="USDR">
          <span className="mr-2">USDR</span>
          <Avatar src={tokenUsdrIcon} size={18} />
        </Option>
        <Option value="REX">
          <span className="mr-2">REX</span>
          <Avatar src={tokenBreIcon} size={18} />
        </Option>
        <Option value="VND">
          <span className="mr-2">VND</span>
          <Avatar src={tokenVndIcon} size={18} />
        </Option>

      </Select>
    </Form.Item>
  )

  const confirmOrder = async (value) => {
    const payload = {
      project: JSON.parse(value.project),
      quantity: value.quantity,
      bases: value.suffix,
      price: value.price,
    }
    console.log(payload)
    setDataDetailStock(payload)
    setModalConfirm(true)
  }

  const requestOrder = async () => {
    const payload = {
      project: dataDetailStock?.project?._id,
      type: 'S',
      quantity: dataDetailStock?.quantity,
      expiry: '2022-10-30',
      bases: [dataDetailStock.bases],
      // bank: '',
      limit: {
        minimum: 1,
        maximum: -1,
      },
      price: dataDetailStock.price,
    }
    const resp = await ido.requestOrder(payload)
    if (resp?.code === 200) {
      toast.success('yêu cầu của bạn đã được gửi.')
      setModalConfirm(false)
    }
    getHistory(queryHistory)
  }

  const onChangeStock = (value) => {
    // console.log('stock', value)
  }

  const onSearch = (value) => {
    getListStock(value)
  }

  return (
    <>
      <Modal
        footer={null}
        visible={modalConfirm}
        onOk={() => setModalConfirm(false)}
        onCancel={() => setModalConfirm(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Đăng quảng cáo
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>Mã Cổ phần</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.project?.stock_info?.symbol}</DescriptionTextRight>
        </DescriptionText>
        {/* <DescriptionText>
          <DescriptionTextLeft> Tên sản phẩm</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.project?.name}</DescriptionTextRight>
        </DescriptionText> */}

        <DescriptionText>
          <DescriptionTextLeft>Số lượng cổ phần</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock.quantity)}</TextColorGreen>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Giá cổ phần</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock?.price)}</TextColorGreen>
        </DescriptionText>

        <DescriptionText>
          <DescriptionTextLeft>Tổng số tiền nhận được</DescriptionTextLeft>
          <TextColorGreen>{formatAmount(dataDetailStock?.price * dataDetailStock.quantity)}</TextColorGreen>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Phương thức thanh toán</DescriptionTextLeft>
          <DescriptionTextRight>{dataDetailStock?.bases}</DescriptionTextRight>
        </DescriptionText>

        <div className="d-flex justify-content-center">
          <Button
            htmlType="submit"
            size="large"
            className="m-l-10 m-r-10"
            onClick={() => setModalConfirm(false)}
          >
            Hủy bỏ
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="m-l-10 m-r-10"
            onClick={() => requestOrder()}
          >
            Xác nhận đăng quảng cáo
          </Button>
        </div>
      </Modal>
      {/* Deposit amount */}
      <Breadcrumb className="mt-3 mb-4">
        <Breadcrumb.Item>Sàn p2p</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/transfer">Giao dịch</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Quảng cáo bán cổ phần</Breadcrumb.Item>
      </Breadcrumb>
      <Typography.Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
        Quảng cáo bán cổ phần
      </Typography.Title>

      <Card className="walletContent" bordered={false}>
        <div className="balance">
          <Form
            form={form}
            name="form-request"
            scrollToFirstError
            onFinish={confirmOrder}
            initialValues={formvalue}
          >
            <Form.Item
              name="project"
              label="Mã cổ phần"
              className="m-b-5"
              rules={[{ required: true, message: 'Please input id!' },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                onChange={onChangeStock}
                onSearch={onSearch}
              >
                {listStock.map((stock) => <Option value={JSON.stringify(stock)}>{stock.no}</Option>) }
              </Select>
            </Form.Item>

            <Form.Item
              name="quantity"
              label="Số lượng (CP)"
              className="m-b-5"
              rules={[{ required: true, message: 'Please input quantity!' },
              ]}
            >
              <InputNumber
                className="w-100"
              />
            </Form.Item>

            <Form.Item
              name="price"
              label="Giá"
              className="m-b-5"
              rules={[{ required: true, message: 'Please input price!' },
              ]}
            >
              <InputNumber
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                addonAfter={suffixSelector}
                className="w-100"
              />
            </Form.Item>

            <Button type="primary" className="mr-2 m-t-20 m-b-10" htmlType="submit">
              Đăng quảng cáo
            </Button>
            <Button icon={<PhoneOutlined />} onClick={() => setModalContactInfo(true)}>
              Liên hệ hỗ trợ
            </Button>
          </Form>
        </div>
      </Card>
      <div className="m-t-40">

        {/* table balance */}
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          onChange={fetchData}
          pagination={pagination}
        />
      </div>
    </>
  )
}

export default OrderBookSale
