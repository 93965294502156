/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input,
  Tabs,
  InputNumber,
  Upload,
  Select,
  Radio,
} from 'antd'
import { Link } from 'react-router-dom'
import {
  DownloadOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  RightOutlined,
  PhoneOutlined,
  HourglassOutlined,
  ExclamationCircleOutlined,
  CloudUploadOutlined, PlusOutlined, CheckCircleOutlined, DeleteOutlined,
} from '@ant-design/icons'
// import './index.css'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import { toast } from 'react-toastify'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import tokenBreIcon from '../../../static/images/TokenBRE.png'
import tokenUsdtIcon from '../../../static/images/TokenUSDT.png'
import tokenVndIcon from '../../../static/images/TokenVNĐ.png'
import ModalContactInfo from '../contact-info'
import walletSerivce from '../../../provider/wallet'
import WalletInfo from '../wallet-info'
import { formatAmount } from '../../../utils/common'
import * as walletAction from '../../../redux/action/wallet'
import { getWalletData } from '../../../redux/selector/wallet'

const { Step } = Steps
const { Option } = Select
const { Title } = Typography

const DescriptionText = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 16px;
`
const DescriptionTextEnd = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 32px;
`

const DescriptionTextLeft = styled.div`
  color: #838383;
`

const DescriptionTextRight = styled.div`
  font-weight: 500;
`

const TextColorGreen = styled.div`
  color: #11C55D;
  font-weight: 500;
`

const suffixSelector = (
  <Form.Item name="suffix" noStyle>
    <div className="d-flex align-items-center">
      <span className="mr-2">VND</span>
      <Avatar src={tokenVndIcon} size={18} />
    </div>
  </Form.Item>
)
export const WALLET_STATUS = {
  W: 'W',
  S: 'S',
  P: 'P',
  PC: 'PC',
  C: 'C',
  CL: 'CL',
  F: 'F',
  WUT: 'WUT',
  WSV: 'WSV',
}
const STATUS_COLORS = {
  [WALLET_STATUS.W]: 'waiting',
  [WALLET_STATUS.S]: 'success',
  [WALLET_STATUS.P]: 'waiting',
  [WALLET_STATUS.PC]: 'success',
  [WALLET_STATUS.C]: 'success',
  [WALLET_STATUS.CL]: 'fail',
  [WALLET_STATUS.F]: 'fail',
  [WALLET_STATUS.WSV]: 'waiting',
  [WALLET_STATUS.WUT]: 'waiting',
}

export const STATUS_NAMES = {
  [WALLET_STATUS.W]: 'Chờ xác nhận',
  [WALLET_STATUS.S]: 'Hoàn tất',
  [WALLET_STATUS.P]: 'Chưa xử lý',
  [WALLET_STATUS.PC]: 'Tiến hành trả',
  [WALLET_STATUS.C]: 'Giao dịch thành công',
  [WALLET_STATUS.CL]: 'Đã hủy',
  [WALLET_STATUS.F]: 'Thất bại',
  [WALLET_STATUS.WSV]: 'Đang xác nhận',
  [WALLET_STATUS.WUT]: 'Chờ chuyển tiền',
}

const WithDrawVND = ({ wallet, getWallet, id }) => {
  useEffect(() => { }, [])
  const { TabPane } = Tabs

  const [form] = Form.useForm()
  const [bankAccount] = Form.useForm()
  const [modalConfirm, setModalConfirm] = useState(false)
  const [otp, setOTP] = useState('')
  const [visible, setVisible] = useState(false)
  const [modalOtp, setModalOtp] = useState(false)
  const [modalTransactionDetail, setModalTransactionDetail] = useState(false)
  const [modalContactInfo, setModalContactInfo] = useState(false)
  const [modalAddBankAccount, setModalAddBankAccount] = useState(false)
  const [modalTransactionDetailWithDraw, setModalTransactionDetailWithDraw] = useState(false)
  const [modalCatalogManagement, setModalCatalogManagement] = useState(false)
  const [currentAccountBank, setCurrentAccountBank] = useState({})
  const [sltCurrentAccountBank, setSltCurrentAccountBank] = useState({})
  const [infoRequest, setInfoRequest] = useState({})
  const [defaultValuesBankAccount, setDefaultValuesBankAccount] = useState({
    bankType: '',
    numberCard: '',
    fullName: '',
    bankBranch: '',
  })

  const [currentWithdraw, setCurrentWithdraw] = useState({ amount: '' })

  const [currentStep, setCurrentStep] = useState(0)
  const [openDetail, setOpenDetail] = useState(false)
  const [isErr, setIsErr] = useState(false)

  const [statusStep, setStatusStep] = useState({
    step1: '',
    step2: '',
  })
  const [totalWithdraw, setTotalWithdraw] = useState(0)

  useEffect(() => {
    form.setFieldsValue(defaultValuesBankAccount)
  }, [bankAccount, defaultValuesBankAccount])

  const openDetailRequest = (data) => {
    console.log(data)
    setInfoRequest(data)
    setOpenDetail(true)
    setModalTransactionDetailWithDraw(true)
  }
  const columns = [
    {
      title: 'Mã tham chiếu',
      key: 'no',
      dataIndex: 'no',
      render: (text) => <span className="font-weight-500">{text || '-'}</span>,
    },
    {
      title: 'Số tiền rút',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (text) => (
        <div>
          <span className="mr-1 font-weight-500">{formatAmount(text || 0)}</span>
          <span>VND</span>
        </div>
      ),
    },
    {
      title: 'Thời gian',
      key: 'created_at_time',
      dataIndex: 'created_at_time',
      render: (text) => (
        <span className="font-weight-500">
          {moment(text).format('MM/DD/YYYY HH:mm:ss')}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text, row) => (
        <div className="center">
          <span className={` ${STATUS_COLORS[text]}`}>
            {STATUS_NAMES[text]}
          </span>

        </div>
      ),
    },
    {

      title: 'Thao tác',
      align: 'center',
      key: 'status',
      dataIndex: 'status',
      render: (text, row) => (
        <div className="center">
          <span>
            <Button
              onClick={() => openDetailRequest(row)}
              shape="circle"
              icon={<RightOutlined className="colorIcon" />}
              size="small"
            />
          </span>
        </div>

      ),
    },

  ]

  const [query, setQuery] = useState({
    currency: 'VND',
    page: 1,
    limit: 10,
  })

  const [listBankAccount, setListBankAccount] = useState([])
  const [data, setData] = useState([])

  const onChange = () => {

  }

  const getListwithdraw = async (params) => {
    const resp = await walletSerivce.getListwithdraw(params)
    console.log('list withdraw', resp)
    setData(resp.data)
  }
  useEffect(() => {
    getListwithdraw(query)
  }, [])

  useEffect(() => {
    fetchListBankAccount()
  }, [])

  const handleChangeOtp = (otp) => {
    setOTP(otp)
  }

  const handleVerifyOtp = async () => {
    console.log(otp)
    const payload = {
      amount: parseFloat(currentWithdraw?.amount),
      bankAccountId: currentAccountBank._id,
      currency: 'VND',
    }
    const resp = await walletSerivce.withdraw({ tfa_code: otp }, payload)
    toast.success('yêu cầu của bạn đã được gửi.')
    getListwithdraw(query)
    setModalOtp(false)
    console.log(resp)
  }

  const clearOTP = () => {
    setModalOtp(false)
    setOTP('')
  }

  const handleResendOtp = () => {
  }

  const fetchListBankAccount = async () => {
    try {
      const resp = await walletSerivce.getBankAcoount()
      console.log(resp)
      setListBankAccount(resp.data)
      if (resp.data.length > 0) {
        setCurrentAccountBank(resp.data[0])
      } else {
        setCurrentAccountBank({})
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addBankAccount = async (values) => {
    const data = {
      bank_type: values.bankType,
      number_card: values.numberCard,
      full_name: values.fullName,
      bank_branch: values.bankBranch,
    }
    try {
      const resp = await walletSerivce.createBankAccount(data)
      console.log(resp)
      if (resp?.code === 200) {
        toast.success('Thêm mới tài khoản ngân hàng thành công')
        fetchListBankAccount()
        setModalAddBankAccount(false)
        bankAccount.resetFields()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const deleteBankAccount = async (id) => {
    const data = {
      // eslint-disable-next-line no-underscore-dangle
      bank_account_id: id,
    }
    console.log(data)
    try {
      const resp = await walletSerivce.deleteBankAccount(data)
      console.log(resp)
      if (resp?.code === 200) {
        toast.success('Xóa tài khoản ngân hàng thành công')
        fetchListBankAccount()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const clearBankAccount = () => {
    setDefaultValuesBankAccount({
      bankType: '',
      numberCard: '',
      fullName: '',
      bankBranch: '',
    })
    setModalAddBankAccount(false)
  }

  const selectBankAccount = () => {
    console.log(currentAccountBank)
    setCurrentAccountBank(sltCurrentAccountBank)
    setModalCatalogManagement(false)
  }

  const clearData = () => {
    setCurrentWithdraw({ amount: '' })
  }

  useEffect(() => {
    form.setFieldsValue(currentWithdraw)
  }, [form, currentWithdraw])

  const handleWithDraw = async () => {
    try {
      const payload = {
        amount: currentWithdraw?.amount,
        bankAccountId: currentAccountBank._id,
        currency: 'VND',
      }
      if (currentWithdraw.amount > 0) {
        const resp = await walletSerivce.withdraw({ tfa_send: 'auto' }, payload)
        console.log(resp)
        setModalTransactionDetailWithDraw(false)
        setModalOtp(true)
      } else {
        toast.error('Số tiền rút phải lớn hơn 0')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const requestWithDraw = async (value) => {
    setCurrentWithdraw(value)
    console.log(value)
    const payload = {
      amount: value.amount,
      bankAccountId: currentAccountBank._id,
      currency: 'VND',
    }
    if (value.amount > 0) {
      // const resp = await wallet.withdraw('auto', payload)
      // console.log(resp)
      // getListwithdraw(query)
      // setInfoRequest(resp.data)
      setModalTransactionDetailWithDraw(true)
      // clearData()
    } else {
      toast.error('Số tiền rút phải lớn hơn 0')
    }
  }

  const cancelRequest = async () => {
    const payload = {
      no: infoRequest.no,
      currency: 'VND',
    }
    await walletSerivce.cancelWithdraw(payload)
    toast.success('yêu cầu của bạn đã được hủy.')
    getListwithdraw(query)
    setModalTransactionDetailWithDraw(false)
  }

  useEffect(() => {
    setTotalWithdraw(wallet?.data?.fiat?.balance)
    console.log('setTotalWithdraw', wallet?.data?.fiat?.balance)
    return () => { }
  }, [wallet])

  return (
    <>
      <ModalContactInfo
        visible={modalContactInfo}
        onOK={() => setModalContactInfo(false)}
        onCancel={() => setModalContactInfo(false)}
      />
      {/* Modal Otp */}
      <Modal
        className="modalOtp"
        footer={null}
        visible={modalOtp}
        onCancel={() => clearOTP()}
        onOk={() => clearOTP()}
      >
        <Title level={3} style={{ fontWeight: 500, marginBottom: 16 }}>
          Xác minh mã bảo mật
        </Title>
        <p style={{ marginBottom: 32 }}>
          Để bảo mật cho tài khoản của bạn, hãy hoàn thành yêu cầu xác minh sau
        </p>
        <OtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={6}
          separator=""
          inputStyle={{
            height: 60,
            width: 50,
            marginRight: 4,
            marginLeft: 4,
            background: '#F5F5F5',
            border: '2px solid #F5F5F5',
            borderRadius: 4,
            marginBottom: 32,
          }}
          containerStyle={{ justifyContent: 'center' }}
          focusStyle={{ border: '2px solid #cf9900' }}
        />
        <p style={{ marginBottom: 32, color: '#121212' }}>
          Nhập mã xác minh gồm 6 chữ số được lấy từ ứng dụng Google Authenticator
        </p>
        <p style={{ marginBottom: 16 }}>
          <Button
            type="primary"
            htmlType="submit"
            className="btn-block"
            size="large"
            disabled={otp.length < 6}
            onClick={handleVerifyOtp}
          >
            Xác nhận
          </Button>
        </p>
        {/* <p style={{ marginBottom: 5, textAlign: 'center' }}>
          Bạn chưa nhận được email?
        </p>
        <div className="text-center">
          <a href="javascript:void(0)" onClick={handleResendOtp}>
            Gửi lại mã xác nhận
          </a>
        </div> */}
      </Modal>

      {/* Modal Info WithDraw  */}
      <Modal
        footer={null}
        visible={modalTransactionDetailWithDraw}
        onOk={() => { setModalTransactionDetailWithDraw(false); setOpenDetail(false) }}
        onCancel={() => { setModalTransactionDetailWithDraw(false); setOpenDetail(false) }}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Thông tin rút tiền
        </Title>
        <DescriptionText>
          <DescriptionTextLeft>Số tiền rút</DescriptionTextLeft>
          <DescriptionTextRight>
            {' '}
            {formatAmount(currentWithdraw?.amount || infoRequest?.quantity)}
            {' '}
            VND
          </DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Loại giao dịch</DescriptionTextLeft>
          <TextColorGreen>
            Rút / VND
          </TextColorGreen>
        </DescriptionText>
        <hr />
        <DescriptionText>
          <DescriptionTextLeft>Ngân hàng</DescriptionTextLeft>
          <DescriptionTextRight>{currentAccountBank?.bank_type}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Số tài khoản</DescriptionTextLeft>
          <DescriptionTextRight>{currentAccountBank?.number_card}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Chủ tài khoản</DescriptionTextLeft>
          <DescriptionTextRight>{currentAccountBank?.full_name}</DescriptionTextRight>
        </DescriptionText>
        <DescriptionText>
          <DescriptionTextLeft>Nội dung</DescriptionTextLeft>
          <DescriptionTextRight>Rút tiền</DescriptionTextRight>
        </DescriptionText>
        {/* <DescriptionTextEnd>
          <DescriptionTextLeft>Trạng thái</DescriptionTextLeft>
          <span className={` ${STATUS_COLORS[infoRequest?.status]}`}>
            {STATUS_NAMES[infoRequest?.status]}
          </span>
        </DescriptionTextEnd> */}
        <Row>
          {
            !openDetail ? (
              <>
                <Col span={12} className="pr-2">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-block"
                    size="large"
                    onClick={() => handleWithDraw()}
                  >
                    Rút tiền
                  </Button>
                </Col>

                <Col span={12}>
                  <Button
                    htmlType="submit"
                    className="btn-block"
                    size="large"
                    // onClick={cancelRequest}
                    onClick={() => setModalTransactionDetailWithDraw(false)}

                  >
                    Hủy lệnh
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col span={24}>
                  <Button
              // type="primary"
                    htmlType="submit"
                    className="btn-block"
                    size="large"
                    onClick={() => setModalTransactionDetailWithDraw(false)}
                  >
                    Đóng
                  </Button>
                </Col>
              </>
            )
          }

        </Row>

      </Modal>

      {/* Modal Catalog Management  */}
      <Modal
        className="modal-add-bank-account bank-account"
        footer={null}
        visible={modalCatalogManagement}
        onOk={() => setModalCatalogManagement(false)}
        onCancel={() => setModalCatalogManagement(false)}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Quản lý tài khoản ngân hàng
        </Title>
        <div className="mb-3 text-end">
          {listBankAccount.length > 0 ? listBankAccount.map((item, index) => (
            <>
              <Button
                className="btn-block select-bank-account"
                onClick={() => setSltCurrentAccountBank(item)}

              >

                <Row className="catalog">
                  <Col span={24}>{item?.bank_type}</Col>
                  <Col span={24}>
                    {item?.full_name}
                    {' '}
                    -
                    {' '}
                    {item?.number_card}
                  </Col>
                </Row>

                <CheckCircleOutlined
                  color="primary"
                />
              </Button>
              <Button type="link" icon={<DeleteOutlined />} onClick={() => deleteBankAccount(item._id)}>
                Xóa
              </Button>
            </>
          )) : (<></>)}

          <Button
            className="mb-3 btn-block add-bank-account"
            onClick={() => setModalAddBankAccount(true)}
          >
            Thêm ngân hàng
            <PlusOutlined />
          </Button>
        </div>

        <div className="right">
          <Button
            className=" m-t-15 mr-2"
            htmlType="submit"
            onClick={() => setModalCatalogManagement(false)}
          >
            Quay lại
          </Button>
          <Button
            type="primary"
            className=" m-t-15"
            htmlType="submit"
            onClick={() => selectBankAccount()}
          >
            Áp dụng
          </Button>
        </div>
      </Modal>

      {/* Modal Add Bank Account */}
      <Modal
        className="modal-add-bank-account"
        footer={null}
        visible={modalAddBankAccount}
        onCancel={() => { clearBankAccount(); bankAccount.resetFields() }}
        onOk={() => { clearBankAccount(); bankAccount.resetFields() }}
      >
        <Title level={3} className="font-weight-500 mb-3">
          Tạo tài khoản ngân hàng mới
        </Title>
        <Form
          form={bankAccount}
          layout="vertical"
          // initialValues={defaultValuesBankAccount}
          onFinish={addBankAccount}
        >
          <Form.Item name="bankType" className="item-form m-t-10" label="Tên ngân hàng" rules={[{ required: true }]}>

            <Select
              showSearch
              placeholder="Vui lòng chọn ngân hàng."
              optionFilterProp="children"
            >
              <Option value="VIETINBANK">VIETINBANK</Option>
              <Option value="VIETCOMBANK">VIETCOMBANK</Option>
              <Option value="VPBANK">VPBANK</Option>
              <Option value="MBBANK">MBBANK</Option>

            </Select>
          </Form.Item>

          <Form.Item name="numberCard" className="item-form m-t-10" label="Số tài khoản" rules={[{ required: true }]}>
            <Input
              placeholder="Vui lòng nhập"
            />
          </Form.Item>

          <Form.Item name="fullName" className="item-form m-t-10" label="Tên chủ tài khoản" rules={[{ required: true }]}>
            <Input
              placeholder="Vui lòng nhập"
            />
          </Form.Item>

          <Form.Item className="block right">
            <Button
              className=" m-t-15 mr-2"
              htmlType="button"
              onClick={() => { clearBankAccount(); bankAccount.resetFields() }}
            >
              Hủy
            </Button>
            <Button
              type="primary"
              className=" m-t-15"
              htmlType="submit"
            >
              Thêm
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Wallet info */}
      <WalletInfo />

      {/* Withdraw amount */}
      <Card className="walletContent" bordered={false}>
        <p>Chọn tài khoản ngân hàng muốn nhận</p>
        {/*
        <Button
          className="btn-block add-bank-account"
          icon={<PlusOutlined />}
          onClick={() => setModalAddBankAccount(true)}
        >
          Thêm ngân hàng
        </Button> */}
        {currentAccountBank?.full_name ? (
          <>
            <div className="mb-3 text-end">
              <Button
                className="ant-btn ant-btn-default btn-block select-bank-account active-bank"
              >

                <Row className="catalog">
                  <Col span={24}>{currentAccountBank?.bank_type}</Col>
                  <Col span={24}>
                    {currentAccountBank?.full_name}
                    {' '}
                    -
                    {' '}
                    {currentAccountBank?.number_card}
                  </Col>
                </Row>
                <CheckCircleOutlined style={{ color: '#FDB515' }} />
              </Button>
            </div>
          </>
        ) : (
          <></>
        ) }
        <Button type="link" className="p-0 link" onClick={() => setModalCatalogManagement(true)}>
          Quản lý tài khoản ngân hàng
        </Button>
        <Form
          form={form}
          name="WithDraw-amount"
          initialValues={currentWithdraw}
          onFinish={requestWithDraw}
        >
          <Form.Item
            name="amount"
            label="Số tiền rút"
            rules={[{ required: true, message: 'Số tiền rút lớn hơn 0. ' }, {
              message: 'Số tiền vượt quá số dư tài khoản.',
              validator: (_, values) => {
                if (values > totalWithdraw) {
                  return Promise.reject('Số tiền vượt quá số dư tài khoản.')
                }
                return Promise.resolve('')
              },

            },
            ]}
            // help={`Số tiền rút tối đa là ${formatAmount(totalWithdraw)} VND`}
            className="mb-3"
          >
            <InputNumber
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              addonAfter={suffixSelector}
              className="w-100"
            />

          </Form.Item>
          <p>
            Số tiền rút tối đa là:
            {' '}
            {formatAmount(totalWithdraw)}
            {' '}
            VND
          </p>
          <Button type="primary" className="mr-2" htmlType="submit">
            Rút tiền
          </Button>
          <Button icon={<PhoneOutlined />} onClick={() => setModalContactInfo(true)}>
            Liên hệ hỗ trợ
          </Button>
        </Form>
      </Card>
      <div className="m-t-40">

        {/* table balance */}
        <Table
          scroll={{ x: true }}
          columns={columns}
          dataSource={data}
          pagination={{ position: 'bottomCenter' }}
        />
      </div>
    </>
  )
}

WithDrawVND.propTypes = {
  wallet: PropTypes.shape(),
  getWallet: PropTypes.func,

}

const mapDispatchToProps = (dispatch) => ({
  getWallet: () => dispatch(walletAction.get()),

})
export default connect(
  (state) => (
    {
      wallet: getWalletData(state),
    }), mapDispatchToProps,
)(WithDrawVND)
