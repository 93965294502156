/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Typography,
  Button,
  DatePicker,
  Table,
  Tag,
  Space,
  Avatar,
  Pagination,
  Steps,
  Modal,
  Form,
  Input, message,
} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import {
  CopyOutlined,
} from '@ant-design/icons'
import styled, { css } from 'styled-components'
import { toast } from 'react-toastify'
import facebook from '../../static/images/facebook.png'
import zalo from '../../static/images/zalo.png'
import instagram from '../../static/images/instagram.png'
import youtube from '../../static/images/youtube.png'
import twitter from '../../static/images/twitter.png'
import telegram from '../../static/images/telegram.png'
import ggplus from '../../static/images/Group.png'
import donation from '../../static/images/donation.png'
import bre from '../../static/images/TokenBRE.png'
import './index.css'
import bountyProgramApi from '../../provider/bounty-program'

export const BountyProgramTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #003262;
`
export const BountyProgramContent = styled.p`
  font-weight: 400;
  color: #838383;
`
export const AvatarBountyProgram = styled.img`
  width: 60; 
  height: 60; 
`
export const AvatarBre = styled.img`
  width: 24;
  height: 24;
`
const BountyProgram = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const [inputState, setInputState] = useState({})
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(10)
  const [dataBountyProgram, setDataBountyProgram] = useState(null)
  const [defaultValuesBountyProgram, setDefaultValuesBountyProgram] = useState({})

  const fetchListBountyProgram = async (current, pageSize) => {
    try {
      const resp = await bountyProgramApi.getListBountyProgram({
        ...query,
        ...{ page: current },
        ...{ size: pageSize },
        sort: {
          createdAt: -1,
        },
      })
      setQuery({ ...query, ...{ page: current }, ...{ size: pageSize } })
      setTotal(resp?.data?.total)
      setCurrentPage(query.page)
      setDataBountyProgram(resp?.data?.programs)
    } catch (e) {
      toast.error(e)
    }
  }

  const onShare = async (item) => {
    const bountProgramId = item?._id
    const data = { programId: bountProgramId, reference: inputState[bountProgramId] }
    try {
      const resp = await bountyProgramApi.createBountyProgram(data)

      if (resp.code === 200) {
        setInputState({})
        await fetchListBountyProgram(query.page, query.size)
        toast.success('Chia sẻ thành công bounty program')
        console.log(inputState)
      } else {
        toast.error(resp.msg)
        setInputState({})
      }
      setCurrentDataBountyProgram(resp?.data)
    } catch (e) {
      toast.error(e)
    }
  }

  const onReward = async (item) => {
    try {
      const completionId = item?.completion?._id
      // console.log(completionId)
      const resp = await bountyProgramApi.rewardBountyProgram({ completionId })
      if (resp.code === 200) {
        await fetchListBountyProgram(query.page, query.size)
        toast.success('Nhận thưởng thành công')
      } else {
        toast.error(resp.msg)
      }
    } catch (e) {
      toast.error(e)
    }
  }

  useEffect(() => {
    fetchListBountyProgram(query.page, query.size)
  }, [])

  const copyToClipboard = async (values) => {
    try {
      await navigator.clipboard.writeText(values)
      message.success('Đã sao chép link')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Typography.Title level={2}>Bounty Program</Typography.Title>
      <Row gutter={[48, 24]}>

        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
          {/* Referal */}
          <Card className="cardReferral w-100" bordered={false}>
            <div className="d-flex">
              <div className="flex-shrink-0 mr-3">
                <img src={donation} style={{ width: 72, height: 72 }} />
              </div>
              <div className="flex-grow-1 ms-3 align-self-center">
                <p className="m-0">Tổng số token có thể nhận</p>
                <div className="balance">
                  0 / 100
                </div>
              </div>
            </div>
          </Card>
        </Col>
        {/* <Col xs={{ span: 24 }} lg={{ span: 12 }} /> */}
      </Row>

      <Typography.Title level={4} className="mt-3">Danh sách chia sẻ</Typography.Title>
      <Row gutter={[32, 24]}>
        {dataBountyProgram?.map((item, index) => (
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            {/* Facebook */}
            <Card className="cardBountyProgram w-100" bordered={false}>
              <div className="d-flex flex-column">
                <div className=" p-2">
                  <div className="d-flex">
                    <div className="mr-auto p-2"><AvatarBountyProgram src={facebook} /></div>
                    <div className="p-2">
                      <div className="reward">
                        <span><AvatarBre src={bre} className="mr-2" /></span>
                        <span>
                          +
                          {item.rexReward}
                          {' '}
                          Token
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-2">
                  <BountyProgramTitle>{item.action}</BountyProgramTitle>
                  <BountyProgramContent>{item.content}</BountyProgramContent>
                </div>
                {(item?.available_status === 'RWD' || item?.available_status === 'RR' || item?.available_status === 'RWG')
                  && (
                  <div className="p-2">
                    <Button type="primary" disabled>Đã nhận thưởng</Button>
                  </div>
                  )}
                {item?.available_status === 'C' && (
                <div className="p-2">
                  <Button
                    type="primary"
                    onClick={() => onReward(item)}
                  >
                    Nhận thưởng

                  </Button>
                </div>
                )}

                {(item?.available_status === 'OK' || item?.available_status === 'RJ' || item?.available_status === 'P') && (
                  <div className="mb-auto p-2">
                    <div className="d-flex border w-100">
                      <div className="mr-auto p-2 w-100">
                        <Input
                          name={item._id}
                          placeholder="Nhập link chia sẻ"
                          onChange={(e) => { setInputState({ ...inputState, ...{ [e.target.name]: e.target.value } }) }}
                        />
                      </div>
                      <div className="p-2">
                        <Button type="link" onClick={() => onShare(item)}>Chia sẻ</Button>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            </Card>

          </Col>
        ))}
      </Row>
      <Row className="d-flex justify-content-center align-items-center">
        <Pagination
          showSizeChanger={false}
          pageSize={10}
          onChange={fetchListBountyProgram}
          defaultCurrent={currentPage}
          total={total}
                // itemRender={itemRender}
          className="mt-2 pt-3 pagination mb-4"
        />
      </Row>
    </>
  )
}

export default BountyProgram
